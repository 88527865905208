import {useQuery} from "@tanstack/react-query";
import ApiLocation from "../../../api/location";
import {API_CONSTANTS} from "../../../api/constants/constants";

export const useEquipmentsForLocationQuery = (locationId) => {
    const params = {
        query: {
            id: locationId,
        },
    };

    return useQuery({
        queryFn: () => ApiLocation.equipmentsForLocation(params),
        queryKey: [API_CONSTANTS.GET_EQUIPMENTS_FOR_LOCATION, params],
        select: (res) => res.list,
        enabled: !!locationId,
    });
};
