import {create} from "zustand";
import {immer} from "zustand/middleware/immer";
import {shallow} from "zustand/shallow";
import {pull as _pull, cloneDeep as _cloneDeep} from "lodash";
import {getSyncSelector} from "./helpers/getSyncSelector";
import {persist} from "zustand/middleware";

const store = (set, get) => ({
    axis: {},
    defaultAxis: {},
    actions: {
        toggleActiveSeries(installationPoint, axisId, isWaterfall = false) {
            const selector = getSyncSelector(installationPoint, false, isWaterfall);
            let selected = _cloneDeep(get().axis[selector]) || ["1"];
            if (selected.indexOf(axisId) >= 0) {
                selected = _pull(selected, axisId);
            } else {
                selected.push(axisId);
            }

            if (selected.length) {
                set((state) => {
                    state.axis[selector] = selected;
                });
            }
        },
        toggleActiveSeriesForSingleSelect(installationPoint, axisId, isWaterfall = false) {
            set((state) => {
                state.axis[getSyncSelector(installationPoint, false, isWaterfall)] = Array.isArray(axisId) ? axisId : [axisId];
            });
        },
        setDefaultAxis: ({installationPoint, isImpactVue, isWaterfall, axis}) =>
            set((state) => {
                state.defaultAxis[getSyncSelector(installationPoint, isImpactVue, isWaterfall)] = axis;
            }),
    },
});

const persistConfig = {
    name: "axis",
    getStorage: () => localStorage,
    partialize: (state) => ({
        defaultAxis: state.defaultAxis,
    }),
    merge: (persisted, current) => ({...current, axis: persisted.defaultAxis, defaultAxis: persisted.defaultAxis}),
};

export const useAxisSyncStore = create(immer(persist(store, persistConfig)));

export const useAxisSyncStoreActions = () => useAxisSyncStore((state) => state.actions, shallow);
export const useSyncedAxis = (installationPoint, isImpactVue = false, isWaterfall = false) => {
    return useAxisSyncStore((state) => state.axis[getSyncSelector(installationPoint, isImpactVue, isWaterfall)], shallow) || ["1"];
};

export const useAxes = () => {
    return useAxisSyncStore((state) => state.axis, shallow) || ["1"];
};
