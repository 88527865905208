import React, {useState} from "react";
import {Modal} from "../../../../../shared";
import PropTypes from "prop-types";
import TextEditor from "../../../../../shared/textEditor/textEditor";
import ActionItemSelect from "../ActionItemSelect/ActionItemSelect";
import {useGetAvailableEquipmentTypeQuery} from "../../../../../hooks/api/actionItem/useGetAvailableEquipmentTypeQuery";
import Loader from "../../../../../shared/loader/loader";
import {useSearchSensorBySerialQuery} from "../../../../../hooks/api/actionItem/useSearchSensorBySerialQuery";
import {useSearchNodeBySerialQuery} from "../../../../../hooks/api/actionItem/useSearchNodeBySerialQuery";
import ActionItemSerialSelect from "../ActionItemSerialSelect/ActionItemSerialSelect";
import {useSearchGatewayBySerialQuery} from "../../../../../hooks/api/actionItem/useSearchGatewayBySerialQuery";
import {useSearchRepeaterBySerialQuery} from "../../../../../hooks/api/actionItem/useSearchRepeaterBySerialQuery";
import {useCreateActionItemMutation} from "../../../../../hooks/api/actionItem/useCreateActionItemMutation";
import FormValidator from "../../../../../helpers/form-validator";
import {rules} from "../../constants/rules";
import ValidationError from "../../../../../layouts/ValidationError/ValidationError";
import useDebounce from "../../../../../hooks/helpers/useDebounce";
import {omit as _omit} from "lodash";

const validator = new FormValidator(rules);

const urgency = [
    {value: 1, label: "Standard"},
    {value: 2, label: "High"},
    {value: 3, label: "Critical"},
];

const initialData = {
    type: "waitesWireless",
    hardwareType: "",
    equipment_type: "",
    urgency: null,
    description: "",
    serial: null,
    activeWwAi: null,
    installation_point_id: null,
};

const ActionItemAddModal = ({onClose, updateList, onShowActionItem}) => {
    const [validation, setValidation] = useState(validator.valid());
    const [isExpandEditor, setIsExpandEditor] = useState(false);
    const [data, setData] = useState(initialData);
    const [searchText, setSearchText] = useState("");
    const debouncedSearchText = useDebounce(searchText, 500);
    const enableCondition = (name) => data.hardwareType === name && (debouncedSearchText > 3 || debouncedSearchText.length >= 3);

    const {data: availableEquipmentType, isLoading: isLoadingAvailableEquipmentType} = useGetAvailableEquipmentTypeQuery();
    const {data: nodeData, isFetching: isLoadingNodeData} = useSearchNodeBySerialQuery({
        enabled: enableCondition("node"),
        query: debouncedSearchText,
    });
    const {data: sensorsData, isFetching: isLoadingSensorData} = useSearchSensorBySerialQuery({
        enabled: enableCondition("sensor"),
        query: debouncedSearchText,
    });
    const {data: routerData, isFetching: isLoadingRouterData} = useSearchRepeaterBySerialQuery({
        enabled: enableCondition("router"),
        query: debouncedSearchText,
    });
    const {data: gatewayData, isFetching: isLoadingGatewayData} = useSearchGatewayBySerialQuery({
        enabled: enableCondition("gateway"),
        query: debouncedSearchText,
    });

    const {mutateAsync, isLoading} = useCreateActionItemMutation({
        onSuccess: () => {
            setData(initialData);
            updateList();
            onClose();
        },
    });

    const isWwAiActive = (data) => {
        if (data.serial === "" || null || undefined) {
            return false;
        }
        return data.activeWwAi !== null;
    };

    const handleSelect = (e) => {
        const updatedData = {...data, [e.name]: e.value};

        if (e.value === "") {
            setData(updatedData);
            setValidation(validator.validate(updatedData));
            return;
        }

        setData(updatedData);
        const validationResult = validator.validate(updatedData, e.target.value);
        setValidation(validationResult);
    };

    const handleChange = (e) => {
        const updatedData = {...data, description: e};
        setData(updatedData);
        setValidation(validator.validate(updatedData));
    };

    const handleSelectSerial = (e, data) => {
        const selectedEquipment = data?.find((item) => item.value === e.value);
        setData((prev) => ({
            ...prev,
            [e.name]: e.value,
            equipment_type: selectedEquipment?.equipmentType,
            installation_point_id: selectedEquipment?.installation_point_id,
            activeWwAi: selectedEquipment?.activeWwAi,
        }));
    };

    const onSubmit = () => {
        if (isWwAiActive(data)) {
            onClose();
            onShowActionItem(data.activeWwAi);
            return;
        }
        let newData;
        if (data.hardwareType !== "sensor") {
            newData = _omit(data, ["hardwareType", "installation_point_id", "activeWwAi"]);
        } else {
            newData = _omit(data, ["hardwareType", "activeWwAi"]);
        }
        const validation = validator.validate(data);
        setValidation(validation);
        if (validation.isValid) {
            mutateAsync(newData);
        }
    };

    return (
        <Modal
            title={"ADD ACTION ITEM"}
            onClose={onClose}
            centered={false}
            onSubmit={onSubmit}
            className={"custom-modal create-action-item-modal"}
            showModal={true}
            submitTitle={isWwAiActive(data) ? "Go to Action Item" : "Add"}
            inProgress={isLoading}
        >
            {isLoadingAvailableEquipmentType ? (
                <Loader />
            ) : (
                <>
                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <div className={`form-group ${validation.hardwareType.isValid || !validation.hardwareType.message ? "" : " is-invalid"}`}>
                                <label className="text-right form-label">
                                    Equipment Type: <span className="color-danger">*</span>
                                </label>
                                <ActionItemSelect
                                    name={"hardwareType"}
                                    options={availableEquipmentType || []}
                                    label={"Select equipment type..."}
                                    value={data.hardwareType || ""}
                                    onChange={(e) => handleSelect(e, "hardwareTypeLabel")}
                                    isSearchable={true}
                                />
                                <ValidationError message={validation.hardwareType.message} />
                            </div>
                        </div>
                    </div>
                    <ActionItemSerialSelect
                        type="sensor"
                        title="Asset Name / Sensor Serial Number"
                        data={data}
                        options={sensorsData?.length ? sensorsData : []}
                        setSearchText={setSearchText}
                        searchText={searchText}
                        isLoading={isLoadingSensorData}
                        handleSelectSerial={(e) => handleSelectSerial(e, sensorsData)}
                        validation={validation}
                        isWwAiActive={isWwAiActive}
                    />
                    <ActionItemSerialSelect
                        type="node"
                        title="Node Serial Number"
                        data={data}
                        options={nodeData?.length ? nodeData : []}
                        setSearchText={setSearchText}
                        searchText={searchText}
                        isLoading={isLoadingNodeData}
                        handleSelectSerial={(e) => handleSelectSerial(e, nodeData)}
                        validation={validation}
                        isWwAiActive={isWwAiActive}
                    />
                    <ActionItemSerialSelect
                        type="gateway"
                        title="Gateway Serial Number"
                        data={data}
                        options={gatewayData?.length ? gatewayData : []}
                        setSearchText={setSearchText}
                        searchText={searchText}
                        isLoading={isLoadingGatewayData}
                        handleSelectSerial={(e) => handleSelectSerial(e, gatewayData)}
                        validation={validation}
                        isWwAiActive={isWwAiActive}
                    />
                    <ActionItemSerialSelect
                        type="router"
                        title="Repeater Serial Number"
                        data={data}
                        options={routerData?.length ? routerData : []}
                        setSearchText={setSearchText}
                        searchText={searchText}
                        isLoading={isLoadingRouterData}
                        handleSelectSerial={(e) => handleSelectSerial(e, routerData)}
                        validation={validation}
                        isWwAiActive={isWwAiActive}
                    />
                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <div className={`form-group mb-0 ${validation?.urgency?.isValid || !validation?.urgency?.message ? "" : " is-invalid"}`}>
                                <label className="text-right form-label">
                                    Urgency: <span className="color-danger">*</span>
                                </label>
                                <ActionItemSelect
                                    name={"urgency"}
                                    options={urgency}
                                    label={"Select..."}
                                    onChange={handleSelect}
                                />
                                <ValidationError message={validation?.urgency?.message} />
                            </div>
                        </div>
                    </div>
                    <div className={"row mt-3"}>
                        <div className={"col-md-12"}>
                            <div
                                className={`form-group ${validation?.description?.isValid || !validation?.description?.message ? "" : " is-invalid"} ${
                                    isExpandEditor && "expand-editor-active"
                                }`}
                            >
                                <label className="text-right form-label">
                                    Description: <span className="color-danger">*</span>
                                </label>
                                <div
                                    className={`arrow ${isExpandEditor && "arrow-active"}`}
                                    onClick={() => setIsExpandEditor((prev) => !prev)}
                                ></div>
                                <TextEditor
                                    content={data.description}
                                    onChange={handleChange}
                                />
                                <ValidationError message={validation?.description?.message} />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Modal>
    );
};

ActionItemAddModal.propTypes = {
    onClose: PropTypes.func,
    updateList: PropTypes.func,
    onShowActionItem: PropTypes.func,
};

export default ActionItemAddModal;
