import ApiLocation from "../../../api/location";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {API_CONSTANTS} from "../../../api/constants/constants";
import Helper from "../../../helpers/helper";

const assetCreatFn = (data) => ApiLocation.create(data);

export const useAssetCreatMutation = ({props, history}) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: assetCreatFn,
        onSuccess: () => {
            queryClient.invalidateQueries([API_CONSTANTS.GET_ASSETS_LIST]);
            props.refreshLocation();
            props.updateUser();
            history.push(Helper.deleteHashParams(["modal", "id"]));
        },
    });
};
