import Api from "./api";

const SsdApi = {
    getAvailableEquipmentTypes: () => {
        return Api.get("ssd/customer/get-available-waites-equipment-types", {host: `${process.env.API_V2_URL}`});
    },
    searchSensorBySerial: ({facilityId = null, locationId = null, query}) => {
        return Api.get("ssd/sensors/search-by-serial", {host: `${process.env.API_V2_URL}`, query: {filters: {facilityId, locationId, query}}});
    },
    searchNodeBySerial: ({facilityId = null, locationId = null, query}) => {
        return Api.get("ssd/nodes/search-by-serial", {host: `${process.env.API_V2_URL}`, query: {filters: {facilityId, locationId, query}}});
    },
    searchGatewayBySerial: ({facilityId = null, locationId = null, query}) => {
        return Api.get("ssd/gateways/search-by-serial", {host: `${process.env.API_V2_URL}`, query: {filters: {facilityId, locationId, query}}});
    },
    searchRepeaterGatewayBySerial: ({facilityId = null, locationId = null, query}) => {
        return Api.get("ssd/repeaters/search-by-serial", {host: `${process.env.API_V2_URL}`, query: {filters: {facilityId, locationId, query}}});
    },
    createActionItem: (data) => {
        return Api.post("ssd/action-item", {...data}, {host: `${process.env.API_V2_URL}`});
    },
};

export default SsdApi;
