import React, {Component} from "react";
import PropTypes from "prop-types";
import FormValidator from "../../helpers/form-validator";
import {ValidationError} from "../../shared";
import {Loader} from "../../shared";
import swal from "../shared/swal";
import {
    DATEFORMAT,
    ALARM_EMAIL_FREQUENCY,
    SELF_SERVICE,
    HYBRID_SERVICE
} from "../../constants/constants";
import {
    WW2_FRONTEND_GROUP,
    MY_DASHBOARD_ACCESS_PERMISSION,
    SHOW_FULL_SERVICE_EQUIPMENTS_PERMISSION,
    SHOW_ALARMS_PERMISSION,
    MANAGE_FULL_SERVICE_EQUIPMENTS_PERMISSION,
    EDIT_ALARMS_PERMISSION,
    WW2_DASHBOARD_GROUP,
    DASHBOARD_ACCESS_PERMISSION,
    DASHBOARD_ACCESS_BLOCK,
    GLOBAL_GROUP,
    SHOW_SERVICE_NOTIFICATIONS_PERMISSION,
    SUPER_ADMIN_PERMISSION,
    MANAGE_SUBSCRIPTIONS_PERMISSION
} from "../../constants/permissions";
import {
    get as _get,
    set as _set,
    remove as _remove,
    size as _size,
    find as _find,
    findKey as _findKey,
} from "lodash";
import {Button} from "reactstrap";
import {Link} from "react-router-dom";
import ApiUserPermissions from "../../api/userPermissions";
import ApiUser from "../../api/user";
import ApiAlertLevel from "../../api/alertLevel";
import SearchInput from "../shared/search-input";
import Converter from "../../helpers/converter";
import {withGlobalStore} from "../../stores/GlobalStore";
import Select from "react-select";
import ApiTimezone from "../../api/timezone";
import SubscriptionActonItems from "../network/action-items/subscription/subscription";
import Toast from "../shared/toast";
import Helper from "../../helpers/helper";
import ApiUserSubscription from "../../api/userSubscriptions";

import "../../assets/scss/components/users/metric-label.scss";
import {UserAlertGroup, UserDeviceAlertGroup} from "../subscriptions/user-subscriptions/groups";
import SelectWrapper from "../../helpers/select-wrapper";
import Page from "../not-found";
import { HeaderSimple } from "../../shared/header";

const rules = [
    {
        field: "name_first",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required.",
    },
    {
        field: "name_last",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required.",
    },
    {
        field: "email",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required.",
    },
    {
        field: "email",
        method: "isEmail",
        validWhen: true,
        message: "E-mail is not a valid.",
    },
    {
        field: "contact_email",
        method: "isEmail",
        validWhen: true,
        skipOnEmpty: true,
        message: "Contact E-mail is not a valid.",
    },
    {
        field: "username",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required.",
    },
    {
        field: "company",
        method: "isEmpty",
        validWhen: false,
        message: "This field is required.",
    },
    {
        field: "all_facilities",
        method: (field_value, options, state) => !state["all_facilities"] && !state?.selectedAssetsTree?.length,
        validWhen: false,
        message: "This field is required.",
    },
];

const darkModeSelectStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: "rgb(56, 56, 46)"
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: "#777",
        color: "#ccc"
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: "#ccc"
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: "#36342a"
    }),
    option: (styles, { isFocused }) => {
        return {
            ...styles,
            backgroundColor:  isFocused
                ? "#777"
                : null
        };
    },
};

const isDarkTheme = localStorage.getItem("lightMode");

class UserForm extends Component {
    constructor(props) {
        super(props);
        this.validator = new FormValidator(rules);
        this.state = {
            title: this.props.match.params.userId ? "Edit User" : "ADD USER",
            data: {
                is_active: "",
                name_first: "",
                name_last: "",
                email: "",
                contact_email: "",
                username: "",
                phone: "",
                timezone_id: "21",
                company: "",
                use_metric: "1",
                receive_hardware_notify: "0",
                personal_settings: {},
                all_facilities: null,
                alert_levels: [],
                unsubscribeAlertGroup: [],
                unsubscribeDeviceAlertGroup: [],
            },
            breadcrumbs: [
                {name: "Users", link: "/users"}
            ],
            loader: true,
            availablePermissionGroup: {},
            globalSearchString: "",
            inProgress: false,
            validation: this.validator.valid(),
            userId: this.props.match.params.userId ? this.props.match.params.userId : "",
            permissions: [],
            availablePermissionBlocks: [],
            isSuperAdmin: false,
            userPermissions: {},
            availableAssetsTree: [],
            selectedAssetsTree: [],
            alertLevels: [],
            timezones: [],
            showContactEmail: true,
            userSubscriptions: [],
        };

        this.onChange = this.onChange.bind(this);
        this.onChangeAlertLevel = this.onChangeAlertLevel.bind(this);
        this.onChangePermissions = this.onChangePermissions.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.searchChange = this.searchChange.bind(this);
        this.handleSubmitAddUser = this.handleSubmitAddUser.bind(this);
        this.handleSubmitEditUser = this.handleSubmitEditUser.bind(this);
        this.handleChangeGlobalSearch = this.handleChangeGlobalSearch.bind(this);
        this.onChangeAvailableAssetsTree = this.onChangeAvailableAssetsTree.bind(this);
    }

    handleChangeGlobalSearch (globalSearchString) {
        this.setState({globalSearchString: globalSearchString});
    }

    onChange(e) {
        const data = {...this.state.data};
        let showContactEmail = this.state.showContactEmail;
        if((_get(e, "target.name", "") === "email")){
            let value = _get(e, "target.value", "");
            if (value.indexOf("@") !== -1) {
                value = value.split("@").shift();
            }
            showContactEmail = value.indexOf("+") !== -1;
        }
        _set(data, _get(e, "target.name", ""), _get(e, "target.value", ""));
        const validation = this.validator.validate(data, _get(e, "target.name", ""));
        if(_get(e, "target.name", "") === "use_metric"){
            if(+_get(e, "target.value", "") === 0){
                data.personal_settings.units.map((metricItem) => {
                    metricItem.selected = "metric";
                });
            }

            if(+_get(e, "target.value", "") === 1){
                data.personal_settings.units.map((metricItem) => {
                    metricItem.selected = "us";
                });
            }
        }
        this.setState({data, validation, showContactEmail});
    }

    onChangeAlertLevel(e) {
        const data = {...this.state.data},
            value = parseInt(_get(e, "target.value", ""));

        if (data.alert_levels.indexOf(value) === -1) {
            data.alert_levels.push(value);
        } else {
            data.alert_levels = _remove(data.alert_levels, value);
        }

        this.setState({data});
    }

    onChangePermissions(e, group) {
        let {data, userPermissions} = this.state;
        const {name, value} = e.target;
        const permissionIndex = (userPermissions[group] || []).indexOf(name);

        if (!+value) {
            if (!userPermissions[group]) {
                userPermissions[group] = [];
            }
            userPermissions[group].push(name);
        } else if (permissionIndex !== -1) {
            userPermissions[group].splice(permissionIndex, 1);

            switch (name) {
                case MY_DASHBOARD_ACCESS_PERMISSION:
                    userPermissions[group] = [];
                    break;
                case SHOW_FULL_SERVICE_EQUIPMENTS_PERMISSION:
                    const manageFullServiceIndex = (userPermissions[group] || []).indexOf(MANAGE_FULL_SERVICE_EQUIPMENTS_PERMISSION);

                    if (manageFullServiceIndex !== -1) {
                        userPermissions[group].splice(manageFullServiceIndex, 1);
                    }
                    break;
                case SHOW_ALARMS_PERMISSION:
                    const editAlarmsIndex = (userPermissions[group] || []).indexOf(EDIT_ALARMS_PERMISSION);

                    if (editAlarmsIndex !== -1) {
                        userPermissions[group].splice(editAlarmsIndex, 1);
                    }
                    break;
                case SHOW_SERVICE_NOTIFICATIONS_PERMISSION:
                    data.receive_hardware_notify = 0;
                    break;
            }
        }

        this.setState({userPermissions, data});
    }

    componentDidMount() {
        const {assetTree} = this.props;

        if (this.state.userId) {
            Promise.all([
                ApiUser.getSelfUser(this.props.match.params.userId),
                ApiUserPermissions.getListByGroups(),
                ApiAlertLevel.getForProfile(),
                ApiTimezone.list(),
                ApiUserSubscription.forUser(this.state.userId),
            ]).then(([{user = {}}, {permissionBlocks = {}, availablePermissionBlocks = {}}, alertLevels, timezones, userSubscriptions]) => {
                let {userPermissions, selectedAssetsTree} = this.state;

                if ((user.permissions || []).length) {
                    user.permissions.map(permission => {
                        let permissionGroup = permission.group || GLOBAL_GROUP;

                        if (!_get(userPermissions, permissionGroup)) {
                            userPermissions[permissionGroup] = [];
                        }
                        userPermissions[permissionGroup].push(permission.name);
                    });
                }

                if (+user.all_facilities === 1) {
                    selectedAssetsTree = [{value: "all", label: "All"}];
                } else if (user.locations) {
                    Object.keys(user.locations).map(locationId => selectedAssetsTree.push({value: +locationId, label: Helper.findLocationFullNameById(assetTree, locationId, user.locations[locationId])}));
                }

                const availableAssetsTree = [
                    {value: "all", label: "All", disabled: !(this.props.auth.userCan(SUPER_ADMIN_PERMISSION) || +this.props.user.all_facilities === 1)},
                    ...assetTree.map(location => {
                        return {
                            label: location.full_name,
                            value: +location.id,
                            parent_id: location.parent_id,
                            disabled: !(this.props.auth.userCan(SUPER_ADMIN_PERMISSION) || +this.props.user.all_facilities === 1 || this.props.user.allowedLocationIds.includes(location.id))
                        };
                    })
                ];

                selectedAssetsTree.map(option => {
                    this.toggleAssetsTreeChildren(availableAssetsTree, option, option.value, true);
                });

                this.setState({
                    loader: false,
                    permissions: permissionBlocks[WW2_FRONTEND_GROUP] || [],
                    availablePermissionBlocks: availablePermissionBlocks[WW2_FRONTEND_GROUP] || [],
                    data: {
                        id: user.id,
                        name_first: user.name_first,
                        name_last: user.name_last,
                        email: user.email,
                        contact_email: user.contact_email === null ? "" : user.contact_email,
                        username: user.username,
                        selectedAssetsTree,
                        phone: user.phone === null ? "" : user.phone,
                        timezone_id: user.timezone_id,
                        company: user.company === null ? "" : user.company,
                        locations: user.locations,
                        use_metric: user.use_metric,
                        receive_hardware_notify: user.receive_hardware_notify,
                        personal_settings: {
                            units: Converter.convertUnitsSettingsToMetricList(this.props.chartTypes, _get(user , "personal_settings.units", {})),
                            datetime_format: _get(user , "personal_settings.datetime_format", Object.keys(DATEFORMAT)[0])
                        },
                        permissions: user.permissions || [],
                        all_facilities: +user.all_facilities === 1 ? 1 : 0,
                        alert_levels: user.alert_levels,
                        email_frequency: user.email_frequency || 0,
                        unsubscribeAlertGroup: [],
                        unsubscribeDeviceAlertGroup: [],
                        isWaitesAccount: _get(user, "isWaitesAccount", false),
                    },
                    availableAssetsTree,
                    breadcrumbs: [...this.state.breadcrumbs, {
                        name: user.username
                    }],
                    selectedAssetsTree,
                    userPermissions,
                    isSuperAdmin: (userPermissions.global || []).indexOf(SUPER_ADMIN_PERMISSION) !== -1,
                    alertLevels: alertLevels.list,
                    timezones,
                    showContactEmail: ((user.email.indexOf("+") !== -1)),
                    userSubscriptions
                });
            });
        } else {
            Promise.all([
                ApiUserPermissions.getListByGroups(),
                ApiAlertLevel.getForProfile(),
                ApiTimezone.list()
            ]).then(([{permissions = {}, permissionBlocks = {}, availablePermissionBlocks = {}}, alertLevels, timezones]) => {
                let userPermissions = {},
                    selectedAssetsTree = [/*{value: "all", label: "All"}*/];
                userPermissions[WW2_FRONTEND_GROUP] = [];
                (permissions[WW2_FRONTEND_GROUP] || []).map(permission => {
                    if (this.props.auth.userCan(permission.name)) {
                        userPermissions[WW2_FRONTEND_GROUP].push(permission.name);
                    }
                });

                const availableAssetsTree = [
                    {value: "all", label: "All", disabled: !(this.props.auth.userCan(SUPER_ADMIN_PERMISSION) || +this.props.user.all_facilities === 1)},
                    ...assetTree.map(location => {
                        return {
                            label: location.full_name,
                            value: +location.id,
                            parent_id: location.parent_id,
                            disabled: !(this.props.auth.userCan(SUPER_ADMIN_PERMISSION) || +this.props.user.all_facilities === 1 || this.props.user.allowedLocationIds.includes(location.id))
                        };
                    })
                ];

                this.setState({
                    loader: false,
                    permissions: permissionBlocks[WW2_FRONTEND_GROUP] || [],
                    availablePermissionBlocks: availablePermissionBlocks[WW2_FRONTEND_GROUP] || [],
                    userPermissions,
                    availableAssetsTree,
                    selectedAssetsTree,
                    data: {
                        ...this.state.data,
                        personal_settings: {
                            units: Converter.convertUnitsSettingsToMetricList(this.props.chartTypes),
                            datetime_format: Object.keys(DATEFORMAT)[0]
                        },
                        all_facilities: null
                    },
                    alertLevels: alertLevels.list,
                    timezones,
                    showContactEmail: false
                });
            });
        }
    }

    handleSubmit() {
        const validation = this.validator.validate(this.state.data);
        const {data, userPermissions} = Object.assign({}, this.state);
        const {emailEventsData} = this.props;
        _set(data, "email_events", emailEventsData);

        this.setState(
            {inProgress: true},
            () => {
                this.setState({validation}, () => {
                    if (validation.isValid) {
                        data.permissions = userPermissions;
                        if(this.state.userId) {
                            this.handleSubmitEditUser(data).catch(err => {
                                if (err.errors) {
                                    const validation = this.state.validation;

                                    Object.keys(err.errors).map(key => {
                                        validation[key].isValid = false;
                                        validation[key].message = err.errors[key][0];
                                    });

                                    this.setState({
                                        validation,
                                        inProgress: false,
                                        data: {
                                            ...this.state.data,
                                            personal_settings: {
                                                ...this.state.data.personal_settings,
                                                units: Converter.convertUnitsSettingsToMetricList(this.props.chartTypes, this.state.data.personal_settings.units)
                                            }
                                        }
                                    });
                                } else {
                                    this.setState({
                                        inProgress: false,
                                        data: {
                                            ...this.state.data,
                                            personal_settings: {
                                                ...this.state.data.personal_settings,
                                                units: Converter.convertUnitsSettingsToMetricList(this.props.chartTypes, this.state.data.personal_settings.units)
                                            }
                                        }
                                    });
                                }
                            });
                        } else {
                            this.handleSubmitAddUser(data).catch(err => {
                                if (err.errors) {
                                    const validation = this.state.validation;
                                    Object.keys(err.errors).map(key => {
                                        validation[key].isValid = false;
                                        validation[key].message = err.errors[key][0];
                                    });

                                    this.setState({
                                        validation,
                                        inProgress: false,
                                        data: {
                                            ...this.state.data,
                                            personal_settings: {
                                                ...this.state.data.personal_settings,
                                                units: Converter.convertUnitsSettingsToMetricList(this.props.chartTypes, this.state.data.personal_settings.units)
                                            }
                                        }
                                    });
                                } else {
                                    this.setState({
                                        inProgress: false,
                                        data: {
                                            ...this.state.data,
                                            personal_settings: {
                                                ...this.state.data.personal_settings,
                                                units: Converter.convertUnitsSettingsToMetricList(this.props.chartTypes, this.state.data.personal_settings.units)
                                            }
                                        }
                                    });
                                }
                            });
                        }
                    } else {
                        this.setState({inProgress: false});
                        Toast.warning("You must fill in all required fields.");
                    }
                });
            }
        );

    }

    handleSubmitAddUser(data) {
        data.personal_settings.units = Converter.convertMetricListToUnitsSettings(data.use_metric, data.personal_settings.units, this.props.chartTypes);
        data.locations = this.state.selectedAssetsTree.filter(item => item.value != "all");
        return ApiUser.create(data).then(() => {
            this.props.history.push("/users#added=1");
        });
    }

    handleSubmitEditUser(data) {
        data.personal_settings.units = Converter.convertMetricListToUnitsSettings(data.use_metric, data.personal_settings.units, this.props.chartTypes);
        data.locations = this.state.selectedAssetsTree.filter(item => item.value != "all");
        return ApiUser.update(data).then(() => {
            this.props.history.push("/users#updated=1");
        });
    }

    searchChange(value) {
        this.handleFilterChange({query: value});
    }

    toggleAssetsTreeChildren = (availableAssetsTree, selectedValues, parent_id, state = true) => {
        const isExist = _size(_find(availableAssetsTree, {parent_id}));
        if (isExist) {
            availableAssetsTree.map(location => {
                if (+location.parent_id === +parent_id) {
                    location.disabled = state;
                    const selectedValueKey = _findKey(selectedValues, {value: location.value});
                    if (state && selectedValueKey) {
                        delete selectedValues[selectedValueKey];
                    }
                    this.toggleAssetsTreeChildren(availableAssetsTree, selectedValues, location.value, state);
                }
            });
        }
        return true;
    };

    onChangeAvailableAssetsTree(value, event) {
        if (event.action !== "deselect-option") {
            const {data, availableAssetsTree} = this.state;
            const {updateFacilityIds} = this.props;
            const id = event.action == "select-option" ? event.option.value : event.removedValue.value;


            if (event.action == "select-option" && event.option && event.option.value == "all") {
                value = [{value: "all", label: "All"}];
                data.all_facilities = 1;
            }

            if (event.action == "remove-value" && event.removedValue) {
                this.toggleAssetsTreeChildren(availableAssetsTree, value, id, false);

                if (!value?.length) {
                    data.all_facilities = null;
                }
            }
            if (event.action == "select-option" && event.option && event.option.value != "all") {
                value = value.filter(item => item.value != "all");
                data.all_facilities = 0;
                this.toggleAssetsTreeChildren(availableAssetsTree, value, id);
            }

            updateFacilityIds(data.all_facilities ? "all" : value || []);
            data.selectedAssetsTree = value;
            this.setState({data, selectedAssetsTree: value || [], availableAssetsTree});
        }
    }

    onChangeUnsubscribeAlertGroup = (unsubscribeAlertGroup) => {
        this.setState({data: {...this.state.data, unsubscribeAlertGroup: unsubscribeAlertGroup}});
    }

    onChangeUnsubscribeDeviceAlertGroup = (unsubscribeDeviceAlertGroup) => {
        this.setState({data: {...this.state.data, unsubscribeDeviceAlertGroup: unsubscribeDeviceAlertGroup}});
    }

    render() {
        const {
            breadcrumbs,
            loader,
            globalSearchString,
            permissions,
            title,
            inProgress,
            data,
            validation,
            isSuperAdmin,
            userPermissions,
            availableAssetsTree,
            selectedAssetsTree,
            alertLevels,
            timezones,
            showContactEmail,
            userSubscriptions,
            availablePermissionBlocks
        } = this.state;
        const {
            user,
            auth,
            loaderEmailEvents,
            emailEventsData,
            urgencies,
            facilities,
            emailEvents,
            selectedFacilityId,
            changeFacility,
            changeData,
            toggleAllForFacility,
            toggleAll
        } = this.props;

        if (!auth.isWaitesAccount && _get(data, "isWaitesAccount")) {
            return <Page/>;
        }

        return (
            <div className="form form--user">
                <HeaderSimple
                    breadcrumbs={ breadcrumbs }
                    globalSearchString={ globalSearchString }
                    handleChangeGlobalSearch={ this.handleChangeGlobalSearch }
                />
                <div className="subheader">
                    <div className="subheader-title">{title}</div>
                </div>

                {loader
                    ?
                    <div className="loader-fullscreen"><Loader/></div>
                    :
                    <React.Fragment>
                        <div className="block">
                            <div className="block-body">
                                <div className="row">
                                    <div className="col-12 mb-2">
                                        <b>Personal Information</b>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="form-group">
                                            <label className="form-label">Username: <span className="color-danger">*</span></label>
                                            <div>
                                                <input
                                                    className={"form-control" + (validation.username.isValid || !validation.username.message ? "" : " is-invalid")}
                                                    type="text"
                                                    name="username"
                                                    defaultValue={data.username}
                                                    onChange={this.onChange}
                                                    disabled={inProgress}
                                                />
                                                <ValidationError message={validation.username.message}/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Company: <span className="color-danger">*</span></label>
                                            <div>
                                                <input
                                                    className={"form-control" + (validation.company.isValid || !validation.company.message ? "" : " is-invalid")}
                                                    type="text"
                                                    name="company"
                                                    defaultValue={data.company}
                                                    onChange={this.onChange}
                                                    disabled={inProgress}
                                                />
                                                <ValidationError message={validation.company.message}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="form-group">
                                            <label className="form-label">First Name: <span className="color-danger">*</span></label>
                                            <div>
                                                <input
                                                    className={"form-control" + (validation.name_first.isValid || !validation.name_first.message ? "" : " is-invalid")}
                                                    type="text"
                                                    name="name_first"
                                                    defaultValue={data.name_first}
                                                    onChange={this.onChange}
                                                    disabled={inProgress}
                                                />
                                                <ValidationError message={validation.name_first.message}/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Email: <span className="color-danger">*</span></label>
                                            <div>
                                                <input
                                                    className={"form-control" + (validation.email.isValid || !validation.email.message ? "" : " is-invalid")}
                                                    type="text"
                                                    name="email"
                                                    defaultValue={data.email}
                                                    onChange={this.onChange}
                                                    disabled={inProgress}
                                                />
                                                <ValidationError message={validation.email.message}/>
                                            </div>
                                        </div>
                                    </div>
                                    {showContactEmail ?
                                        <React.Fragment>
                                            <div className="col-md-6 col-lg-4">
                                                <LastName
                                                    lastName={data.name_last}
                                                    isValid={validation.name_last.isValid}
                                                    validMessage={validation.name_last.message}
                                                    inProgress={inProgress}
                                                    onChange={this.onChange}
                                                />
                                                <div className="form-group">
                                                    <label className="form-label">Contact
                                                        Email:</label>
                                                    <div>
                                                        <input
                                                            title={"Please provide a Contact Email Address since your Email Address contains a plus sign."}
                                                            className={"form-control" + (validation.contact_email.isValid || !validation.contact_email.message ? "" : " is-invalid")}
                                                            type="text"
                                                            name="contact_email"
                                                            defaultValue={data.contact_email}
                                                            onChange={this.onChange}
                                                            disabled={inProgress}
                                                            placeholder={"Please provide a Contact Email Address since your Email Address contains a plus sign."}
                                                            style={{overflow: "hidden", textOverflow: "ellipsis"}}
                                                        />
                                                        <ValidationError message={validation.contact_email.message}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-4">
                                                <UserPhone
                                                    phone={data.phone}
                                                    inProgress={inProgress}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <div className="col-md-6 col-lg-4">
                                                <LastName
                                                    lastName={data.name_last}
                                                    isValid={validation.name_last.isValid}
                                                    validMessage={validation.name_last.message}
                                                    inProgress={inProgress}
                                                    onChange={this.onChange}
                                                />
                                                <UserPhone
                                                    phone={data.phone}
                                                    inProgress={inProgress}
                                                    onChange={this.onChange}
                                                />
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="block">
                            <div className="block-body">
                                <div className="row">
                                    <div className="col-12 mb-2">
                                        <b>Personal Settings</b>
                                    </div>
                                    <div className="col-md-4 col-lg-3">
                                        <div className="form-group">
                                            <label className="form-label">Time Zone</label>
                                            <div>
                                                <SelectWrapper
                                                    style={{
                                                        width:"100%",
                                                        display:"block",
                                                    }}
                                                    name="timezone_id"
                                                    value={data.timezone_id}
                                                    onChange={this.onChange}
                                                    disabled={inProgress}
                                                >
                                                    {timezones.map(timezone => (
                                                        <option key={timezone.id} value={timezone.id}>{timezone.title}</option>
                                                    ))}
                                                </SelectWrapper>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">Date Format</label>
                                            <div>
                                                <SelectWrapper
                                                    style={{
                                                        width:"100%",
                                                        display:"block",
                                                    }}
                                                    name="personal_settings.datetime_format"
                                                    value={data.personal_settings.datetime_format}
                                                    onChange={this.onChange}
                                                    disabled={inProgress}
                                                >
                                                    {Object.keys(DATEFORMAT).map((item) => (
                                                        <option key={item} value={item}>{DATEFORMAT[item]}</option>
                                                    ))}
                                                </SelectWrapper>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-3 pl-4">
                                        <div className="form-group">
                                            <label className="form-label">Measurement Unit</label>
                                            <div className="form-list form-list--column mt-2">
                                                <label className="form-radio">
                                                    <input
                                                        type="radio"
                                                        name="use_metric"
                                                        onChange={this.onChange}
                                                        disabled={inProgress}
                                                        value="0"
                                                        defaultChecked={+data.use_metric === 0}/> Metric (mm, mm/sec, °C) <span/>
                                                </label>
                                                <label className="form-radio">
                                                    <input
                                                        type="radio"
                                                        name="use_metric"
                                                        onChange={this.onChange}
                                                        disabled={inProgress}
                                                        value="1"
                                                        defaultChecked={+data.use_metric === 1}/> US (mils, in/sec, °F) <span/>
                                                </label>
                                                <label className="form-radio">
                                                    <input
                                                        type="radio"
                                                        name="use_metric"
                                                        onChange={this.onChange}
                                                        disabled={inProgress}
                                                        value="2"
                                                        defaultChecked={+data.use_metric === 2}/> Custom <span/>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={+data.use_metric === 2 ? "col-md-4 col-lg-6 dashed-board" : "col-md-4 col-lg-6 dashed-board d-none"}>
                                        <div className="form-group mb-4">
                                            <label className="form-label">&nbsp;</label>
                                            <div className="row">
                                                {data.personal_settings.units.map((metricItem,key) => {
                                                    return (
                                                        <div className="col-md-6 form-list mb-2" key={key}>
                                                            <div className="row">
                                                                <div className="col-xxl-4 col-xl-12">
                                                                    <label className="form-label metric-label">{metricItem.label}</label>
                                                                </div>

                                                                <div className="col-xxl-7 col-xl-12">
                                                                    <div
                                                                        className="row"
                                                                        style={{
                                                                            alignItems: "center",
                                                                            height: "100%"
                                                                        }}
                                                                    >
                                                                        {Object.keys(metricItem.units).map((metricType, keyMetricType) =>
                                                                            <div className="col-md-6" key={keyMetricType} >
                                                                                <label className="form-radio">
                                                                                    <input
                                                                                        type="radio"
                                                                                        name={"personal_settings.units." + key + ".selected"}
                                                                                        onChange={this.onChange}
                                                                                        disabled={inProgress}
                                                                                        value={metricType}
                                                                                        checked={_get(data, "personal_settings.units." + key + ".selected") === metricType}
                                                                                    />
                                                                                    {metricItem.units[metricType]}
                                                                                    <span/>
                                                                                </label>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="col-xxl-1 col-md-auto"></div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="block">
                            <div className="block-body">
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <b>Available Asset Tree Branches <span className="color-danger">*</span></b>
                                    </div>
                                    <div className="col-md-12 mb-4">
                                        <Select
                                            className={"form-control-select" + (validation.all_facilities.isValid || !validation.all_facilities.message ? "" : " is-invalid")}
                                            isMulti
                                            placehoder={false}
                                            isSearchable={false}
                                            isClearable={false}
                                            styles={ isDarkTheme === "true" ? darkModeSelectStyles : {} }
                                            value={selectedAssetsTree}
                                            hideSelectedOptions={false}
                                            options={availableAssetsTree}
                                            onChange={this.onChangeAvailableAssetsTree}
                                            isOptionDisabled={(option) => option.disabled === true}
                                        />
                                        <ValidationError message={validation.all_facilities.message}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <b>Permissions</b>
                                    </div>
                                    <PermissionsBlocks
                                        user={user}
                                        auth={auth}
                                        inProgress={inProgress}
                                        isSuperAdmin={isSuperAdmin}
                                        onChangePermissions={this.onChangePermissions}
                                        permissions={permissions}
                                        userPermissions={userPermissions}
                                        availablePermissionBlocks={availablePermissionBlocks}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="block">
                            <div className="block-body">
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <b>Alert Email Notifications</b>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group row align-items-center">
                                            <label className="col-2 form-label pull-right-label mb-0 pr-3">
                                                Alert Email Frequency
                                            </label>
                                            <div className="col-10 col-lg-4">
                                                <SelectWrapper
                                                    style={{
                                                        width:"100%",
                                                        display:"block",
                                                    }}
                                                    name="email_frequency"
                                                    value={data.email_frequency}
                                                    onChange={this.onChange}
                                                    disabled={inProgress}
                                                >
                                                    {Object.keys(ALARM_EMAIL_FREQUENCY).map((item) => (
                                                        <option key={item} value={item}>{ALARM_EMAIL_FREQUENCY[item]}</option>
                                                    ))}
                                                </SelectWrapper>
                                            </div>
                                        </div>
                                        {alertLevels.length > 0 &&
                                            <div className="form-group row align-items-center">
                                                <label className="col-2 form-label pull-right-label mb-0 pr-3">
                                                    Receive Alert Email Notifications for
                                                </label>
                                                <div className="col-10 col-lg-5">
                                                    <div className="row">
                                                        {alertLevels.map(alertLevel =>
                                                            <div className="col-md-3" key={alertLevel.id}>
                                                                <label className="form-checkbox form-checkbox--black mb-0">
                                                                    <input
                                                                        type="checkbox"
                                                                        disabled={inProgress}
                                                                        value={alertLevel.id}
                                                                        onChange={this.onChangeAlertLevel}
                                                                        defaultChecked={data.alert_levels.indexOf(alertLevel.id) !== -1}
                                                                    /> {alertLevel.name} <span/>
                                                                </label>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <UserAlertGroup
                                            userSubscriptions={userSubscriptions}
                                            unsubscribe={_get(data, "unsubscribeAlertGroup", [])}
                                            updateUnsubscribe={this.onChangeUnsubscribeAlertGroup}
                                            inProgress={inProgress}
                                            labelClass={"col-2"}
                                            disabled={!auth.userCan(SUPER_ADMIN_PERMISSION) && !auth.userCan(MANAGE_SUBSCRIPTIONS_PERMISSION)}
                                        />
                                        {[SELF_SERVICE, HYBRID_SERVICE].indexOf(auth.customer.system_version) !== -1 && auth.userCan(SHOW_SERVICE_NOTIFICATIONS_PERMISSION) && ((userPermissions[WW2_FRONTEND_GROUP] || []).indexOf(SHOW_SERVICE_NOTIFICATIONS_PERMISSION) !== -1 || isSuperAdmin) &&
                                            <React.Fragment>
                                                <div className="form-group row" style={{borderTop: "1px solid #ddd", paddingTop: 10}}>
                                                    <label className="col-2 form-label pull-right-label">
                                                        Receive Event Log Notifications
                                                    </label>
                                                    <div className="col-10 col-lg-5">
                                                        <label className="form-checkbox form-checkbox--black">
                                                            <input
                                                                id="hardware-alerts-notify"
                                                                name="receive_hardware_notify"
                                                                type="checkbox"
                                                                disabled={inProgress}
                                                                value={+!+data.receive_hardware_notify}
                                                                onChange={this.onChange}
                                                                defaultChecked={+data.receive_hardware_notify === 1}
                                                            /><span/>
                                                        </label>
                                                    </div>
                                                </div>
                                                <UserDeviceAlertGroup
                                                    userSubscriptions={userSubscriptions}
                                                    unsubscribe={_get(data, "unsubscribeDeviceAlertGroup", [])}
                                                    updateUnsubscribe={this.onChangeUnsubscribeDeviceAlertGroup}
                                                    inProgress={inProgress}
                                                    labelClass={"col-2"}
                                                    disabled={!auth.userCan(SUPER_ADMIN_PERMISSION) && !auth.userCan(MANAGE_SUBSCRIPTIONS_PERMISSION)}
                                                />
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="block">
                            <div className="block-body">
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <b>Action Item Email Notifications</b>
                                    </div>
                                    <div className="col-md-12 mb-4">
                                        <SubscriptionActonItems
                                            loader={loaderEmailEvents}
                                            data={emailEventsData}
                                            urgencies={urgencies}
                                            facilities={facilities}
                                            emailEvents={emailEvents}
                                            user={data}
                                            selectedFacilityId={selectedFacilityId}
                                            changeFacility={changeFacility}
                                            changeData={changeData}
                                            toggleAllForFacility={toggleAllForFacility}
                                            toggleAll={toggleAll}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-right">
                            <Link to="/users" className="btn btn-sm btn-secondary">Cancel</Link>
                            <Button color="primary" size="sm" className="ml-2" disabled={this.state.inProgress} onClick={this.handleSubmit}>Save</Button>
                        </div>
                    </React.Fragment>
                }
            </div>
        );
    }
}

const HeaderRight = ({history, globalSearchString, handleChangeGlobalSearch}) => (
    <div className="header-rules">
        <div className="filter-item ml-2">
            <SearchInput
                history={history}
                disabled={false}
                placeholder="Global Search"
                query={globalSearchString}
                onChange={handleChangeGlobalSearch}
                additionalClasses="form-control-sm"
                handleSearch="global"
            />
        </div>
    </div>
);
/**
 * Components LastName and UserPhone are necessary for the correct working of the logic for displaying the contact
 * e-mail field
 **/
const LastName = ({lastName, isValid, validMessage, inProgress, onChange}) => (
    <div className="form-group">
        <label className="form-label">Last Name: <span
            className="color-danger">*</span></label>
        <div>
            <input
                className={"form-control" + (isValid || !validMessage ? "" : " is-invalid")}
                type="text"
                name="name_last"
                defaultValue={lastName}
                onChange={onChange}
                disabled={inProgress}
            />
            <ValidationError message={validMessage}/>
        </div>
    </div>
);

const UserPhone = ({phone, inProgress, onChange}) => (
    <div className="form-group">
        <label className="form-label">Phone:</label>
        <div>
            <input
                className="form-control"
                type="text"
                name="phone"
                defaultValue={phone}
                onChange={onChange}
                disabled={inProgress}
            />
        </div>
    </div>
);

const PermissionsBlocks = ({permissions, isSuperAdmin, inProgress, onChangePermissions, userPermissions, auth, availablePermissionBlocks}) => {

    const isDisabledPermission = (permissionName = "") => {
        if ((userPermissions[WW2_FRONTEND_GROUP] || []).indexOf(MY_DASHBOARD_ACCESS_PERMISSION) === -1 && permissionName !== MY_DASHBOARD_ACCESS_PERMISSION) {
            return true;
        }

        switch (permissionName) {
            case MANAGE_FULL_SERVICE_EQUIPMENTS_PERMISSION:
                return (userPermissions[WW2_FRONTEND_GROUP] || []).indexOf(SHOW_FULL_SERVICE_EQUIPMENTS_PERMISSION) === -1;
            case EDIT_ALARMS_PERMISSION:
                return (userPermissions[WW2_FRONTEND_GROUP] || []).indexOf(SHOW_ALARMS_PERMISSION) === -1;
        }

        return isSuperAdmin || inProgress;
    };

   const accessFullServicePermission = (auth.userCan(DASHBOARD_ACCESS_PERMISSION, WW2_DASHBOARD_GROUP) &&
       <div>
           <label
               className="form-checkbox form-checkbox--black"
               title="Access Full-Service Dashboard"
               onClick={() => isSuperAdmin ? swal("", "You cannot change permission for Super Admin.", "error") : {}}
           >
               <input
                   type="checkbox"
                   name={DASHBOARD_ACCESS_PERMISSION}
                   onChange={e => onChangePermissions(e, WW2_DASHBOARD_GROUP)}
                   disabled={isSuperAdmin || inProgress}
                   value={+((userPermissions[WW2_DASHBOARD_GROUP] || []).indexOf(DASHBOARD_ACCESS_PERMISSION) !== -1)}
                   defaultChecked={isSuperAdmin || (userPermissions[WW2_DASHBOARD_GROUP] || []).indexOf(DASHBOARD_ACCESS_PERMISSION) !== -1}
               /> Access Full-Service Dashboard<span/>
           </label>
       </div>);

    return Object.entries(permissions).map(([blockName, permissionList]) => {
        return  <>
            <div className="col-md-3">
                <span className={"font-weight-bold"}>{_get(availablePermissionBlocks, blockName)}:</span>
            {permissionList.map(permission => {
                if (auth.userCan(permission.name)) {
                    const value = ( userPermissions[WW2_FRONTEND_GROUP] || []).indexOf(permission.name) !== -1;
                    const isChecked = isSuperAdmin || value;
                    const disabledPermission = isDisabledPermission(_get(permission, "name"));

                    return (<div key={permission.id}>
                        <label
                            className={`form-checkbox form-checkbox--black  ${disabledPermission && "disabled"}`}
                            title={permission.description}
                            onClick={() => isSuperAdmin ? swal("", "You cannot change permission for Super Admin.", "error") : {}}
                        >
                            <input
                                type="checkbox"
                                name={permission.name}
                                onChange={e => onChangePermissions(e, WW2_FRONTEND_GROUP)}
                                disabled={disabledPermission}
                                value={+value}
                                defaultChecked={isChecked}
                                checked={isChecked}
                            /> {permission.id === 15 ? "Access Self-Service Dashboard" : permission.short_description}<span/>
                        </label>
                    </div>);
                }
            })}
            {blockName === DASHBOARD_ACCESS_BLOCK && accessFullServicePermission}
            </div>
        </>;
    });
};

PermissionsBlocks.propTypes = {
    permissions: PropTypes.object,
    auth: PropTypes.object,
    isSuperAdmin: PropTypes.bool,
    inProgress: PropTypes.bool,
    onChangePermissions: PropTypes.func,
    userPermissions: PropTypes.array,
    availablePermissionBlocks: PropTypes.array,
};

HeaderRight.propTypes = {
    history: PropTypes.object,
    globalSearchString: PropTypes.string,
    handleChangeGlobalSearch: PropTypes.func,
};

LastName.propTypes = {
    lastName: PropTypes.string,
    isValid: PropTypes.bool,
    validMessage: PropTypes.string,
    inProgress: PropTypes.bool,
    onChange: PropTypes.func
};

UserPhone.propTypes = {
    phone: PropTypes.string,
    inProgress: PropTypes.bool,
    onChange: PropTypes.func
};

UserForm.propTypes = {
    userId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.bool
    ]),
    title: PropTypes.string,
    auth: PropTypes.object,
    user: PropTypes.object,
    chartTypes: PropTypes.object,
    handleSubmit: PropTypes.func,
    availablePermissionGroup: PropTypes.object,
    permissionList: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,

    // From Action Items SubscriptionDataHandle
    loaderEmailEvents: PropTypes.bool,
    emailEventsData: PropTypes.object,
    urgencies: PropTypes.object,
    facilities: PropTypes.array,
    assetTree: PropTypes.array,
    emailEvents: PropTypes.array,
    selectedFacilityId: PropTypes.number,
    changeFacility: PropTypes.func,
    changeData: PropTypes.func,
    onSave: PropTypes.func,
    toggleAllForFacility: PropTypes.func,
    toggleAll: PropTypes.func,
    updateFacilityIds: PropTypes.func
};

export default withGlobalStore(UserForm);