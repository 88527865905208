import ApiLocation from "../../../api/location";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {API_CONSTANTS} from "../../../api/constants/constants";

const assetDeleteFn = ({id, moveToAsset}) => ApiLocation.delete(id, moveToAsset);

export const useAssetDeleteMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: assetDeleteFn,
        onSuccess: () => {
            queryClient.invalidateQueries([API_CONSTANTS.GET_ASSETS_LIST]);
        },
    });
};
