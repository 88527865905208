import {useMutation, useQueryClient} from "@tanstack/react-query";
import EquipmentTypeApi from "../../../api/equipmentType";
import {API_CONSTANTS} from "../../../api/constants/constants";

const useAddEquipmentType = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (newType) => EquipmentTypeApi.create({name: newType}),
        onSuccess: () => {
            return queryClient.invalidateQueries({queryKey: [API_CONSTANTS.GET_EQUIPMENT_TYPES]});
        },
    });
};

export default useAddEquipmentType;
