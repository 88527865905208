import React from "react";
import SelectWrapper from "../../../../../helpers/select-wrapper";
import PropTypes from "prop-types";

const ActionItemSelect = ({name, value, label, options, onChange, className}) => {
    return (
        <div className={className}>
            <SelectWrapper
                value={value}
                name={name}
                onChange={onChange}
            >
                <option value="">{label}</option>
                {options.map((option) => (
                    <option
                        key={option.value}
                        value={option.value}
                    >
                        {option.label}
                    </option>
                ))}
            </SelectWrapper>
        </div>
    );
};

ActionItemSelect.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    label: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
    className: PropTypes.string,
};

export default ActionItemSelect;
