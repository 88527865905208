import React from "react";
import PropTypes from "prop-types";
import SelectWrapper from "../../../../../helpers/select-wrapper";
import ValidationError from "../../../../../layouts/ValidationError/ValidationError";
import styles from "./style.module.scss";

const ActionItemSerialSelect = ({type, title, data, options, setSearchText, searchText, isLoading, handleSelectSerial, validation, isWwAiActive}) => {
    const handleSensorSearch = (inputValue) => {
        if (inputValue.length >= 3) {
            setSearchText(inputValue);
        } else if (!inputValue.length) {
            setSearchText("");
        }
    };

    console.log(isWwAiActive(data));

    return (
        data.hardwareType === type && (
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <div
                        className={`form-group ${validation?.serial?.isValid || !validation?.serial?.message ? "" : "is-invalid"} ${
                            isWwAiActive(data) ? `${styles.formGroupMargin}` : ""
                        }`}
                    >
                        <label className="text-right form-label">
                            {title}: <span className="color-danger">*</span>
                        </label>

                        <SelectWrapper
                            name={"serial"}
                            onChange={(e, data) => handleSelectSerial(e, data)}
                            isSearchable={true}
                            onInputChange={handleSensorSearch}
                            noOptionsMessage={() => (searchText.length < 3 ? "Enter three or more characters to search" : "No items match")}
                            loadingMessage={() => "Loading"}
                            isLoading={isLoading}
                        >
                            <option value="">Enter 3 or more characters...</option>
                            {options.map((option) => (
                                <option
                                    key={option.value}
                                    value={option.value}
                                    disabled={option.isInactive}
                                    className="d-flex align-items-center justify-content-between"
                                >
                                    {option.label}
                                    {isWwAiActive(option) && <div className={styles.dot}></div>}
                                </option>
                            ))}
                        </SelectWrapper>
                        {isWwAiActive(data) ? (
                            <div className={styles.invalid + " invalid-feedback"}>
                                An Action Item is currently open for this installation point that must be addressed before you can open a new one.
                            </div>
                        ) : (
                            <ValidationError message={validation.serial.message} />
                        )}
                    </div>
                </div>
            </div>
        )
    );
};

ActionItemSerialSelect.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    data: PropTypes.object,
    options: PropTypes.array,
    setSearchText: PropTypes.func,
    searchText: PropTypes.string,
    isLoading: PropTypes.bool,
    handleSelectSerial: PropTypes.func,
    validation: PropTypes.object,
    isWwAiActive: PropTypes.func,
};

export default ActionItemSerialSelect;
