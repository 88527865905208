import {useQuery} from "@tanstack/react-query";
import ApiLocation from "../../../api/location";
import Helper from "../../../helpers/helper";
import {API_CONSTANTS} from "../../../api/constants/constants";

const assetListKey = ({onlyWithEquipment, isFlat, sort}) => [API_CONSTANTS.GET_ASSETS_LIST, {onlyWithEquipment, isFlat, sort}];
const assetListFn = ({onlyWithEquipment}) => ApiLocation.list({onlyWithEquipment});

export const useAssetListQuery = ({onlyWithEquipment, isFlat = true, sort = null} = {}) => {
    return useQuery({
        queryFn: () => assetListFn(onlyWithEquipment),
        queryKey: assetListKey({
            onlyWithEquipment,
            isFlat,
            sort,
        }),
        select: (res) => {
            if (!isFlat) {
                return res.list;
            }

            return Helper.transformLocations(res.list).map((location) => {
                return {
                    label: location.full_name,
                    value: +location.id,
                    parent_id: location.parent_id,
                };
            });
        },
        staleTime: 0,
    });
};
