import {useMutation} from "@tanstack/react-query";
import SsdApi from "../../../api/ssd";
import Toast from "../../../pages/shared/toast";

const actionItemCreateFn = (data) => SsdApi.createActionItem(data);

export const useCreateActionItemMutation = (filters) => {
    return useMutation({
        mutationFn: actionItemCreateFn,
        onSuccess: (res) => {
            Toast.success(res.data.message);
            filters.onSuccess();
        },
        onError: (res) => {
            Toast.error(res.message);
        },
    });
};
