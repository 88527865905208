export const hasAssetTreeBranchName = (node, nextParent) => {
    const {name, id} = node;
    const nextParentChildren = nextParent?.children;

    if (!nextParentChildren) {
        return false;
    }

    return nextParentChildren.find((child) => name === child?.name && child?.id !== id);
};
