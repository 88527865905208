import React from "react";
import SortableTree, {getFlatDataFromTree} from "react-sortable-tree";
import {Link} from "react-router-dom";
import Helper from "../../../../helpers/helper";
import PropTypes from "prop-types";
import {hasAssetTreeBranchName} from "../../helpers/hasAssetTreeBranchName";
import {useHistory} from "react-router";

const ROW_HEIGHT = 62;

const LocationsAssetTree = ({setTreeData, treeData, auth, autoSort, user, onDeleteLocation}) => {
    const history = useHistory();
    const calculateHeight = (treeData) => {
        const flat = getFlatDataFromTree({treeData, getNodeKey: (node) => node.id});
        return flat.length * ROW_HEIGHT + 75;
    };

    const onDrag = ({node}) => {
        const facilityLocation = treeData.find((facility) => facility.facility_id === node.facility_id);
        const facilityLocationId = facilityLocation?.id;

        if (auth.userCan("editAssetsTree") && !autoSort) {
            return user.all_facilities || (+node.is_lock === 0 && (user.all_facilities || user.allowedLocationIds.includes(facilityLocationId)));
        }

        return false;
    };

    const canDrop = ({prevPath, nextPath, node, nextParent}) => {
        if (auth.userCan("editAssetsTree")) {
            const hasName = hasAssetTreeBranchName(node, nextParent);

            if (hasName) {
                return !hasName;
            }

            return (!autoSort || nextPath.length > 1) && prevPath[0] === nextPath[0];
        }

        return false;
    };

    const generateNodeProps = ({node}) => {
        let locationName = (
            <span>
                <span className={"tree-facility-icon"}>
                    <i className="fa fa-home fa-2x" />
                </span>{" "}
                <span className="tree-facility-name">{node.name}</span>
            </span>
        );

        return {
            title: +node.is_lock ? locationName : node.name,
            className: "k-sortable-row",
            buttons: [
                <Link
                    key={`show-${node.id}`}
                    to={`/equipments/${node.id}`}
                    className="btn btn-sm btn-icon btn-primary"
                    title="View"
                >
                    <i className="fa fa-eye" />
                </Link>,
                ...(auth.userCan("editAssetsTree") && +node.is_lock === 0 && (user.all_facilities || user.allowedLocationIds.includes(node.id))
                    ? [
                          <span
                              key={`edit-${node.id}`}
                              className="btn btn-sm btn-icon btn-warning"
                              onClick={() =>
                                  history.push(
                                      Helper.setHashParams({
                                          modal: "edit",
                                          id: node.id,
                                      })
                                  )
                              }
                              title="Edit"
                          >
                              <i className="fas fa-pen" />
                          </span>,
                          <span
                              key={`del-${node.id}`}
                              className="btn btn-sm btn-icon btn-danger"
                              style={{marginLeft: 5}}
                              onClick={() => onDeleteLocation(node.id)}
                              title="Delete"
                          >
                              <i className="fas fa-trash-alt" />
                          </span>,
                      ]
                    : []),
            ],
        };
    };

    return (
        <div className="block">
            <div className="block-body">
                <div style={{height: calculateHeight(treeData)}}>
                    <SortableTree
                        treeData={treeData}
                        onChange={(treeData) => setTreeData(treeData)}
                        canDrag={onDrag}
                        canDrop={canDrop}
                        maxDepth={10000}
                        scaffoldBlockPxWidth={30}
                        getNodeKey={({node}) => node.id}
                        generateNodeProps={generateNodeProps}
                        rowHeight={ROW_HEIGHT}
                    />
                </div>
            </div>
        </div>
    );
};

LocationsAssetTree.propTypes = {
    setTreeData: PropTypes.func,
    treeData: PropTypes.array,
    auth: PropTypes.object,
    autoSort: PropTypes.bool,
    user: PropTypes.object,
    onDeleteLocation: PropTypes.func,
};

export default LocationsAssetTree;
