import React, {useMemo, useState} from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledTooltip} from "reactstrap";
import {get as _get, pull as _pull, isEmpty as _isEmpty} from "lodash";
import {AXES_COLORS, AXES_COLORS_DARK} from "../../../../../../constants/constants";
import {useChartContext} from "../../hooks/chartHooks";
import Toast from "../../../../../shared/toast";
import auth from "../../../../../../services/auth";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {useLockPoints} from "../../hooks/useLockPoints";
import {useChartSelectedEquipmentStore, useSelectedByEquipment} from "../../../../../../stores/zustand/ChartSelectedEquipmentStore";
import {useEquipmentByParams} from "../../../../../../hooks/api/equipment/useEquipmentQuery";
import {useManageAxis} from "../../../../../../modals/manageAxes/hooks/useManageAxis";
import {useIsImpactVue} from "../../../hooks/useChartTypes";
import {shallow} from "zustand/shallow";
import {useAxisLabels} from "../../hooks/helpers/useAxisLabels";

const isDarkTheme = localStorage.getItem("lightMode");

const SeriesMenuMultiple = () => {
    const {equipmentItem} = useEquipmentByParams();
    const [dropdownSeriesOpen, setDropdownSeriesOpen] = useState(false);

    const equipmentSelected = useChartSelectedEquipmentStore();

    const toggleSeriesOpen = () => {
        setDropdownSeriesOpen((prev) => !prev);
    };

    const {chartProps} = useChartContext();
    const lockPoints = useLockPoints();
    const selectedInstallationPointIds = useSelectedByEquipment(equipmentItem.id);

    const {outliersIds} = chartProps;

    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const seriesList = useChartStateStoreContext((state) => state.settings.seriesList, shallow);
    const {changeSelectedSeriesList} = useChartStateStoreContext((state) => state.actions);
    const viewOnly = useMemo(() => auth.equipmentViewOnly(equipmentItem), [equipmentItem]);

    const axisMultipleLabels = useAxisLabels();

    let selPoint = (equipmentSelected[equipmentItem?.id] || []).length === 1 && equipmentSelected[equipmentItem?.id][0];

    const saveSelectedAxis = () => {
        const selected = _get(seriesList, "selected", []);

        const currentSelected = JSON.parse(localStorage.getItem("selectedSeries")) || {};
        localStorage.setItem("selectedSeries", JSON.stringify({...currentSelected, [chartType]: selected}));

        Toast.info("The default settings will be applied to all equipment.");
    };

    const toggleActiveSeries = (axisId) => {
        let selected = [...seriesList.selected];
        if (selected.indexOf(axisId) >= 0) {
            selected = _pull(selected, axisId);
        } else {
            selected.push(axisId);
        }

        if (selected.length) {
            changeActiveSeries(selected);
        }
    };

    const [showManageAxis] = useManageAxis();

    const changeActiveSeries = (selected) => {
        lockPoints.current = [];
        changeSelectedSeriesList(selected);
    };

    const isImpactVueType = useIsImpactVue(chartType);

    if (Object.keys(seriesList.list).length < 2 || isImpactVueType) {
        return <></>;
    }

    const selectedLabels = Object.keys(seriesList.list)
        .filter((key) => seriesList.selected.indexOf(key) >= 0)
        .map((key) => (!_isEmpty(axisMultipleLabels) ? axisMultipleLabels[key] : seriesList.list[key].axisName + " - Axis"));

    return (
        <Dropdown
            size="sm"
            isOpen={dropdownSeriesOpen}
            toggle={toggleSeriesOpen}
        >
            <DropdownToggle
                caret
                className="btn-default"
            >
                {selectedLabels.join(", ")}
            </DropdownToggle>
            <DropdownMenu>
                {Object.keys(seriesList.list).map((key) => (
                    <DropdownItem
                        className={"axis-dropdown-item"}
                        key={key}
                        toggle={false}
                    >
                        {selPoint ? (
                            <div>
                                <i
                                    id={`iconm-${seriesList.list[key].axisName}`}
                                    className={`fa fa-eye${seriesList.selected.indexOf(key) >= 0 ? "" : "-slash"}`}
                                    onClick={() => toggleActiveSeries(key)}
                                />
                                <UncontrolledTooltip
                                    placement="top"
                                    target={`iconm-${seriesList.list[key].axisName}`}
                                >
                                    {"Click to  overlay"}
                                </UncontrolledTooltip>
                            </div>
                        ) : (
                            <div>
                                <i
                                    id={`icon-${seriesList.list[key].axisName}`}
                                    className={`disabled-eye fa fa-eye${seriesList.selected.indexOf(key) >= 0 ? "" : "-slash"}`}
                                />
                                <UncontrolledTooltip
                                    placement="top"
                                    target={`icon-${seriesList.list[key].axisName}`}
                                >
                                    {"You cannot select multiple axes when multiple installation points are selected"}
                                </UncontrolledTooltip>
                            </div>
                        )}

                        <span
                            id={`labelm-${seriesList.list[key].axisName}`}
                            className={"label badge axis-select-label"}
                            style={{
                                color: "#fff",
                                backgroundColor: isDarkTheme === "true" ? AXES_COLORS_DARK[key] : AXES_COLORS[key],
                            }}
                            onClick={() => changeActiveSeries([key])}
                        >
                            {!_isEmpty(axisMultipleLabels) ? axisMultipleLabels[key] : seriesList.list[key].axisName + " - Axis"}
                            <UncontrolledTooltip
                                placement="top"
                                target={`labelm-${seriesList.list[key].axisName}`}
                            >
                                Click to change the overlay view
                            </UncontrolledTooltip>
                        </span>

                        {!!outliersIds &&
                            Object.keys(outliersIds)
                                .map((axisId) => +axisId)
                                .indexOf(+key) >= 0 && (
                                <span
                                    className={"label badge"}
                                    style={{
                                        color: "#fff",
                                        backgroundColor: "orange",
                                        marginLeft: "10px",
                                    }}
                                >
                                    outlier
                                </span>
                            )}
                    </DropdownItem>
                ))}
                {!!saveSelectedAxis && (
                    <DropdownItem className={"d-flex"}>
                        <span
                            title="Save the axes view for all equipment."
                            className="btn btn-sm btn-primary ml-auto mr-auto"
                            onClick={saveSelectedAxis}
                        >
                            Save as Default
                        </span>
                    </DropdownItem>
                )}
                {!viewOnly && (
                    <React.Fragment>
                        <DropdownItem divider />
                        <DropdownItem onClick={() => showManageAxis(selectedInstallationPointIds)}>Manage...</DropdownItem>
                    </React.Fragment>
                )}
            </DropdownMenu>
        </Dropdown>
    );
};

export default SeriesMenuMultiple;
