import {useQuery} from "@tanstack/react-query";
import ApiEquipment from "../../../api/equipment";
import {API_CONSTANTS} from "../../../api/constants/constants";

const useEquipmentTypes = () => {
    const {isLoading, isSuccess, error, data, isRefetching} = useQuery([API_CONSTANTS.GET_EQUIPMENT_TYPES], () => ApiEquipment.getTypes(), {
        select: (response) => response.list,
    });

    return {
        isLoading,
        isSuccess,
        isRefetching,
        error,
        data,
    };
};

export default useEquipmentTypes;
