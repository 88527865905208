import $ from "jquery";
import auth from "../services/auth";
import swal from "../pages/shared/swal";

const HOST_NAME_AWS = new URL(process.env.AWS_HOST_URL).hostname;
const HOST_NAME_AZURE = new URL(process.env.AZURE_HOST_URL).hostname;
const hostname = window.location.hostname;

export const IS_HOST_AWS = hostname === HOST_NAME_AWS;
export const IS_HOST_AZURE = hostname === HOST_NAME_AZURE;

export default {
    getUrl(options) {
        let opt = options || {};

        const host = opt.host || process.env.API_URL;
        const query = opt.query || {};
        const module = opt.module || "api";
        const params = $.param(query);

        return Object.assign({}, this.options, opt, {
            toString() {
                return `${host}/${module}/${this.url}${params.length > 0 ? "?" + params : ""}`;
            },
        });
    },

    getHeaders() {
        const headers = {"Content-Type": "application/json", Accept: "application/json"};

        if (auth.loggedIn()) {
            Object.assign(headers, {Authorization: `Bearer ${auth.getToken()}`});
        }

        return headers;
    },

    get(url, options = {}) {
        const host = options.host || process.env.API_URL;
        const query = options.query || {};
        const module = options.module || "api";
        const endpoint = this.getUrl({host: host, url: url, query: query, module: module});

        return this.fetch(
            endpoint,
            Object.assign(
                {},
                {
                    method: "GET",
                    headers: this.getHeaders(),
                    credentials: "include",
                },
                options
            )
        );
    },

    performBodyRequest(method, url, body, options) {
        const host = options.host || process.env.API_URL;
        const query = options.query || {};
        const module = options.module || "api";
        const endpoint = this.getUrl({host: host, url: url, query: query, module: module});

        return this.fetch(
            endpoint,
            Object.assign(
                {},
                {
                    method: method,
                    headers: this.getHeaders(),
                    credentials: "include",
                },
                options,
                {body: JSON.stringify(body)}
            )
        );
    },

    postFormData(url, body, options = {}) {
        return this.performBodyFormDataRequest("POST", url, body, options);
    },

    performBodyFormDataRequest(method, url, body, options) {
        const host = options.host || process.env.API_URL;
        const query = options.query || {};
        const endpoint = this.getUrl({host: host, url: url, query: query});

        const headers = {};

        if (auth.loggedIn()) {
            Object.assign(headers, {Authorization: `Bearer ${auth.getToken()}`});
        }

        return this.fetch(
            endpoint,
            Object.assign(
                {},
                {
                    method: method,
                    headers: headers,
                    credentials: "include",
                },
                options,
                {body: body}
            )
        );
    },

    post(url, body, options = {}) {
        return this.performBodyRequest("POST", url, body, options);
    },

    patch(url, body, options = {}) {
        return this.performBodyRequest("PATCH", url, body, options);
    },

    put(url, body, options = {}) {
        return this.performBodyRequest("PUT", url, body, options);
    },

    delete(url, body, options = {}) {
        return this.performBodyRequest("DELETE", url, body, options);
    },

    fetch(endpoint, options) {
        return fetch(endpoint, {...options, ...{withCredentials: true}})
            .then((resp) => {
                if (resp.ok) {
                    return resp;
                }

                if (resp.status === 401) {
                    const currentUrl = encodeURIComponent(window.location.href);
                    window.location.href = `${process.env.SSO_URL}/login?redirect=${currentUrl}`;
                }

                return resp.json().then((err) => {
                    err.status = resp.status;
                    throw err;
                });
            })
            .then((resp) => {
                if (options.parseAsText) {
                    return resp.text().catch(() => ({}));
                }
                return resp.json().catch(() => ({}));
            })
            .catch((err) => {
                if (err.status !== 422) {
                    if (err.status === 404) {
                        window.location.href = "/404";
                    } else if (["TypeError", "AbortError"].includes(err.name)) {
                        return;
                    } else if (err.message) {
                        swal("", err.message, "error", 15000);
                    } else if (err.name) {
                        swal("", err.name, "error", 15000);
                    } else {
                        swal("", err.toString(), "error", 15000);
                    }
                }

                if (err?.response?.status === 422) {
                    const message = JSON.parse(err.response?.data?.message);

                    if (message?.errors?.message === "Incorrect Domain") {
                        const pathname = window.location.pathname;

                        const redirectUrl = (IS_HOST_AWS ? process.env.AZURE_HOST_URL : process.env.AWS_HOST_URL) + pathname;

                        window.location.replace(redirectUrl);
                    }
                }
                if (err.reloadPage) {
                    window.location.reload();
                }

                throw err;
            });
    },

    source(url, options = {}) {
        const host = options.host || process.env.API_URL;
        const query = options.query || {};
        const endpoint = this.getUrl({host: host, url: url, query: query});

        options = Object.assign(
            {},
            {
                method: "GET",
                headers: this.getHeaders(),
                credentials: "include",
                withCredentials: true,
            },
            options
        );

        return fetch(endpoint, options)
            .then((resp) => {
                if (resp.ok) {
                    return resp;
                }

                if (resp.status === 401) {
                    const currentUrl = encodeURIComponent(window.location.href);
                    window.location.href = `${process.env.SSO_URL}/login?redirect=${currentUrl}`;
                }

                return resp;
            })
            .then((resp) => resp)
            .catch((err) => {
                if (err.status !== 422) {
                    if (err.status === 404) {
                        window.location.href = "/404";
                    } else if (err.name === "AbortError") {
                        return;
                    } else if (err.message) {
                        swal("", err.message, "error", 15000);
                    } else if (err.name) {
                        swal("", err.name, "error", 15000);
                    } else {
                        swal("", err.toString(), "error", 15000);
                    }
                }

                if (err.reloadPage) {
                    window.location.reload();
                }

                throw err;
            });
    },
};
