import React, {useState} from "react";
import Helper from "../../../../helpers/helper";
import PropTypes from "prop-types";
import {toggleExpandedForAll} from "react-sortable-tree";
import styles from "./style.module.scss";
import {toggleLocalStorageItem} from "../../services/toggleLocalStorageItem";
import {useHistory} from "react-router";

const LocationsHeader = ({treeData, auth, setTreeData, refreshLocation, handleAutoSort}) => {
    const sort = localStorage.getItem("auto-sort-asset-tree");
    const [btnAction, setBtnAction] = useState(false);
    const history = useHistory();

    const toggleAutoSort = () => {
        toggleLocalStorageItem();
        refreshLocation();
        handleAutoSort();
    };

    const toggleExpand = (expanded) => {
        const newTreeData = toggleExpandedForAll({
            treeData,
            expanded,
        });
        setBtnAction((prev) => !prev);
        setTreeData(newTreeData);
    };

    return (
        <div className={styles.subheader}>
            <div className="subheader-title">Asset Tree</div>
            <div className="subheader-controls">
                <div className="subheader-toolbar">
                    <button
                        type="button"
                        className={"btn btn-sm ml-2 " + (sort === "1" ? "btn-success" : "btn-danger")}
                        onClick={toggleAutoSort}
                    >
                        <span>Auto sorting: {sort === "1" ? "On" : "Off"}</span>
                    </button>
                    <button
                        type="button"
                        className="btn btn-sm btn-primary ml-2"
                        onClick={() => toggleExpand(btnAction)}
                    >
                        {!btnAction ? <i className="fa fa-angle-double-up" /> : <i className="fa fa-angle-double-down" />}
                        <span>{!btnAction ? "Collapse all" : "Expand all"}</span>
                    </button>
                    {auth.userCan("editAssetsTree") && (
                        <button
                            type="button"
                            className="btn btn-sm btn-success ml-2"
                            onClick={() => history.push(Helper.setHashParams({modal: "add"}))}
                        >
                            <i className="fa fa-plus" /> <span>Add Branch</span>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

LocationsHeader.propTypes = {
    treeData: PropTypes.array,
    auth: PropTypes.object,
    setTreeData: PropTypes.func,
    refreshLocation: PropTypes.func,
    handleAutoSort: PropTypes.func,
};

export default LocationsHeader;
