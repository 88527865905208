import {useModal} from "react-modal-hook";
import RequestOnDemandModal from "../components/requestOnDemandModal";
import React, {useRef} from "react";
import {useHistory, useParams} from "react-router";
import useRequestOnDemandPending from "../../../hooks/api/Global/useRequestOnDemandPending";
import {useModalHistory} from "../../../hooks/helpers/useModalHistory";
import {MODALS} from "../../../constants/modals";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";
import {useInstallationPointsListQuery} from "../../../hooks/api/installationPoints/useInstallationPointsListQuery";

export const useRequestOnDemandModal = () => {
    const params = useParams();
    const pendingOnDemandsRef = useRef([]);
    const {addPendingOnDemand} = useRequestOnDemandPending(pendingOnDemandsRef);
    const history = useHistory();
    const {equipmentItem} = useEquipmentByParams();
    const {triggers, isLoading} = useInstallationPointsListQuery(equipmentItem.id);
    const [showModal, hideModal] = useModal(
        () => (
            <RequestOnDemandModal
                equipmentId={+params.equipmentId}
                equipment={equipmentItem}
                title={"Request On Demand"}
                showModal={true}
                onClose={onHide}
                addPendingOnDemand={addPendingOnDemand}
                history={history}
                triggers={triggers}
                isTriggersLoading={isLoading}
            />
        ),
        [equipmentItem, triggers, isLoading]
    );

    const {pushHistory, deleteHistory} = useModalHistory(MODALS.REQUEST_ON_DEMAND, showModal);

    const onShow = () => {
        pushHistory();
        showModal();
    };

    const onHide = () => {
        deleteHistory();
        hideModal();
    };

    return [onShow, onHide];
};
