import React, {useEffect, useReducer} from "react";
import PropTypes from "prop-types";
import {get as _get, find as _find, dropRight as _dropRight, includes as _includes, isUndefined} from "lodash";
import SortableTree from "react-sortable-tree";
import ApiLocation from "../../api/location";
import LocationModal from "../../modals/location/location";
import SweetAlert from "react-bootstrap-sweetalert";
import {withGlobalStore} from "../../stores/GlobalStore";
import {withLocationSelectStore} from "../../stores/LocationSelectStore";

import "./aside.scss";
import {ACTION, reducer} from "./reducer/asideReducer";
import cookies from "react-cookies";

const ROW_HEIGHT = 45;

const initialState = {
    treeData: [],
    draggedNode: null,
    deleteLocationId: null,
    filter: {
        query: "",
    },
    searchParams: {},
    autoSort: !isUndefined(cookies.load("auto-sort-asset-tree")),
};

const Aside = (props) => {
    const {updateUser, auth, locationAside, locationAsideToggle, user, refreshLocation, locationList} = props;

    const [state, dispatch] = useReducer(reducer, initialState);
    const {treeData, deleteLocationId, searchParams, draggedNode, autoSort} = state;

    const setSearchParams = (data) => {
        dispatch({type: ACTION.SET_SEARCH_PARAMS, payload: data});
    };

    const handleFetch = () => {
        ApiLocation.list().then((data) => {
            let treeData = _get(data.list, "0.children") || [];
            dispatch({
                type: ACTION.SET_TREE_DATA,
                payload: treeData,
            });
        });
    };

    useEffect(() => {
        handleFetch();
    }, []);

    const onDragTreeData = (drag) => {
        if (drag.isDragging && drag.draggedNode) {
            dispatch({type: ACTION.SET_DRAGGED_NODE, payload: drag.draggedNode});
        } else {
            const draggedNodeId = _get(draggedNode, "id");

            if (draggedNodeId) {
                ApiLocation.updateTree({newTree: treeData}).then(() => {
                    refreshLocation();
                    dispatch({type: ACTION.SET_DRAGGED_NODE, payload: null});
                });
            }
        }
    };

    const hasAssetTreeBranchName = (node, nextParent) => {
        const {name, id} = node;
        const nextParentChildren = _get(nextParent, "children");

        if (!nextParentChildren) {
            return false;
        }

        return _find(nextParentChildren, function (child) {
            if (name === _get(child, "name") && _get(child, "id") !== id) {
                return true;
            }
        });
    };

    const onDeleteLocation = (deleteLocationId) => {
        dispatch({type: ACTION.DELETE_LOCATION_ID, payload: deleteLocationId});
    };

    const deleteLocation = () => {
        ApiLocation.delete(deleteLocationId).then(() => {
            onDeleteLocation(null);
            refreshLocation();
        });
    };

    const onChangeTree = (treeData) => {
        dispatch({type: ACTION.SET_TREE_DATA, payload: treeData});
    };

    return (
        <div className={"location-aside" + (locationAside ? "" : " d-none")}>
            <span
                className="edit-equipment-button edit-equipment-button-close"
                onClick={locationAsideToggle}
            >
                <i className="fas fa-times-circle" />
            </span>
            <div className="title">Modify Asset Tree</div>
            <div className="add-asset-container">
                <button
                    onClick={() => setSearchParams({modal: "add"})}
                    className="btn btn-sm btn-primary"
                >
                    <i className="fa fa-plus" /> Add Asset
                </button>
            </div>
            <SortableTree
                className="location-tree custom-scroll-tree"
                treeData={locationList}
                onChange={(locationList) => onChangeTree(locationList)}
                onDragStateChanged={onDragTreeData}
                canDrag={({node}) => {
                    const facilityLocation = _find(locationList, {facility_id: node.facility_id});
                    const facilityLocationId = _get(facilityLocation, "id");

                    if (auth.userCan("editAssetsTree") && !autoSort) {
                        return +node.is_lock === 0 && (user.all_facilities || _includes(user.allowedLocationIds, facilityLocationId));
                    }

                    return false;
                }}
                canDrop={({prevPath, nextPath, node, nextParent}) => {
                    if (auth.userCan("editAssetsTree")) {
                        const hasAssetTreeName = hasAssetTreeBranchName(node, nextParent);

                        if (hasAssetTreeName) {
                            return !hasAssetTreeName;
                        }

                        return (!autoSort || nextPath.length > 1) && prevPath[0] === nextPath[0];
                    }

                    return false;
                }}
                scaffoldBlockPxWidth={30}
                style={{height: "calc(100vh - 180px)"}}
                getNodeKey={({node}) => node.id}
                generateNodeProps={({node}) => {
                    if (node.name === "ADD_NODE_BTN") {
                        return {
                            nodecontentrenderer: "",
                            title: auth.userCan("editAssetsTree") && (
                                <span
                                    key={`add-${node.id}`}
                                    className="edit-equipment-button"
                                    onClick={() =>
                                        setSearchParams({
                                            modal: "add",
                                        })
                                    }
                                >
                                    <i className="fas fa-plus-circle" />
                                </span>
                            ),
                            canDrag: false,
                            className: "k-sortable-row-add",
                        };
                    } else {
                        return {
                            title: node.name,
                            className: "k-sortable-row",
                            buttons:
                                auth.userCan("editAssetsTree") &&
                                +node.is_lock === 0 &&
                                (user.all_facilities || _includes(user.allowedLocationIds, node.id))
                                    ? [
                                          <span
                                              key={`edit-${node.id}`}
                                              className="edit-equipment-button"
                                              onClick={() =>
                                                  setSearchParams({
                                                      modal: "edit",
                                                      id: node.id,
                                                  })
                                              }
                                          >
                                              <i className="fas fa-pencil-alt" />
                                          </span>,
                                      ]
                                    : [],
                        };
                    }
                }}
                rowHeight={ROW_HEIGHT}
            />
            {(searchParams.modal || !!searchParams.id) && (
                <LocationModal
                    variant={searchParams.modal}
                    locationId={+searchParams.id}
                    list={_dropRight(locationList)}
                    onClose={() => setSearchParams({})}
                    refreshLocation={refreshLocation}
                    updateUser={updateUser}
                    handleSubmit={() => setSearchParams({})}
                    user={user}
                />
            )}
            {deleteLocationId && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnBsStyle="default"
                    btnSize="xs"
                    title="DELETE ASSET TREE BRANCH"
                    onConfirm={deleteLocation}
                    onCancel={() => onDeleteLocation(null)}
                >
                    Are you sure you want to delete this asset tree branch?
                </SweetAlert>
            )}
        </div>
    );
};

Aside.propTypes = {
    auth: PropTypes.object,
    locationAside: PropTypes.bool,
    locationAsideToggle: PropTypes.func,
    user: PropTypes.object,
    updateUser: PropTypes.func,

    //LocationStore
    locationList: PropTypes.array,
    refreshLocation: PropTypes.func,
};

export default withLocationSelectStore(withGlobalStore(Aside));
