import React, {useState} from "react";
import PropTypes from "prop-types";
import ReadingIntervalForm from "./ReadingIntervalForm";
import FftSettingsForm from "./FftSettingsForm";
import {get as _get, includes as _includes} from "lodash";

import {TabContent, TabPane, Nav, NavItem, NavLink, UncontrolledTooltip} from "reactstrap";
import classnames from "classnames";
import {withGlobalStore} from "../../../stores/GlobalStore";
import {WakeOnShakeForm} from "../../../modals/trigger-settings/components/wake-on-shake";
import {BooleanForm} from "../../../modals/trigger-settings/components/generic";
import HighPassFilterInput from "./HighPassFilterInput";
import ImpactVueText from "../../../shared/impactVue/ImpactVueText";
import {compare} from "compare-versions";

const SettingsForm = ({
    settings,
    installationPoint,
    triggers,
    toggleFlag,
    onTriggersFormChange,
    specs,
    onChange,
    showNotice,
    formErrors,
    versionType,
    nodeSerials,
    validation,
    sensorParameters,
    isImpactVueEnabled,
}) => {
    const fftSettings = _get(settings, "fft_settings", {});
    const booleanSupport = _includes(_get(triggers, "restriction.generic_sensor_types", []), +_get(installationPoint, "sensor.version_type", false));

    let wosSupport = _includes(_get(triggers, "restriction.wos_sensor_types", []), +_get(installationPoint, "sensor.version_type", false));
    if (wosSupport) {
        const version = _get(installationPoint, "sensor.version", "0.0.0");
        const wosSupportVersion = _get(triggers, "restriction.wos_sensor_version", "0.0.0");
        wosSupport = compare(version, wosSupportVersion, ">=");
    }

    const [activeTab, setActiveTab] = useState("1");

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const isAdapter = _get(installationPoint, "point_type") === "adapter";

    return (
        <div className="point-settings-form-container mt-2">
            <div className={"row"}>
                <div className={"col-md-6"}>
                    {!booleanSupport && (
                        <ReadingIntervalForm
                            settings={settings}
                            onChange={onChange}
                            showNotice={showNotice}
                            formErrors={formErrors}
                            validation={validation}
                            nodeSerials={nodeSerials}
                            isAdapter={isAdapter}
                            isImpactVueEnabled={isImpactVueEnabled}
                        />
                    )}
                    {wosSupport && (
                        <WakeOnShakeForm
                            triggers={triggers}
                            toggleFlag={toggleFlag}
                            onFormChange={onTriggersFormChange}
                            isImpactVueEnabled={isImpactVueEnabled}
                        />
                    )}
                    {booleanSupport && (
                        <BooleanForm
                            triggers={triggers}
                            toggleFlag={toggleFlag}
                            onFormChange={onTriggersFormChange}
                        />
                    )}
                </div>
                <div className={"col-md-1"} />
                {isAdapter || booleanSupport ? (
                    <div className={"col-md-5"} />
                ) : (
                    <div className={"col-md-5"}>
                        <label>FFT/RMS Settings</label>

                        <Nav
                            tabs
                            className={"nav-intervals"}
                        >
                            <NavItem>
                                <NavLink
                                    className={classnames({active: activeTab === "1"})}
                                    onClick={() => {
                                        toggleTab("1");
                                    }}
                                >
                                    FFT/RMS
                                </NavLink>
                            </NavItem>

                            <NavItem>
                                <NavLink
                                    className={classnames({active: activeTab === "2"})}
                                    onClick={() => {
                                        toggleTab("2");
                                    }}
                                >
                                    RMS
                                </NavLink>
                            </NavItem>

                            {!!(_get(sensorParameters, "supportHfdvue") && isImpactVueEnabled) && (
                                <NavItem>
                                    <NavLink
                                        className={classnames({active: activeTab === "3"})}
                                        onClick={() => {
                                            toggleTab("3");
                                        }}
                                    >
                                        <ImpactVueText type={"fft"} />
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                {Object.keys(fftSettings).map((key) => (
                                    <FftSettingsForm
                                        key={key}
                                        specs={specs}
                                        settings={fftSettings[key]}
                                        onChange={onChange}
                                        version_type={versionType}
                                        formErrors={formErrors}
                                        sensorParameters={sensorParameters}
                                    />
                                ))}
                            </TabPane>

                            <TabPane tabId="2">
                                <div
                                    className="form-group"
                                    style={{marginBottom: 20}}
                                >
                                    <div className="row mb-3">
                                        <label className="col-5 text-right pt-2">
                                            {!_get(installationPoint, "sensor.is_mote") && (
                                                <>
                                                    <span
                                                        className="mr-2"
                                                        id="high-pass-info"
                                                    >
                                                        <i className="fa fa-info-circle"></i>
                                                    </span>
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target="high-pass-info"
                                                    >
                                                        <p className="tooltip-text">
                                                            When setting up a high-pass filter for a tethered sensor, it will apply to all sensors
                                                            associated with this node
                                                        </p>
                                                    </UncontrolledTooltip>
                                                </>
                                            )}
                                            RMS High-Pass Filter :
                                        </label>
                                        <div className="col-7">
                                            <HighPassFilterInput
                                                name={"high_pass"}
                                                value={+_get(settings, "high_pass")}
                                                onChange={onChange}
                                                errorMsg={_get(formErrors, "high_pass")}
                                                maxValue={+_get(fftSettings, "1.fmax", 1200)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </TabPane>

                            {!!isImpactVueEnabled && (
                                <TabPane tabId="3">
                                    <FftSettingsForm
                                        settings={fftSettings[1]}
                                        onChange={onChange}
                                        version_type={versionType}
                                        formErrors={formErrors}
                                        index={1}
                                        isHfdvue={true}
                                        sensorParameters={sensorParameters}
                                    />
                                </TabPane>
                            )}
                        </TabContent>
                    </div>
                )}
            </div>
        </div>
    );
};

SettingsForm.propTypes = {
    settings: PropTypes.object,
    installationPoint: PropTypes.object,
    triggers: PropTypes.object,
    onChange: PropTypes.func,
    toggleFlag: PropTypes.func,
    onTriggersFormChange: PropTypes.func,
    specs: PropTypes.object,
    showNotice: PropTypes.bool,
    formErrors: PropTypes.object,
    versionType: PropTypes.number,
    withHfdvue: PropTypes.bool,
    nodeSerials: PropTypes.array,
    validation: PropTypes.object,
    sensorParameters: PropTypes.object,
    auth: PropTypes.object,
    isImpactVueEnabled: PropTypes.bool,
};

export default withGlobalStore(SettingsForm);
