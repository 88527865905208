import React, {useEffect, useState} from "react";
import {Modal, ValidationError} from "../../shared";
import FormValidator from "../../helpers/form-validator";
import PropTypes from "prop-types";
import CollapseLocationSelect from "../../shared/collapseLocationSelect/collapseLocationSelect";
import {useAssetCreatMutation} from "../../hooks/api/assets/useAssetCreatMutation";
import {useAssetUpdateMutation} from "../../hooks/api/assets/useAssetUpdateMutation";
import {rules} from "./rules";
import {useHistory} from "react-router";
import {useAssetListQuery} from "../../hooks/api/assets/useAssetListQuery";
import {useAssetGetQuery} from "../../hooks/api/assets/useAssetGetQuery";

const validator = new FormValidator(rules);

const LocationModal = (props) => {
    const [locationList, setLocationList] = useState([]);
    const [validation, setValidation] = useState(validator.valid());
    const history = useHistory();

    const {mutateAsync: locationCreate, isLoading: isLoadingLocationCreate} = useAssetCreatMutation({props, history});
    const {mutateAsync: locationUpdate, isLoading: isLoadingLocationUpdate} = useAssetUpdateMutation({props, history});
    const {data: locationData, isLoading: isLoadingLocationData} = useAssetListQuery({
        onlyWithEquipment: false,
        isFlat: false,
    });
    const {data: locationItem, isLoading: isLoadingLocationItem} = useAssetGetQuery({locationId: props.locationId});

    const [data, setData] = useState({
        name: "",
        parentId: 0,
        label: "",
    });

    useEffect(() => {
        const {locationId, list = []} = props;
        if (locationId && !isLoadingLocationData && !isLoadingLocationItem) {
            setData((prev) => ({
                ...prev,
                ...locationItem,
                parentId: locationItem?.parent ? locationItem?.parent?.id : 0,
            }));
            setLocationList(locationData[0].children);
            return;
        }

        setLocationList(list);
    }, [isLoadingLocationData, isLoadingLocationItem]);

    const handleChange = (e, field) => {
        const updatedData = {...data, [field]: e.target.value};

        if (field === "parentId") {
            updatedData.label = e.target.label;
        }

        setData(updatedData);

        const auxiliaryComponent = {locationList, locationId: props.locationId};
        const validationResult = validator.validate(updatedData, e.target.value, auxiliaryComponent);
        setValidation(validationResult);
    };

    const onSubmitModal = () => {
        const auxiliaryComponent = {locationList, locationId: props.locationId};

        if (data.parentId === "" || +data.parentId === 0) {
            setValidation((prev) => ({
                ...prev,
                label: {
                    isValid: false,
                    message: "This field is required.",
                },
            }));
            return;
        }

        if (!data.name.trim()) {
            setValidation((prev) => ({
                ...prev,
                name: {
                    isValid: false,
                    message: "This field is required.",
                },
            }));
            return;
        }

        const validation = validator.validate(data, null, auxiliaryComponent);
        setValidation(validation);

        if (validation.isValid) {
            props.variant === "add" ? locationCreate(data) : locationUpdate({data, id: props.locationId});
        }
    };

    function getVariantValue(options) {
        return props.variant === "add" ? options.add : options.edit;
    }

    return (
        <Modal
            {...props}
            showModal={true}
            disableSubmit={!validation.isValid}
            onSubmit={onSubmitModal}
            inProgress={getVariantValue({add: isLoadingLocationCreate, edit: isLoadingLocationUpdate})}
            title={getVariantValue({add: "ADD BRANCH", edit: "EDIT BRANCH"})}
            submitTitle="Save"
        >
            <div className="form-group row">
                <label
                    className={"col-3 text-right form-label"}
                    htmlFor=""
                >
                    Parent:
                </label>
                <div className="col-9">
                    <CollapseLocationSelect
                        style={{width: 328, outline: 0}}
                        addClassName={validation.label.isValid || !validation.label.message ? "" : " is-invalid"}
                        emptyOptionLabel={"Select Parent"}
                        onChange={(e) => handleChange(e, "parentId")}
                        value={data.parentId}
                        locationList={locationList}
                        disabled={+data.parentId === 0 && props.locationId}
                    />
                    <ValidationError message={validation.label.message} />
                </div>
            </div>

            <div className="form-group row">
                <label className="col-3 text-right form-label">Name:</label>
                <div className="col-9">
                    <input
                        className={"form-control" + (validation.name.isValid || !validation.name.message ? "" : " is-invalid")}
                        type="text"
                        name="name"
                        value={data.name}
                        onChange={(e) => handleChange(e, "name")}
                        disabled={+data.parentId === 0 || data.parentId === ""}
                    />
                    <ValidationError message={validation.name.message} />
                </div>
            </div>
        </Modal>
    );
};

LocationModal.propTypes = {
    locationId: PropTypes.number,
    onClose: PropTypes.func,
    customStyles: PropTypes.object,
    children: PropTypes.any,
    cancelTitle: PropTypes.string,
    list: PropTypes.array,
    user: PropTypes.object,
    variant: PropTypes.string,
    refreshLocation: PropTypes.func,
    updateUser: PropTypes.func,
    handleSubmit: PropTypes.func,
};

export default LocationModal;
