import {useQuery} from "@tanstack/react-query";
import EquipmentApi from "../../../api/equipment";
import {get as _get, cloneDeep as _cloneDeep} from "lodash";
import {useChartSelectedEquipmentStore, useChartSelectedEquipmentStoreActions} from "../../../stores/zustand/ChartSelectedEquipmentStore";
import {useEquipmentBreadcrumbsStoreActions} from "../../../stores/zustand/EquipmentBreadcrumbsStore";
import {useParams} from "react-router";
import {useEffect} from "react";
import {setColorForInstallationPoints} from "../../../helpers/installationPoints";
import {API_CONSTANTS} from "../../../api/constants/constants";

export const getEquipmentQueryKeys = (equipmentId) => [API_CONSTANTS.GET_EQUIPMENT, +equipmentId];

const REFETCH_INTERVAL = 30000;

export const useEquipmentQuery = ({equipmentId, isRefetch = false}) => {
    const {setSelectedInstallationPoints} = useChartSelectedEquipmentStoreActions();
    const {fetchEquipmentData} = useEquipmentBreadcrumbsStoreActions();
    const selectedEquipment = useChartSelectedEquipmentStore();

    const {isSuccess, data: equipmentItem} = useQuery(getEquipmentQueryKeys(equipmentId), () => EquipmentApi.get(+equipmentId), {
        onSuccess: (data) => {
            fetchEquipmentData(data);
            return data;
        },
        select: (equipment) => {
            const equipmentLocal = _cloneDeep(equipment);
            equipmentLocal.installationPoints = setColorForInstallationPoints(equipmentLocal.installationPoints);
            return equipmentLocal;
        },
        cacheTime: 0,
        enabled: !!equipmentId,
        refetchInterval: isRefetch ? REFETCH_INTERVAL : null,
    });

    useEffect(() => {
        if (isSuccess && equipmentItem) {
            if (equipmentItem.installationPoints.length) {
                const firstPoint = equipmentItem.installationPoints.find((point) => point.point_type !== "tach");

                let equipmentDefaultSelected = localStorage.getItem("equipmentDefaultSelected_" + equipmentItem.id) || "{}";

                if (equipmentDefaultSelected !== null && selectedEquipment[equipmentItem.id] === undefined) {
                    equipmentDefaultSelected = JSON.parse(equipmentDefaultSelected);
                    let defaultPoints = _get(equipmentDefaultSelected, "installationPoints", []);
                    if (defaultPoints.length === 0 && firstPoint) {
                        defaultPoints.push(firstPoint.id);
                    }
                    setSelectedInstallationPoints(defaultPoints, equipmentItem.id);
                } else {
                    //force clear from tachometer points
                    let defaultPoints = _get(selectedEquipment, equipmentItem.id, []).filter((pointID) => {
                        const point = equipmentItem.installationPoints.find((point) => +point.id === +pointID);
                        return _get(point, "point_type", "") !== "tach";
                    });

                    if (defaultPoints.length === 0 && firstPoint) {
                        defaultPoints.push(firstPoint.id);
                    }

                    setSelectedInstallationPoints(defaultPoints, equipmentItem.id);
                }
            }
        }
    }, [isSuccess, equipmentItem]);

    return {
        isSuccess,
        equipmentItem,
    };
};

export const useEquipmentByParams = (enabled = true) => {
    const params = useParams();

    const {
        isSuccess,
        data: equipmentItem,
        isRefetching,
    } = useQuery([API_CONSTANTS.GET_EQUIPMENT, +params.equipmentId], () => EquipmentApi.get(+params.equipmentId), {
        enabled: !!params.equipmentId || enabled,
        select: (equipment) => {
            const equipmentLocal = _cloneDeep(equipment);
            equipmentLocal.installationPoints = setColorForInstallationPoints(equipmentLocal.installationPoints);
            return equipmentLocal;
        },
    });

    return {
        isSuccess,
        isRefetching,
        equipmentItem,
    };
};
