import {useQuery} from "@tanstack/react-query";
import {API_CONSTANTS} from "../../../api/constants/constants";
import SsdApi from "../../../api/ssd";

const searchRepeaterBySerialKey = (filters) => [API_CONSTANTS.SEARCH_REPEATER_BY_SERIAL, filters.query];
const searchRepeaterBySerialFn = (filters) => SsdApi.searchRepeaterGatewayBySerial({query: filters.query});

export const useSearchRepeaterBySerialQuery = (filters) => {
    return useQuery({
        queryFn: () => searchRepeaterBySerialFn(filters),
        queryKey: searchRepeaterBySerialKey(filters),
        enabled: filters.enabled,
        cacheTime: 0,
        select: (res) => {
            return res.data.map((item) => ({
                value: item.serial,
                label: item.label,
                equipmentType: item.equipmentType,
                activeWwAi: item.activeWwAi,
            }));
        },
    });
};
