/* eslint-disable no-unused-vars */
import {create} from "zustand";
import {immer} from "zustand/middleware/immer";
import {shallow} from "zustand/shallow";
import {subscribeWithSelector} from "zustand/middleware";
import {getSyncSelector} from "./helpers/getSyncSelector";
import {useRef} from "react";
import Helper from "../../../../../helpers/helper";
import {MTIME_THREE_MONTHS} from "../../../../../stores/zustand/ChartSelectedRangeStore";
import moment from "moment";
import {getCalendarChunkByMonth} from "../../../../../helpers/getCalendarChunkByMonth";

const DEFAULT_UNIX_FROM = Helper.findCommence(MTIME_THREE_MONTHS);
const DEFAULT_UNIX_TO = moment().utc().unix();
export const DEFAULT_DATEPICKER_RANGE = {unixFrom: moment().utc().startOf("month").subtract(2, "month").unix(), unixTo: moment().unix()};

export const useTimestampsSyncStore = create(
    immer(
        subscribeWithSelector((set, get) => ({
            timestamps: {},
            unixDateRange: {},
            datepickerUnixRange: {},
            actions: {
                setTimestampsForFft(installationPointId, {date = "last", time = "last", readingId, checkChartDateRange = false}, isImpactVue = false) {
                    const installPointSelector = getSyncSelector(installationPointId, isImpactVue);
                    set((state) => {
                        state.timestamps[installPointSelector] = {date, time, readingId};

                        if (checkChartDateRange) {
                            const {unixFrom = DEFAULT_UNIX_FROM, unixTo = DEFAULT_UNIX_TO} = state?.unixDateRange?.[installPointSelector] || {};

                            const updated = getCalendarChunkByMonth({
                                date,
                                unixFrom,
                                unixTo,
                            });
                            state.unixDateRange[installPointSelector] = {unixFrom: updated.unixFrom, unixTo: updated.unixTo};
                            state.datepickerUnixRange[installPointSelector] = {unixFrom: updated.unixFrom, unixTo: updated.unixTo};
                        }
                    });
                },
                setUnixDateRange: ({installationPointId, isImpactVue = false, unixFrom, unixTo}) =>
                    set((state) => {
                        const installPointSelector = getSyncSelector(installationPointId, isImpactVue);
                        state.unixDateRange[installPointSelector] = {unixFrom, unixTo};
                    }),
                setDatepickerUnixRange: ({installationPointId, isImpactVue = false, unixFrom, unixTo}) =>
                    set((state) => {
                        const installPointSelector = getSyncSelector(installationPointId, isImpactVue);
                        state.datepickerUnixRange[installPointSelector] = {unixFrom, unixTo};
                    }),
            },
        }))
    )
);

export const useFftSyncedTimestamp = (installationPointId, isImpactVue = false) => {
    const installPointSelector = getSyncSelector(installationPointId, isImpactVue);
    const defaultTimestamps = useRef({date: "last", time: "last"});

    return useTimestampsSyncStore((state) => state.timestamps[installPointSelector], shallow) || defaultTimestamps.current;
};

export const useSelectUnixDateRangeTimestampSync = (installationPointId, isImpactVue = false) => {
    const installPointSelector = getSyncSelector(installationPointId, isImpactVue);

    return useTimestampsSyncStore((state) => state.unixDateRange[installPointSelector], shallow) || DEFAULT_DATEPICKER_RANGE;
};

export const useSelectDatepickerUnixRangeTimestampSync = (installationPointId, isImpactVue = false) => {
    const installPointSelector = getSyncSelector(installationPointId, isImpactVue);

    return useTimestampsSyncStore((state) => state.datepickerUnixRange[installPointSelector], shallow) || DEFAULT_DATEPICKER_RANGE;
};

export const useTimestampsSyncStoreActions = () => useTimestampsSyncStore((state) => state.actions, shallow);
