import {useContext} from "react";
import {ChartStateStore} from "../../context/context";
import {useStore} from "zustand";

export const useChartStateStoreContext = (selector, equalityFn) => {
    const store = useContext(ChartStateStore);
    if (!store) {
        throw new Error("Missing chart state context");
    }
    return useStore(store, selector, equalityFn);
};
export const useChartActions = () => useChartStateStoreContext((state) => state.actions);

export const useSelectChartZoomRange = () => useChartStateStoreContext((state) => state.zoom);
export const useSelectChartType = () => useChartStateStoreContext((state) => state.settings.chartType);
export const useSelectChartSeriesList = () => useChartStateStoreContext((state) => state.settings.seriesList);
export const useSelectChartFullScreenMode = () => useChartStateStoreContext((state) => state.settings.fullScreenMode);
