export const rules = [
    {
        field: "hardwareType",
        method: (value) => value !== "",
        validWhen: true,
        message: "This field is required.",
    },
    {
        field: "urgency",
        method: (value) => value !== "",
        validWhen: true,
        message: "This field is required.",
    },
    {
        field: "serial",
        method: (value) => value !== "",
        validWhen: true,
        message: "This field is required.",
    },
    {
        field: "description",
        method: (value) => {
            const strippedValue = value.replace(/<[^>]*>/g, "").trim();
            return strippedValue !== "";
        },
        validWhen: true,
        message: "This field is required.",
    },
];
