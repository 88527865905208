import React, {useEffect, useState} from "react";
import FormValidator from "../../../helpers/form-validator";
import {ValidationError} from "../../../shared";
import PropTypes from "prop-types";
import Loader from "../../../shared/loader/loader";
import get from "lodash/get";
import Helper from "../../../helpers/helper";
import {withGlobalStore} from "../../../stores/GlobalStore";
import {withRouter} from "react-router";
import {EQUIPMENT_SERVICE, IDLE_THRESHOLD_TYPES} from "../../../constants/constants";
import EquipmentTypeSelector from "../../../shared/form/EquipmentTypeSelector";
import {Button, ModalFooter} from "reactstrap";
import CollapseLocationSelect from "../../../shared/collapseLocationSelect/collapseLocationSelect";
import SelectWrapper from "../../../helpers/select-wrapper";
import InfoTooltip from "../../../shared/infoTooltip/infoTooltip";
import {CheckboxFormGroup} from "../../../shared/formParts/formParts";
import SweetAlert from "react-bootstrap-sweetalert";
import {MANAGE_FULL_SERVICE_EQUIPMENTS_PERMISSION, MANAGE_SELF_SERVICE_EQUIPMENT_PERMISSION} from "../../../constants/permissions";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";
import {useEquipmentUpdateMutation} from "../../../hooks/api/equipment/useEquipmentUpdateMutation";
import {useDashboardListQuery} from "../../../hooks/api/dashboard/useDashboardListQuery";
import {rules} from "./rules";
import {addValidationRules} from "./helpers/addValidationRules";

const propTypes = {
    equipment: PropTypes.object,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    showModal: PropTypes.bool,
    customStyles: PropTypes.object,
    children: PropTypes.any,
    title: PropTypes.string,
    submitTitle: PropTypes.string,
    cancelTitle: PropTypes.string,
    updateImages: PropTypes.func,
    handleUpdateImages: PropTypes.func,
    match: PropTypes.object,
    history: PropTypes.object,
    invalidateEquipment: PropTypes.func,
    handleEditEquipmentPhoto: PropTypes.func,
    auth: PropTypes.object,
    chartTypes: PropTypes.object,
    cancelEdit: PropTypes.func,
    onDisabledSubmit: PropTypes.func,
    user: PropTypes.object,
};

const Edit = (props) => {
    const showServiceInput = () =>
        props.auth.isHybrid &&
        props.auth.userCan(MANAGE_FULL_SERVICE_EQUIPMENTS_PERMISSION) &&
        props.auth.userCan(MANAGE_SELF_SERVICE_EQUIPMENT_PERMISSION);
    const hasImpactVue = props.equipment?.installationPoints?.some((item) => !!item?.sensor?.supportHfdvue && !!item?.sensor?.is_hfdvue);

    const [data, setData] = useState({
        location_id: "",
        name: "",
        asset_code: "",
        equipment_type_id: 1,
        amps: "",
        location: "",
        manufacturer: "",
        images: {},
        main_image: 0,
        service: null,
        idle_threshold: null,
        idle_threshold_type: null,
        impact_idle_threshold: null,
        impact_idle_threshold_type: IDLE_THRESHOLD_TYPES.ACC,
        is_route_collector: 0,
        hasActiveRegularAI: false,
    });
    const validator = new FormValidator(
        addValidationRules({rules, data, hasImpactVue, showServiceInput: showServiceInput(), hasRouteCollectorAccess: props.auth.hasRouteCollectorAccess})
    );
    const [validation, setValidation] = useState(() => validator.valid());
    const [showConfirmAlert, setShowConfirmAlert] = useState(false);
    const [confirmAlertMessage, setConfirmAlertMessage] = useState("");

    const {equipmentItem, isSuccess} = useEquipmentByParams();
    const {mutateAsync: equipmentUpdate, isLoading: isLoadingEquipmentUpdate} = useEquipmentUpdateMutation(() => props.cancelEdit());
    const {data: dashboardList, isLoading} = useDashboardListQuery();

    useEffect(() => {
        isSuccess &&
            setData((prev) => ({
                ...prev,
                ...equipmentItem,
                equipment_type_id: equipmentItem?.type?.id || "",
            }));
    }, []);

    const onChange = (e) => {
        const newData = {...data, [e.target.name]: e.target.value};

        if (newData.service !== EQUIPMENT_SERVICE.SELF) {
            newData.is_route_collector = 0;
        }

        const newValidation = validator.validate({...newData, dashboardList: dashboardList?.list?.working?.equipments}, get(e, "target.name", ""));
        setData(newData);
        setValidation(newValidation);
    };

    const handleSubmit = () => {
        const newValidation = validator.validate({...data, dashboardList: dashboardList?.list?.working?.equipments});
        setValidation(newValidation);

        equipmentUpdate({id: props.match.params.equipmentId, data}).then((response) => {
            if (response.errors) {
                const updatedValidation = {...validation};
                Object.entries(response.errors).forEach(([key, message]) => {
                    updatedValidation[key] = {isValid: false, message};
                });
                setValidation(updatedValidation);
            }
        });
    };

    const isDisabledServiceType = (readOnly) => {
        return readOnly || isLoadingEquipmentUpdate || (data.service === "full" && data.hasActiveRegularAI) || data.is_route_collector;
    };

    const blockingMessageServiceType = () => {
        if (data.hasActiveRegularAI) {
            return "Active regular action item exists.";
        }

        if (data.is_route_collector) {
            return "This equipment monitored by route collector.";
        }

        return "";
    };

    const handleSave = () => {
        const useInRoute = !!data.useInRoute;

        if (+data.is_route_collector === 0 && useInRoute) {
            setShowConfirmAlert(true);
            setConfirmAlertMessage(
                "This equipment has been added to a route. Changing the equipment type will result in its exclusion from the route. Are you sure you want to proceed with this change?"
            );
            return;
        }

        handleSubmit();
    };

    const readOnly = props.auth.equipmentViewOnly(data);
    const hasAssociativeSensors = data.installationPoints?.some((item) => !!item?.sensor?.length || !!item?.sensor_id);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div className={"row"}>
                    <div className="form-group col-md-6">
                        <label>Asset Tree Branch:</label>
                        <div>
                            <CollapseLocationSelect
                                className={"form-control"}
                                addClassName={validation.location_id.isValid || !validation.location_id.message ? "" : " is-invalid"}
                                selectName={"location_id"}
                                value={+data.location_id || null}
                                onChange={onChange}
                                emptyOptionLabel={"Select Location"}
                                allowedLocationIds={props.user.allowedLocationIds || []}
                                disabled={isLoadingEquipmentUpdate || readOnly}
                                style={{width: "auto"}}
                            />
                            <ValidationError message={validation.location_id.message} />
                        </div>
                    </div>

                    {showServiceInput() && (
                        <div className="form-group col-md-6">
                            <label>Service Type:</label>
                            <SelectWrapper
                                className={validation.service.isValid || !validation.service.message ? "" : " is-invalid"}
                                style={{
                                    width: "100%",
                                    display: "block",
                                }}
                                name="service"
                                defaultValue={data.service}
                                onChange={onChange}
                                disabled={isDisabledServiceType(readOnly)}
                            >
                                {Object.values(EQUIPMENT_SERVICE).map((service) => (
                                    <option
                                        key={service}
                                        value={service}
                                    >
                                        {Helper.capitalize(service)}
                                    </option>
                                ))}
                            </SelectWrapper>
                            <div className="form-control-notice">{blockingMessageServiceType()}</div>
                            <ValidationError message={validation.service.message} />
                        </div>
                    )}
                    <div className="form-group col-md-6">
                        <label>Name:</label>
                        <div>
                            <input
                                className={"form-control" + (validation.name.isValid || !validation.name.message ? "" : " is-invalid")}
                                type="text"
                                name="name"
                                defaultValue={data.name}
                                onChange={onChange}
                                disabled={isLoadingEquipmentUpdate || readOnly}
                            />
                            <ValidationError message={validation.name.message} />
                        </div>
                    </div>
                    <div className="form-group col-md-6">
                        <label>Asset Сode:</label>
                        <div>
                            <input
                                className="form-control"
                                type="text"
                                name="asset_code"
                                defaultValue={data.asset_code}
                                onChange={onChange}
                                disabled={isLoadingEquipmentUpdate || readOnly}
                            />
                        </div>
                    </div>
                    <div className={"col-md-6"}>
                        <EquipmentTypeSelector
                            name={"equipment_type_id"}
                            value={data.equipment_type_id}
                            onChange={onChange}
                            disabled={isLoadingEquipmentUpdate || readOnly}
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <div
                            className={
                                "custom-validation " +
                                (validation.idle_threshold_type.isValid || !validation.idle_threshold_type.message ? "" : " is-invalid")
                            }
                        >
                            <label>Idle Threshold Type:</label>
                            <SelectWrapper
                                className={validation.idle_threshold_type.isValid || !validation.idle_threshold_type.message ? "" : " is-invalid"}
                                style={{
                                    width: "100%",
                                    display: "block",
                                }}
                                name="idle_threshold_type"
                                defaultValue={data.idle_threshold_type}
                                onChange={onChange}
                                disabled={isLoadingEquipmentUpdate || readOnly}
                            >
                                <option value="">---</option>
                                {Object.values(IDLE_THRESHOLD_TYPES).map((type) => (
                                    <option
                                        key={type}
                                        value={type}
                                    >
                                        {Helper.capitalize(type)}
                                    </option>
                                ))}
                            </SelectWrapper>
                        </div>
                        <ValidationError message={validation.idle_threshold_type.message} />
                    </div>
                    <div className="form-group col-md-6">
                        <div
                            className={
                                "custom-validation " + (validation.idle_threshold.isValid || !validation.idle_threshold.message ? "" : " is-invalid")
                            }
                        >
                            <label>Idle Threshold:</label>
                            <InfoTooltip iconClass="blue-tooltip">
                                RMS readings below the specified idle threshold level will reduce the number of TWF/FFT readings being taken until the RMS
                                readings exceed the idle threshold. The minimum idle threshold value is 0.
                            </InfoTooltip>
                            <div className={"input-group"}>
                                <input
                                    className={
                                        "form-control " + (validation.idle_threshold.isValid || !validation.idle_threshold.message ? "" : " is-invalid")
                                    }
                                    type="number"
                                    name="idle_threshold"
                                    defaultValue={data.idle_threshold}
                                    onChange={onChange}
                                    disabled={isLoadingEquipmentUpdate || readOnly}
                                />
                                <div className="input-group-append">
                                    <span className="input-group-text">
                                        {Helper.getIdleThresholdMeasure(props.chartTypes, data.idle_threshold_type, "N/A")}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <ValidationError message={validation.idle_threshold.message} />
                    </div>
                    {hasImpactVue && (
                        <>
                            <div className="form-group col-md-6">
                                <div
                                    className={
                                        "custom-validation " +
                                        (validation.impact_idle_threshold_type.isValid || !validation.impact_idle_threshold_type.message
                                            ? ""
                                            : " is-invalid")
                                    }
                                >
                                    <label>ImpactVue Idle Threshold Type:</label>
                                    <SelectWrapper
                                        className={
                                            validation.impact_idle_threshold_type.isValid || !validation.impact_idle_threshold_type.message
                                                ? ""
                                                : " is-invalid"
                                        }
                                        style={{
                                            width: "100%",
                                            display: "block",
                                        }}
                                        name="impact_idle_threshold_type"
                                        defaultValue={data.impact_idle_threshold_type}
                                        onChange={onChange}
                                        disabled={true}
                                    >
                                        <option value="">---</option>
                                        {Object.values(IDLE_THRESHOLD_TYPES).map((type) => (
                                            <option
                                                key={type}
                                                value={type}
                                            >
                                                {Helper.capitalize(type)}
                                            </option>
                                        ))}
                                    </SelectWrapper>
                                </div>
                                <ValidationError message={validation.impact_idle_threshold_type.message} />
                            </div>
                            <div className="form-group col-md-6">
                                <div
                                    className={
                                        "custom-validation " +
                                        (validation.impact_idle_threshold.isValid || !validation.impact_idle_threshold.message ? "" : " is-invalid")
                                    }
                                >
                                    <label>ImpactVue Idle Threshold:</label>
                                    <InfoTooltip iconClass="blue-tooltip">
                                        RMS readings below the specified idle threshold level will reduce the number of TWF/FFT readings being taken until
                                        the RMS readings exceed the idle threshold. The minimum idle threshold value is 0.
                                    </InfoTooltip>
                                    <div className={"input-group"}>
                                        <input
                                            className={
                                                "form-control " +
                                                (validation.impact_idle_threshold.isValid || !validation.impact_idle_threshold.message
                                                    ? ""
                                                    : " is-invalid")
                                            }
                                            type="number"
                                            name="impact_idle_threshold"
                                            defaultValue={data.impact_idle_threshold}
                                            onChange={onChange}
                                            disabled={isLoadingEquipmentUpdate || readOnly}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                {Helper.getIdleThresholdMeasure(props.chartTypes, data.impact_idle_threshold_type, "N/A")}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <ValidationError message={validation.impact_idle_threshold.message} />
                            </div>
                        </>
                    )}
                    {props.auth.hasRouteCollectorAccess() && data.service === EQUIPMENT_SERVICE.SELF && !hasAssociativeSensors && (
                        <div className="form-group col-md-6">
                            <div
                                className={
                                    "custom-validation " +
                                    (validation.is_route_collector.isValid || !validation.is_route_collector.message ? "" : " is-invalid")
                                }
                            >
                                <div className={"input-group"}>
                                    <CheckboxFormGroup
                                        label={"Monitored by Route Collector"}
                                        name={"is_route_collector"}
                                        value={data.is_route_collector}
                                        onChange={onChange}
                                        readOnly={readOnly}
                                    />
                                </div>
                            </div>
                            <ValidationError message={validation.is_route_collector.message} />
                        </div>
                    )}
                    {!readOnly && (
                        <ModalFooter>
                            <div className="edit-btns d-flex justify-content-end">
                                <Button
                                    onClick={props.cancelEdit}
                                    color={"secondary"}
                                    className="btn-sm"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={handleSave}
                                    disabled={isLoadingEquipmentUpdate}
                                    color={"primary"}
                                    className="btn-sm ml-2"
                                >
                                    {isLoadingEquipmentUpdate ? (
                                        <span>
                                            <i className="fa fa-spinner"></i> Processing
                                        </span>
                                    ) : (
                                        "Save"
                                    )}
                                </Button>
                            </div>
                        </ModalFooter>
                    )}
                </div>
            )}

            {showConfirmAlert && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnBsStyle="default"
                    btnSize="xs"
                    title=""
                    onConfirm={handleSubmit}
                    onCancel={() => setShowConfirmAlert(false)}
                >
                    {confirmAlertMessage}
                </SweetAlert>
            )}
        </>
    );
};

Edit.propTypes = propTypes;

export default withRouter(withGlobalStore(Edit));
