import $ from "jquery";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {withGlobalStore} from "../../../../../stores/GlobalStore";
import {
    orderBy as _orderBy,
    isEqual as _isEqual,
    filter as _filter,
    get as _get,
    findIndex as _findIndex,
    union as _union,
    mapValues as _mapValues,
} from "lodash";
import SensorSortableList from "./sensorSortableList";
import ApiInstallationPoints from "../../../../../api/installationPoint";

import {isMobile} from "react-device-detect";
import arrayMove from "array-move";

import "../../../../../assets/scss/components/sensor/tabs-title.scss";
import "../../../../../assets/scss/components/sensor/sensor-filter.scss";
import {useChartSelectedEquipmentStore, useChartSelectedEquipmentStoreActions} from "../../../../../stores/zustand/ChartSelectedEquipmentStore";
import useAlarmList from "../../../../../hooks/api/Alarm/UseAlarmListQuery";
import {useEquipmentByParams} from "../../../../../hooks/api/equipment/useEquipmentQuery";
import {useQueryClient} from "@tanstack/react-query";
import {setColorForInstallationPoints} from "../../../../../helpers/installationPoints";
import {API_CONSTANTS} from "../../../../../api/constants/constants";

const listTitle = {
    sensor: "Sensors",
    adapter: "Adapters",
};

const ReadingTypesList = (props) => {
    const {auth, handleIntervalsModal, instPointType, toggleSensorsEditModal, isRouteCollector} = props;

    const {setSelectedInstallationPoints} = useChartSelectedEquipmentStoreActions();
    const equipmentSelected = useChartSelectedEquipmentStore();
    const {equipmentItem: equipment} = useEquipmentByParams();
    const {activeAlarms} = useAlarmList(equipment.id);
    const queryClient = useQueryClient();
    const showIntervalsBtn = instPointType === "sensor" && !isRouteCollector;

    const getInstallationPoints = () => {
        const filteredPoints = _filter(equipment.installationPoints, (point) => point.point_type === "adapter" || point.point_type === "sensor") || [];

        return _orderBy(filteredPoints, ["sort"], ["asc"]);
    };
    const isCheckedAllValidation = () => {
        let selectedInstPoints = equipmentSelected[equipment.id] || [];

        const selectedInstPointsForType = _filter(selectedInstPoints, (id) => _findIndex(installationPoints, ["id", id]) !== -1);

        return selectedInstPointsForType.length === installationPoints.length;
    };

    const [installationPoints, setInstallationPoints] = useState(getInstallationPoints);
    const [isCheckedAll, setIsCheckedAll] = useState(isCheckedAllValidation);

    useEffect(() => {
        setIsCheckedAll(isCheckedAllValidation());
    }, [equipment.installationPoints, equipmentSelected[equipment.id], equipment.id]);

    useEffect(() => {
        setInstallationPoints(getInstallationPoints());
    }, [equipment.installationPoints]);

    const copy = (e, hex_id) => {
        e.preventDefault();
        let input = document.createElement("textarea");
        document.body.appendChild(input);
        input.value = hex_id;
        input.select();
        document.execCommand("copy");
        document.body.removeChild(input);
    };

    const onCheckedAll = () => {
        const instPointIds = Object.values(_mapValues(installationPoints, "id"));

        let selectedInstPoints = [...equipmentSelected[equipment.id]] || [];

        if (isCheckedAll) {
            setSelectedInstallationPoints([], equipment.id, true);
            setIsCheckedAll(false);
        } else {
            selectedInstPoints = _union(selectedInstPoints, instPointIds);
            setSelectedInstallationPoints(selectedInstPoints, equipment.id, true);
            setIsCheckedAll(true);
        }
    };

    const onSortEnd = ({oldIndex, newIndex}) => {
        $("#" + getSortableListId() + " .sortable-list-horizontal-item").css({opacity: 1});

        const moved = arrayMove(installationPoints, oldIndex, newIndex);

        const rebuildPointsPositions = moved.map((point, index) => {
            point.sort = index;
            return {id: point.id, sort: index};
        });

        if (!_isEqual(installationPoints, moved)) {
            queryClient.setQueryData([API_CONSTANTS.GET_EQUIPMENT, +equipment.id], {
                ...equipment,
                installationPoints: setColorForInstallationPoints(moved),
            });
            setInstallationPoints(setColorForInstallationPoints(moved));
            ApiInstallationPoints.updatePointsSort(rebuildPointsPositions).then(({status}) => {
                if (status !== "ok") {
                    alert("Error");
                    return;
                }
            });
        }
    };

    const getSortableListId = () => {
        const {instPointType} = props;

        return "draggable-sensors-list-" + instPointType;
    };

    const sortableListId = getSortableListId();

    if (!installationPoints.length && instPointType === "adapter") return "";

    return (
        <div className={"mb-3"}>
            <div className="tab-content-title ml-1 d-flex justify-content-between align-items-center">
                <div className={"d-flex"}>
                    {installationPoints.length > 1 ? (
                        <span className="form-list form-list--row sensors-list">
                            <label
                                className={"form-checkbox checkbox-all_sensors"}
                                style={{display: "inline", marginBottom: 0}}
                                title={isCheckedAll ? "Deselect All" : "Select All"}
                            >
                                <input
                                    type="checkbox"
                                    checked={isCheckedAll}
                                    onChange={onCheckedAll}
                                />
                                {_get(listTitle, instPointType)}
                                <span
                                    style={{
                                        borderColor: "#4b4b4b",
                                        ...(isCheckedAll ? {backgroundColor: "#4b4b4b"} : {}),
                                    }}
                                />
                            </label>
                        </span>
                    ) : (
                        <div>{_get(listTitle, instPointType)}</div>
                    )}
                    {instPointType === "sensor" && !auth.equipmentViewOnly(equipment) && auth.userCan("editInstallationPoints") && (
                        <span
                            className="sensors-filter"
                            onClick={() => toggleSensorsEditModal(instPointType)}
                        >
                            <i className="fa fa-pencil-alt" />
                        </span>
                    )}
                </div>

                {showIntervalsBtn && (
                    <button
                        title="Intervals"
                        className={"btn btn-sm btn-info"}
                        onClick={handleIntervalsModal}
                    >
                        Intervals
                    </button>
                )}
            </div>
            <div className="sensors-list mb-3">
                <SensorSortableList
                    hideSortableGhost={false}
                    pressDelay={isMobile ? 150 : 0}
                    axis={"y"}
                    helperContainer={document.getElementById(sortableListId)}
                    onSortEnd={(obj) => {
                        $($("#draggable-sensors-list .sortable-list-horizontal-item")[obj.oldIndex]).css({opacity: 1});
                        onSortEnd(obj);
                    }}
                    onSortStart={(obj) => $(obj.node).css({opacity: 0})}
                    copy={copy}
                    shouldCancelStart={(e) => {
                        if (["sensor-checkbox-wrapper"].indexOf(e.target.className.toLowerCase()) !== -1) {
                            return true; // Return true to cancel sorting
                        }
                    }}
                    instPointType={instPointType}
                    installationPoints={installationPoints}
                    sortableListId={sortableListId}
                    equipment={equipment}
                    activeAlarms={activeAlarms}
                    isRouteCollector={isRouteCollector}
                />
            </div>
        </div>
    );
};

ReadingTypesList.propTypes = {
    auth: PropTypes.object,
    handleChangeActiveGroup: PropTypes.func,
    toggleSensorsEditModal: PropTypes.func,
    handleIntervalsModal: PropTypes.func,
    instPointType: PropTypes.string,
    isRouteCollector: PropTypes.bool,
};

export default withGlobalStore(ReadingTypesList);
