import React from "react";
import PropTypes from "prop-types";
import {useAssetUpdateTreeMutation} from "../../../../hooks/api/assets/useAssetUpdateTreeMutation";

const LocationsActionsButtons = ({treeData, setTreeData, backUpTreeData}) => {
    const {mutateAsync: locationUpdateTree, isLoading} = useAssetUpdateTreeMutation();

    const onUpdateSorting = async () => {
        locationUpdateTree({newTree: treeData});
    };

    const onCancelSorting = () => {
        setTreeData(backUpTreeData);
    };
    return (
        <div className="d-flex justify-content-end align-items-center controls-btns-equipment sm-buttons">
            <div
                className={"btn btn-sm btn-secondary mr-2" + (isLoading ? " disabled" : "")}
                onClick={onCancelSorting}
            >
                {isLoading && <i className="fa fa-spinner ml-2" />}
                Cancel
            </div>
            <div
                className={"btn btn-sm btn-primary" + (isLoading ? " disabled" : "")}
                onClick={onUpdateSorting}
            >
                {isLoading && <i className="fa fa-spinner ml-2" />}
                Save
            </div>
        </div>
    );
};

LocationsActionsButtons.propTypes = {
    setTreeData: PropTypes.func,
    treeData: PropTypes.array,
    backUpTreeData: PropTypes.array,
};

export default LocationsActionsButtons;
