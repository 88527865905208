import {useQuery} from "@tanstack/react-query";
import {API_CONSTANTS} from "../../../api/constants/constants";
import SsdApi from "../../../api/ssd";

const searchGatewayBySerialKey = (filters) => [API_CONSTANTS.SEARCH_GATEWAY_BY_SERIAL, filters.query];
const searchGatewayBySerialFn = (filters) => SsdApi.searchGatewayBySerial({query: filters.query});

export const useSearchGatewayBySerialQuery = (filters) => {
    return useQuery({
        queryFn: () => searchGatewayBySerialFn(filters),
        queryKey: searchGatewayBySerialKey(filters),
        enabled: filters.enabled,
        cacheTime: 0,
        select: (res) => {
            return res.data.map((item) => ({
                value: item.serial,
                label: item.label,
                equipmentType: item.equipmentType,
                activeWwAi: item.activeWwAi,
            }));
        },
    });
};
