import ApiLocation from "../../../api/location";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import Toast from "../../../pages/shared/toast";
import {API_CONSTANTS} from "../../../api/constants/constants";

const assetUpdateTreeFn = ({newTree}) => ApiLocation.updateTree({newTree});

export const useAssetUpdateTreeMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: assetUpdateTreeFn,
        onSuccess: () => {
            queryClient.invalidateQueries([API_CONSTANTS.GET_ASSETS_LIST]);
            Toast.success("Your changes have been saved.");
        },
        onError: (res) => {
            Toast.error(res.message);
        },
    });
};
