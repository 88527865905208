import {useMemo} from "react";
import {INST_POINT_COLORS_DARK_THEME, INST_POINT_COLORS_LIGHT_THEME} from "../../constants/constants";

export const useIsDarkTheme = () => useMemo(() => localStorage.getItem("lightMode"), []);

export const useInstallationPointColors = () => {
    const isDarkTheme = useIsDarkTheme();
    return isDarkTheme === "true" ? INST_POINT_COLORS_DARK_THEME : INST_POINT_COLORS_LIGHT_THEME;
};


