import {useQuery} from "@tanstack/react-query";
import {API_CONSTANTS} from "../../../api/constants/constants";
import SsdApi from "../../../api/ssd";

const getAvailableEquipmentKey = () => [API_CONSTANTS.GET_AVAILABLE_EQUIPMENT_TYPE];
const getAvailableEquipmentFn = () => SsdApi.getAvailableEquipmentTypes();

export const useGetAvailableEquipmentTypeQuery = () => {
    return useQuery({
        queryFn: () => getAvailableEquipmentFn(),
        queryKey: getAvailableEquipmentKey(),
        select: (res) => res.data,
    });
};
