import React, {useEffect, useState} from "react";
import FFTAlertTypeControl from "../parts/fft-alert-type-control";
import AxisTypeControl from "../parts/axis-type-control";
import {filter as _filter, find as _find, get as _get} from "lodash";
import BandAlertTemplate from "../parts/band-alert-template";
import EnvelopeAlertTemplate from "../parts/envelope-alert-template";
import TachometerRpmAlertTemplate from "../parts/tachometer-rpm-alert-template";
import SimpleAlertTemplate from "../parts/simple-alert-template";
import {useHistory} from "react-router";
import PropTypes from "prop-types";
import {useChartComputed, useChartType} from "../hooks/helpers/chart";
import {withGlobalStore} from "../../../stores/GlobalStore";
import {useInstallationPointIds} from "../../../hooks/helpers/useInstallationPointIds";
import useAlertLevelsList from "../../../hooks/api/Alert/useAlertLevelList";
import useAlertLevelsConditionsListQuery from "../../../hooks/api/Alert/useAlarmConditionLevelsListQuery";
import {useSelfUserQuery} from "../../../hooks/api/user/useSelfUserQuery";
import {useAlertConditionListQuery} from "../../../hooks/api/alertCondition/useAlertConditionListQuery";
import {
    useAddBandTo,
    useAddTachometerRpmTo,
    useAlertConditionStoreActions,
    useConfirmCancel,
    useCurrentAlertCondition,
    useCurrentAlertGroup,
    useCurrentIsChanged,
    useDeleteId,
    useReloadFlag,
    useSelectedTab,
    useStdDeviations,
    useTempAlertId,
    useConfirmRemove,
} from "../../../stores/zustand/AlertConditionStore";
import Toast from "../../../pages/shared/toast";
import ApiAlertCondition from "../../../api/alertCondition";
import {cloneDeep as _cloneDeep} from "lodash";
import {useFindCurrent, useSetAlert} from "../hooks/helpers/alerts";
import {useInstallationPoint} from "../hooks/useInstallationPoint";
import {useTachometer} from "../hooks/helpers/tachometer";
import {useAlertConditionUpdateMutation} from "../../../hooks/api/alertCondition/useAlertConditionUpdateMutation";
import {useAlertConditionCreateMutation} from "../../../hooks/api/alertCondition/useAlertConditionCreateMutation";
import {useAlertConditionDeleteMutation} from "../../../hooks/api/alertCondition/useAlertConditionDeleteMutation";
import {useAlertConditionUpdateMultipleMutation} from "../../../hooks/api/alertCondition/useAlertConditionUpdateMultipleMutation";
import SweetAlert from "react-bootstrap-sweetalert";
import {useInstallationPointsListQuery} from "../../../hooks/api/installationPoints/useInstallationPointsListQuery";
import {Loader} from "../../../shared";
import Helper from "../../../helpers/helper";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";
import useAxisLabelQuery from "../../../hooks/api/axisLabel/useAxisLabelQuery";
import {useChartTypesStore} from "../../../stores/zustand/ChartSelectedChartTypeStore";
import {useAlertConditionListQueryInvalidate} from "../../../hooks/api/alertCondition/useAlertConditionListQueryInvalidate";
import {FREQUENCY_TYPES} from "../../../constants/constants";
import {useCurrentSpeed} from "../hooks/helpers/useCurrentSpeed";
import {Link} from "react-router-dom";
import {useIsImpactVue} from "../../../pages/chart/highcharts/hooks/useChartTypes";
import useCustomChartTypes from "../../../hooks/api/Chart/UseCustomChartTypes";

const AlertBody = () => {
    const history = useHistory();
    const [showValidationError, setShowValidationError] = useState(false);
    const {equipmentItem: equipment} = useEquipmentByParams();
    const {axisLabelsChart: axisLabels} = useAxisLabelQuery(equipment.id);
    const chartTypes = useChartTypesStore();
    const alertType = useSelectedTab();
    const currentAlertCondition = useCurrentAlertCondition();
    const currentGroup = useCurrentAlertGroup();

    const [showActiveActiveTriggerConfirmation, setShowActiveActiveTriggerConfirmation] = useState(false);
    const [confirmIgnoreActiveActiveTrigger, setConfirmIgnoreActiveActiveTrigger] = useState(false);

    useEffect(() => {
        if (confirmIgnoreActiveActiveTrigger) {
            setShowActiveActiveTriggerConfirmation(false);
            saveCurrent();
        }
    }, [confirmIgnoreActiveActiveTrigger]);

    const {
        setCurrent,
        setCurrentIsChanged,
        setCurrentAlertGroup,
        setTempAlertId,
        setInProgress,
        setReloadFlag,
        setAddBandTo,
        setAddTachometerRpmTo,
        setConfirmCancel,
        setConfirmRemove,
    } = useAlertConditionStoreActions();
    const deleteId = useDeleteId();
    const confirmRemove = useConfirmRemove();

    const {readingType, chartType, axisId, hasAxis, locationId, units, alias, specs, versionType} = useChartComputed(chartTypes, equipment);
    const isTachometer = alertType === "tachometer-rpm";
    const {fftAlertType, isChartFFT, isEnvelope, isBand} = useChartType(chartTypes);
    const {tachometerList} = useTachometer(equipment, chartType);
    const tachometerRpmAlertList = tachometerList;
    const isImpactVue = useIsImpactVue(chartType);
    const {customChartTypes, isSuccess: isCustomChartTypesSuccess} = useCustomChartTypes(equipment.id, chartTypes);
    const isAdapterChartType = customChartTypes?.[chartType];

    const showAxisTypeControl = hasAxis && !isImpactVue && !isAdapterChartType && isCustomChartTypesSuccess;

    const installationPoint = useInstallationPoint(equipment, chartType);
    const installationPointId = installationPoint.id;
    const installationPointIds = useInstallationPointIds(equipment);
    const {alertLevelsList: levels, isSuccess: isAlertLevelsSuccess} = useAlertLevelsList();
    const {alertLevelsConditionsList: conditions} = useAlertLevelsConditionsListQuery();
    const {installationPoints, isSuccess: isInstallationPointSuccess} = useInstallationPointsListQuery(equipment.id);
    const {users, isSuccess: isUserSuccess} = useSelfUserQuery(locationId);
    const {
        alertConditionsGroup: alertGroups,
        alertConditionsGroupNormalized: alertGroupsNormalized,
        alertConditions,
        isSuccess: isAlertConditionalSuccess,
    } = useAlertConditionListQuery(chartType, installationPointIds, installationPointId);
    const alertList = alertConditions;
    const {mutateAsync: updateAlertCondition, isLoading: isAlertUpdateLoading} = useAlertConditionUpdateMutation(chartType, installationPointIds);
    const {mutateAsync: createAlertCondition, isLoading: isAlertCreateLoading} = useAlertConditionCreateMutation(chartType);
    const {mutateAsync: deleteAlertCondition, isLoading: isAlertDeleteLoading} = useAlertConditionDeleteMutation(chartType);
    const {mutateAsync: updateMultipleAlertCondition, isLoading: isAlertMultipleUpdateLoading} = useAlertConditionUpdateMultipleMutation(chartType);
    const inProgress = isAlertCreateLoading || isAlertUpdateLoading || isAlertMultipleUpdateLoading;
    const invalidateAlertConditionsList = useAlertConditionListQueryInvalidate(chartType, installationPointIds);

    const setAlert = useSetAlert(chartTypes, equipment, alertType);
    const findCurrent = useFindCurrent(chartTypes, equipment, alertType);

    const speedConfig = useCurrentSpeed();
    const currentSpeed = speedConfig.value || 0;
    const currentFrequency =
        currentSpeed === 0 && currentAlertCondition.fftBandFrequencyType === FREQUENCY_TYPES.ORDERS
            ? FREQUENCY_TYPES.HZ
            : currentAlertCondition.fftBandFrequencyType;

    const currentIsChanged = useCurrentIsChanged();

    const addBandTo = useAddBandTo();
    const addTachometerRpmTo = useAddTachometerRpmTo();
    const stdDeviations = useStdDeviations();
    const reloadFlag = useReloadFlag();
    const confirmCancel = useConfirmCancel();

    useEffect(() => {
        if (isUserSuccess && isInstallationPointSuccess && confirmCancel.isConfirmed) {
            confirmCancel.callback();
            setConfirmCancel({callback: undefined, isConfirmed: false});
        }
    }, [confirmCancel.isConfirmed]);
    const currentTab = useSelectedTab();

    const tempAlertId = useTempAlertId();

    useEffect(() => {
        if (!isInstallationPointSuccess || !isAlertLevelsSuccess || !isAlertConditionalSuccess) {
            return;
        }

        const hashParams = Helper.getHashParams();
        const currentLevel = currentAlertCondition.alertLevel || {};
        let list = alertConditions || [];

        const tachometersList = _filter(alertConditions, (alertCondition) => +alertCondition?.isTachometerRpm === 1) || tachometerRpmAlertList || [];
        if (currentTab === "linear") {
            list = _filter(list, (item) => +item?.installationPointId === +installationPointId);
        }

        const alertId = hashParams.id || tempAlertId;
        const tachometerList = _filter(tachometersList, (item) => +item.installationPointId === +installationPointId);
        const currentForUpdate =
            findCurrent(hashParams.levelId, list, undefined, alertId, tachometerList) ||
            findCurrent(currentLevel.id || _get(levels, [0, "id"]), list, undefined, alertId, tachometerList) ||
            setAlert(currentLevel.id ? currentLevel : _get(levels, [0], {}), list);

        setCurrent(currentForUpdate);
        setTempAlertId(undefined);
        setCurrentIsChanged(false);

        if (hashParams.levelId || hashParams.id) history.push(Helper.deleteHashParams(["levelId", "id"]));
    }, [installationPointId, chartType, levels, installationPoints, users, alertGroups, currentTab, alertConditions, axisId, currentGroup, isEnvelope]);

    const switchCurrentAlert = (currentAlertCondition) => {
        let callback;
        if (currentIsChanged) {
            setConfirmCancel({
                callback: () => {
                    setCurrent(currentAlertCondition);
                    setCurrentIsChanged(false);
                },
            });
        } else {
            setCurrent(currentAlertCondition, callback);
        }
    };

    const getAlert = (level) => {
        return findCurrent(level.id) || setAlert(level) || {};
    };

    const resetErrorLock = () => {
        if (inProgress && showValidationError) {
            setInProgress(false);
        }
        if (showValidationError) {
            setShowValidationError(false);
        }
    };

    const setConfirm = (callback) => {
        resetErrorLock();
        setConfirmCancel({callback});
    };

    const removeCurrent = () =>
        deleteAlertCondition({alert_condition_id: deleteId || currentAlertCondition.id}).then(() => {
            Toast.success("The alert has been deleted.");
            if (alertType === "delta-t") {
                let currentGroupDispatch;
                let currentGroupID = _get(currentGroup, "id", false);

                const currentGroupAlerts = _get(alertGroupsNormalized, [axisId, currentGroupID, "alerts"], []);
                const currentGroupAlertsLength = Object.keys(currentGroupAlerts).length;

                if (currentGroupAlertsLength < 2) {
                    let otherGroup = _find(_get(alertGroupsNormalized, [axisId], []), (group) => group.id !== currentGroupID);

                    currentGroupDispatch = {
                        id: _get(otherGroup, "id", false),
                        points: _get(otherGroup, "points", []),
                        names: _get(otherGroup, "names", []),
                    };
                } else {
                    currentGroupDispatch = _cloneDeep(currentGroup);
                }

                setConfirmRemove(false);
                setCurrentIsChanged(false);
                setCurrentAlertGroup(currentGroupDispatch);
            } else {
                setConfirmRemove(false);
                setCurrentIsChanged(false);
            }
        });

    const resetCurrent = () => {
        resetErrorLock();
        const currentLocal =
            findCurrent(
                currentAlertCondition.alert_level_id,
                undefined,
                isBand && fftAlertType === currentAlertCondition.fftAlertType ? currentAlertCondition.index : undefined
            ) || setAlert(currentAlertCondition.alertLevel);
        setCurrent(currentLocal);
        setCurrentIsChanged(false);
    };
    const addBand = (level) => {
        if (currentIsChanged)
            setConfirm(() => {
                setCurrent(setAlert(level));
                setAddBandTo(`${axisId}-${level.id}`);
            });
        else {
            setCurrent(setAlert(level));
            setAddBandTo(`${axisId}-${level.id}`);
        }
    };

    const checkChanges = (data) => {
        if (isEnvelope) {
            if (
                +data.following_type !== +data.followingType ||
                +data.fft_envelope_type !== +data.fftEnvelopeType ||
                +data.fft_envelope_std !== +data.fftEnvelopeStd ||
                +data.fft_envelope_percent !== +data.fftEnvelopePercent ||
                +data.fft_envelope_minimum_value !== +data.fftEnvelopeMinimumValue ||
                +data.fft_envelope_calc_type !== +data.fftEnvelopeCalcType ||
                data.avg_date_from !== data.avgDateFrom ||
                data.avg_date_to !== data.avgDateTo ||
                +data.avg_last_d_count !== +data.avgLastDCount ||
                +data.avg_last_r_count !== +data.avgLastRCount ||
                +data.fft_envelope_spectral_window_width !== +data.fftEnvelopeSpectralWindowWidth
            ) {
                return true;
            }
        } else {
            switch (data.condition) {
                case "=":
                    return +_get(data, "alertTriggered.reading_value") !== +data.value;
                case ">=":
                    return +_get(data, "alertTriggered.reading_value") < +data.value;
                case ">":
                    return +_get(data, "alertTriggered.reading_value") <= +data.value;
                case "<=":
                    return +_get(data, "alertTriggered.reading_value") > +data.value;
                case "<":
                    return +_get(data, "alertTriggered.reading_value") >= +data.value;
            }
        }

        return false;
    };

    const saveCurrent = () => {
        let data = _cloneDeep(currentAlertCondition);

        if (_get(data, "alertTriggered") && checkChanges(data)) {
            if (confirmIgnoreActiveActiveTrigger) {
                data.confirmIgnoreActiveAlerTrigger = true;
            } else {
                setShowActiveActiveTriggerConfirmation(true);
                return;
            }
        }

        data.alertType = alertType;
        data.isTachometerRpm = data.alertType === "tachometer-rpm" ? "1" : "0";

        if (data.alertType !== "linear" && data.alertType !== "tachometer-rpm") {
            data.groupPoints = currentGroup.points || [];

            let currentEquipmentPointCount = 0;
            data.groupPoints.map((pointId) => {
                if (_find(equipment.installationPoints, (point) => +point.id === +pointId)) {
                    currentEquipmentPointCount++;
                }
            });

            if (currentEquipmentPointCount === 0) {
                return Toast.warning("You must select at least one installation point from the current equipment.");
            }

            if (data.groupPoints.length < 2) {
                return Toast.warning("You must select at least two installation points.");
            }

            if (data.value === "") {
                setShowValidationError(true);
                return false;
            } else if (data.requireReadings === "" || data.requireReadings < 1) {
                setShowValidationError(true);
                return false;
            } else if (+data.requireReadings !== parseInt("" + data.requireReadings)) {
                setShowValidationError(true);
                return false;
            } else if (data.groupInterval === "" || data.groupInterval < 1) {
                setShowValidationError(true);
                return false;
            } else if (+data.groupInterval !== parseInt("" + data.groupInterval)) {
                setShowValidationError(true);
                return false;
            } else {
                setShowValidationError(false);
            }
        }

        let preCheckedUsers = [];
        users.forEach((user) => {
            if ((user.alert_levels || []).indexOf(+data.alert_level_id) !== -1)
                preCheckedUsers.push({
                    value: user.id,
                    label: user.full_name,
                    isFixed: true,
                });
        });

        let groupEmailUsers = [];
        (data.groupEmailUsers || []).filter((userId) => {
            const user = _find(users, (user) => +user.id === +userId);
            if (user && !_find(preCheckedUsers, (user) => +user.value === +userId) && !_find(groupEmailUsers, (user) => +user.value === +userId)) {
                groupEmailUsers.push({
                    value: userId,
                    label: (user || {}).full_name,
                    isFixed: true,
                    title: "User is included in Alert Group for this equipment.",
                });
            }
        });

        if (+data.email && !(data.emailUsers || []).length && !preCheckedUsers.length && !groupEmailUsers.length) {
            return Toast.warning("You must select at least one email address from the drop-down list.");
        }

        if (data.rmsOd === "1") {
            if (!data.f_max) data.f_max = versionType === 1 ? 12800 : 2500;
            if (!data.lines_of_resolution) data.lines_of_resolution = 1024;
        }
        if (isBand && +data.fftBandFrom > +data.fftBandTo) {
            data = {...data, fftBandFrom: data.fftBandTo, fftBandTo: data.fftBandFrom};
        }
        setInProgress(false);

        if (+axisId === 0) {
            if (data.alertType === "linear") {
                if (data.value === "") {
                    setShowValidationError(true);
                    return false;
                }
            }

            if (isBand || data.alertType === "tachometer-rpm") {
                if (data.value === "" && data.tachometerRpmFrom !== "" && data.tachometerRpmTo !== "") {
                    setShowValidationError(true);
                    return false;
                }

                if (data.alertType === "tachometer-rpm") {
                    if (
                        (data.tachometerRpmFrom === "" && data.tachometerRpmTo === "") ||
                        (data.tachometerRpmFrom === "" && data.tachometerRpmTo === "" && data.value === "")
                    ) {
                        setShowValidationError(true);
                        return false;
                    }

                    if (+data.tachometerRpmFrom < 0 && +data.tachometerRpmTo < 0) {
                        setShowValidationError(true);
                        return false;
                    }

                    if (data.tachometerRpmFrom === "" || data.tachometerRpmFrom === null) {
                        setShowValidationError(true);
                        return false;
                    } else if (+data.tachometerRpmFrom > +data.tachometerRpmTo && data.tachometerRpmTo !== "") {
                        setShowValidationError(true);
                        return false;
                    } else if (+data.tachometerRpmFrom < 0 || !Number.isInteger(+data.tachometerRpmFrom)) {
                        setShowValidationError(true);
                        return false;
                    } else {
                        setShowValidationError(false);
                    }

                    if (data.tachometerRpmTo === "" || data.tachometerRpmTo === null) {
                        setShowValidationError(true);
                        return false;
                    } else if (+data.tachometerRpmTo < +data.tachometerRpmFrom && data.tachometerRpmFrom !== "") {
                        setShowValidationError(true);
                        return false;
                    } else if (+data.tachometerRpmTo < 0 || !Number.isInteger(+data.tachometerRpmTo)) {
                        setShowValidationError(true);
                        return false;
                    } else {
                        setShowValidationError(false);
                    }
                }
                // TODO react query
                Promise.all(
                    Object.keys((chartTypes[chartType] || {}).series || {}).map((key) =>
                        ApiAlertCondition.create({
                            ...data,
                            axisId: key,
                            axis_id: key,
                        }).then(({result}) => result)
                    )
                )
                    .then(() => {
                        Toast.success("The alerts has been added.");
                        setCurrentIsChanged(false);
                        setInProgress(false);
                        invalidateAlertConditionsList();
                    })
                    .catch(() => setInProgress(false));
            } else if (!isEnvelope) {
                if (data.alertType !== "linear" && data.alertType !== "tachometer-rpm") {
                    if (data.id) {
                        if (data.value === "") {
                            setShowValidationError(true);
                            return false;
                        } else if (data.requireReadings === "" || data.requireReadings < 1) {
                            setShowValidationError(true);
                            return false;
                        } else {
                            setShowValidationError(false);
                        }
                        updateAlertCondition({
                            ...data,
                            axisId: 1,
                            axis_id: 1,
                        })
                            .then(() => {
                                Toast.success("The alerts has been updated.");
                                setCurrentIsChanged(false);
                                setInProgress(false);
                            })
                            .catch(() => setInProgress(false));
                    } else {
                        createAlertCondition({
                            ...data,
                            axisId: 1,
                            axis_id: 1,
                            axes: [1, 2, 3],
                        })
                            .then(({result}) => {
                                Toast.success("The alerts has been updated.");
                                setCurrentIsChanged(false);
                                setInProgress(false);
                                setReloadFlag(alertType !== "linear" ? !reloadFlag : reloadFlag);
                                setCurrentAlertGroup({...currentGroup, id: _get(result, "0.group_id") || false});
                            })
                            .catch(() => setInProgress(false));
                    }
                } else {
                    if (data.alertType !== "tachometer-rpm") {
                        if (data.value === "") {
                            setShowValidationError(true);
                            return false;
                        } else if (data.requireReadings === "" || data.requireReadings < 1) {
                            setShowValidationError(true);
                            return false;
                        } else {
                            setShowValidationError(false);
                        }
                        updateMultipleAlertCondition(
                            Object.keys((chartTypes[chartType] || {}).series || {}).map((key) => ({
                                ...data,
                                axisId: key,
                                axis_id: key,
                                chartType,
                            }))
                        )
                            .then(() => {
                                Toast.success("The alerts has been updated.");
                                setCurrentIsChanged(false);
                                setInProgress(false);
                            })
                            .catch(() => setInProgress(false));
                    }
                }
            }
        } else {
            if (data.alertType === "tachometer-rpm") {
                if (data.value === "") {
                    setShowValidationError(true);
                }

                if (+data.tachometerRpmFrom === 0 && +data.tachometerRpmTo === 0) {
                    setShowValidationError(true);
                    return false;
                }

                if (+data.tachometerRpmFrom < 0 && +data.tachometerRpmTo < 0) {
                    setShowValidationError(true);
                    return false;
                }

                if (data.tachometerRpmFrom !== "" && data.tachometerRpmTo !== "") {
                    if (data.value !== "") {
                        setShowValidationError(false);
                    }
                }

                if (data.tachometerRpmFrom === "" || data.tachometerRpmFrom === null) {
                    setShowValidationError(true);
                    return false;
                } else if (+data.tachometerRpmFrom > +data.tachometerRpmTo && data.tachometerRpmTo !== "") {
                    setShowValidationError(true);
                    return false;
                } else if (+data.tachometerRpmFrom < 0 || !Number.isInteger(+data.tachometerRpmFrom)) {
                    setShowValidationError(true);
                    return false;
                } else {
                    setShowValidationError(false);
                }

                if (data.tachometerRpmTo === "" || data.tachometerRpmTo === null) {
                    setShowValidationError(true);
                    return false;
                } else if (+data.tachometerRpmTo < +data.tachometerRpmFrom && data.tachometerRpmFrom !== "") {
                    setShowValidationError(true);
                    return false;
                } else if (+data.tachometerRpmTo < 0 || !Number.isInteger(+data.tachometerRpmTo)) {
                    setShowValidationError(true);
                    return false;
                } else {
                    setShowValidationError(false);
                }
            }

            if (data.id) {
                if (data.value === "") {
                    setShowValidationError(true);
                    return false;
                } else if (data.requireReadings === "" || data.requireReadings < 1) {
                    setShowValidationError(true);
                    return false;
                } else if (data.alertType === "tachometer-rpm") {
                    if (data.value !== "") {
                        setShowValidationError(false);
                    }

                    if (data.tachometerRpmFrom < 0 || data.tachometerRpmFrom === "" || data.tachometerRpmTo < 0 || data.tachometerRpmTo === "") {
                        return false;
                    }
                } else {
                    setShowValidationError(false);
                }

                updateAlertCondition(data)
                    .then(({result}) => {
                        Toast.success("The alert has been updated.");
                        setCurrentIsChanged(false);
                        setTempAlertId(+result.id);
                        setInProgress(false);
                    })
                    .catch(() => setInProgress(false));
            } else {
                if (data.value === "") {
                    setShowValidationError(true);
                    return false;
                } else if (data.alertType === "tachometer-rpm") {
                    if (data.value !== "") {
                        setShowValidationError(false);
                    }

                    if (data.tachometerRpmFrom < 0 || data.tachometerRpmFrom === "" || data.tachometerRpmTo < 0 || data.tachometerRpmTo === "") {
                        return false;
                    }
                } else if (data.requireReadings === "" || data.requireReadings < 1) {
                    setShowValidationError(true);
                    return false;
                } else {
                    setShowValidationError(false);
                }
                createAlertCondition(data)
                    .then(({result}) => {
                        Toast.success("The alert has been added.");
                        setCurrentIsChanged(false);
                        setTempAlertId(+result.id);
                        setInProgress(false);
                        setReloadFlag(alertType !== "linear" ? !reloadFlag : reloadFlag);
                        setCurrentAlertGroup({...currentGroup, id: _get(result, "0.group_id") || false});
                        setAddBandTo(undefined);
                        setAddTachometerRpmTo(undefined);
                    })
                    .catch(() => setInProgress(false));
            }
        }
    };
    const updateCurrent = (data, withoutChanges) => {
        if (data.email === "1") {
            return setCurrent({...currentAlertCondition, email: data.email});
        }
        resetErrorLock();
        setCurrent({...currentAlertCondition, ...data});
        if (!withoutChanges) {
            setCurrentIsChanged(true);
        }
    };

    const tryRemoveCurrent = () => setConfirmRemove((prev) => !prev);
    const cancelConfirmModal = () => setConfirmRemove(false);

    const cancelAddTachometerRpm = () => {
        if (currentIsChanged) {
            setConfirm(() => {
                setCurrent(findCurrent(currentAlertCondition.alert_level_id));
                setCurrentIsChanged(false);
                setAddTachometerRpmTo(undefined);
            });
        } else {
            setCurrent(findCurrent(currentAlertCondition.alert_level_id));
            setCurrentIsChanged(false);
            setAddTachometerRpmTo(undefined);
        }
    };

    const addTachometerRpm = (level) => {
        if (currentIsChanged)
            setConfirm(() => {
                setCurrent(setAlert(level));
                setAddTachometerRpmTo(`${axisId}-${level.id}`);
            });
        else {
            setCurrent(setAlert(level));
            setAddTachometerRpmTo(`${axisId}-${level.id}`);
        }
    };
    const cancelAddBand = () => {
        if (currentIsChanged) {
            setConfirm(() => {
                setCurrent(findCurrent(currentAlertCondition.alert_level_id));
                setCurrentIsChanged(false);
                setAddBandTo(undefined);
            });
        } else {
            setCurrent(findCurrent(currentAlertCondition.alert_level_id));
            setCurrentIsChanged(false);
            setAddBandTo(undefined);
        }
    };

    if (!isUserSuccess || !isInstallationPointSuccess || !isAlertLevelsSuccess) {
        return <Loader />;
    }

    return (
        <>
            {isChartFFT && (
                <FFTAlertTypeControl
                    axisId={axisId}
                    fftAlertType={fftAlertType}
                    currentIsChanged={currentIsChanged}
                    history={history}
                    setConfirm={setConfirm}
                    isImpactVue={isImpactVue}
                />
            )}
            {showAxisTypeControl && (
                <AxisTypeControl
                    axisId={axisId}
                    history={history}
                    axisLabels={axisLabels}
                    chartType={chartType}
                    chartTypes={chartTypes}
                    isEnvelope={isEnvelope}
                    currentIsChanged={currentIsChanged}
                    installationPointId={installationPointId}
                    setConfirm={setConfirm}
                />
            )}
            <div>
                {levels.map((level) => {
                    if (isBand) {
                        const alerts = _filter(
                            alertList,
                            (alert) =>
                                +alert.alert_level_id === +level.id &&
                                (isChartFFT ? +alert.axis_id === axisId && +alert.fftAlertType === +fftAlertType : +alert.reading_type_id === readingType)
                        );
                        if (alerts.length) {
                            return (
                                <React.Fragment key={level.id}>
                                    {alerts.map((alert) => {
                                        const index = `${level.id}-${alert.id}`;
                                        const isCurrent = +currentAlertCondition.alert_level_id === +level.id && currentAlertCondition.index === index;
                                        return (
                                            <BandAlertTemplate
                                                key={index}
                                                alias={alias}
                                                units={units}
                                                level={level}
                                                isChanged={currentIsChanged}
                                                inProgress={inProgress}
                                                isCurrent={isCurrent}
                                                alert={isCurrent ? currentAlertCondition : alert}
                                                users={users}
                                                conditions={conditions}
                                                versionType={versionType}
                                                currentSpeed={currentSpeed}
                                                currentFrequency={currentFrequency}
                                                specs={specs}
                                                setCurrent={switchCurrentAlert}
                                                update={updateCurrent}
                                                remove={tryRemoveCurrent}
                                                reset={resetCurrent}
                                                save={saveCurrent}
                                                validErrorMsg={showValidationError}
                                            />
                                        );
                                    })}
                                    {addBandTo === `${axisId}-${level.id}` ? (
                                        <BandAlertTemplate
                                            key={level.id}
                                            alias={alias}
                                            units={units}
                                            level={level}
                                            isAddBaned={true}
                                            isChanged={currentIsChanged}
                                            inProgress={inProgress}
                                            isCurrent={+currentAlertCondition.alert_level_id === +level.id && +currentAlertCondition.index === 0}
                                            alert={
                                                +currentAlertCondition.alert_level_id === +level.id && +currentAlertCondition.index === 0
                                                    ? currentAlertCondition
                                                    : setAlert(level)
                                            }
                                            users={users}
                                            conditions={conditions}
                                            versionType={versionType}
                                            currentSpeed={currentSpeed}
                                            currentFrequency={currentFrequency}
                                            specs={specs}
                                            cancelAddBand={cancelAddBand}
                                            setCurrent={switchCurrentAlert}
                                            update={updateCurrent}
                                            remove={tryRemoveCurrent}
                                            reset={resetCurrent}
                                            save={saveCurrent}
                                            validErrorMsg={showValidationError}
                                        />
                                    ) : (
                                        <span
                                            onClick={() => addBand(level)}
                                            className="add-new-link-manage mb-3"
                                        >
                                            <i className="fa fa-plus-circle" />
                                            <span>Add New {level.name || ""} Level</span>
                                        </span>
                                    )}
                                </React.Fragment>
                            );
                        }
                        const isCurrent = +currentAlertCondition.alert_level_id === +level.id && +currentAlertCondition.index === 0;
                        return (
                            <BandAlertTemplate
                                key={level.id}
                                alias={alias}
                                units={units}
                                level={level}
                                inProgress={inProgress}
                                isChanged={currentIsChanged}
                                isCurrent={isCurrent}
                                alert={isCurrent ? currentAlertCondition : setAlert(level)}
                                users={users}
                                conditions={conditions}
                                versionType={versionType}
                                currentSpeed={currentSpeed}
                                currentFrequency={currentFrequency}
                                specs={specs}
                                setCurrent={switchCurrentAlert}
                                update={updateCurrent}
                                remove={tryRemoveCurrent}
                                reset={resetCurrent}
                                save={saveCurrent}
                                validErrorMsg={showValidationError}
                            />
                        );
                    } else {
                        const isCurrent = +currentAlertCondition.alert_level_id === +level.id;
                        const alert = isCurrent ? _cloneDeep(currentAlertCondition) : getAlert(level);
                        if (isEnvelope) {
                            return (
                                <EnvelopeAlertTemplate
                                    key={level.id}
                                    alias={alias}
                                    level={level}
                                    isChanged={currentIsChanged}
                                    isCurrent={isCurrent}
                                    inProgress={inProgress}
                                    alert={alert}
                                    users={users}
                                    stdDeviations={stdDeviations}
                                    versionType={versionType}
                                    specs={specs}
                                    setCurrent={setCurrent}
                                    update={updateCurrent}
                                    remove={tryRemoveCurrent}
                                    reset={resetCurrent}
                                    save={saveCurrent}
                                    validErrorMsg={showValidationError}
                                />
                            );
                        } else {
                            if (isTachometer) {
                                const tachometerRpmAlerts = _filter(
                                    tachometerRpmAlertList,
                                    (alert) =>
                                        +alert.alert_level_id === +level.id &&
                                        (hasAxis ? +alert.axis_id === axisId : +alert.reading_type_id === readingType)
                                );
                                if (tachometerRpmAlerts.length) {
                                    return (
                                        <React.Fragment key={level.id}>
                                            {tachometerRpmAlerts.map((alert) => {
                                                const index = `${level.id}-${alert.id}`;
                                                const isCurrent =
                                                    +currentAlertCondition.alert_level_id === +level.id && currentAlertCondition.index === index;
                                                return (
                                                    <TachometerRpmAlertTemplate
                                                        key={index}
                                                        alias={alias}
                                                        units={units}
                                                        level={level}
                                                        isChanged={currentIsChanged}
                                                        isCurrent={isCurrent}
                                                        inProgress={inProgress}
                                                        alert={isCurrent ? currentAlertCondition : alert}
                                                        users={users}
                                                        conditions={conditions}
                                                        versionType={versionType}
                                                        specs={specs}
                                                        setCurrent={setCurrent}
                                                        update={updateCurrent}
                                                        remove={tryRemoveCurrent}
                                                        reset={resetCurrent}
                                                        save={saveCurrent}
                                                        validErrorMsg={showValidationError}
                                                    />
                                                );
                                            })}
                                            {addTachometerRpmTo === `${axisId}-${level.id}` ? (
                                                <TachometerRpmAlertTemplate
                                                    key={level.id + "addTachometerRpmTo"}
                                                    alias={alias}
                                                    units={units}
                                                    level={level}
                                                    isChanged={currentIsChanged}
                                                    inProgress={inProgress}
                                                    isCurrent={
                                                        +currentAlertCondition.alert_level_id === +level.id &&
                                                        (+currentAlertCondition.index === 0 || !currentAlertCondition.index)
                                                    }
                                                    alert={
                                                        +currentAlertCondition.alert_level_id === +level.id &&
                                                        (+currentAlertCondition.index === 0 || !currentAlertCondition.index)
                                                            ? currentAlertCondition
                                                            : setAlert(level)
                                                    }
                                                    users={users}
                                                    conditions={conditions}
                                                    versionType={versionType}
                                                    specs={specs}
                                                    setCurrent={setCurrent}
                                                    update={updateCurrent}
                                                    remove={tryRemoveCurrent}
                                                    reset={resetCurrent}
                                                    save={saveCurrent}
                                                    cancelAddTachometerRpm={cancelAddTachometerRpm}
                                                    validErrorMsg={showValidationError}
                                                />
                                            ) : (
                                                <span
                                                    onClick={() => addTachometerRpm(level)}
                                                    className="add-new-link-manage mb-3"
                                                >
                                                    <i className="fa fa-plus-circle" />
                                                    <span>Add New {level.name || ""} Level</span>
                                                </span>
                                            )}
                                        </React.Fragment>
                                    );
                                }
                                const isCurrent = +currentAlertCondition.alert_level_id === +level.id;
                                return (
                                    <TachometerRpmAlertTemplate
                                        key={level.id + "new"}
                                        alias={alias}
                                        units={units}
                                        level={level}
                                        inProgress={inProgress}
                                        isChanged={currentIsChanged}
                                        isCurrent={isCurrent}
                                        alert={isCurrent ? currentAlertCondition : setAlert(level)}
                                        users={users}
                                        conditions={conditions}
                                        versionType={versionType}
                                        specs={specs}
                                        setCurrent={switchCurrentAlert}
                                        update={updateCurrent}
                                        remove={tryRemoveCurrent}
                                        reset={resetCurrent}
                                        save={saveCurrent}
                                        validErrorMsg={showValidationError}
                                    />
                                );
                            } else {
                                const cautionLevel = _find(levels, (l) => l.name === "Caution") || {};
                                return (
                                    <SimpleAlertTemplate
                                        key={level.id}
                                        alias={alias}
                                        units={alertType === "delta-t" ? "dev of " + units : units}
                                        alertType={alertType}
                                        level={level}
                                        currentAxisId={axisId}
                                        isChanged={currentIsChanged}
                                        inProgress={inProgress}
                                        isCurrent={isCurrent}
                                        alert={alert}
                                        cautionValue={+getAlert(cautionLevel).value}
                                        users={users}
                                        conditions={conditions}
                                        versionType={versionType}
                                        specs={specs}
                                        setCurrent={switchCurrentAlert}
                                        update={updateCurrent}
                                        remove={tryRemoveCurrent}
                                        reset={resetCurrent}
                                        save={saveCurrent}
                                        validErrorMsg={showValidationError}
                                    />
                                );
                            }
                        }
                    }
                })}
            </div>
            {confirmRemove && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnBsStyle="default"
                    btnSize="xs"
                    title="DELETE ALERT"
                    delete={deleteId}
                    onConfirm={removeCurrent}
                    onCancel={cancelConfirmModal}
                    disabled={isAlertDeleteLoading}
                >
                    Are you sure you want to delete this alert?
                </SweetAlert>
            )}
            {confirmCancel.callback && (
                <SweetAlert
                    warning
                    showCancel
                    cancelBtnText="Cancel"
                    confirmBtnText="Yes"
                    cancelBtnBsStyle="default"
                    btnSize="xs"
                    title=""
                    onConfirm={() => setConfirmCancel({...confirmCancel, isConfirmed: true})}
                    onCancel={() => setConfirmCancel({})}
                >
                    Are you sure you want to navigate away from this page? If you proceed, ALL of your changes will be lost. Click Yes to continue, or
                    click Cancel to stay on the current page
                </SweetAlert>
            )}
            {showActiveActiveTriggerConfirmation && (
                <SweetAlert
                    warning
                    showCancel
                    cancelBtnText="Cancel"
                    confirmBtnText="Confirm"
                    cancelBtnBsStyle="default"
                    btnSize="xs"
                    style={{width: "50em"}}
                    title=""
                    onConfirm={() => {
                        setConfirmIgnoreActiveActiveTrigger(true);
                    }}
                    onCancel={() => setShowActiveActiveTriggerConfirmation(false)}
                >
                    <span>There are currently active alerts based on the existing alert configuration. </span>
                    <div className={"mt-4 mb-4"}>
                        <span>
                            <b>
                                {_get(currentAlertCondition, "alertTriggered.description")} - [{_get(currentAlertCondition, "alertTriggered.timestamp")}] -{" "}
                            </b>
                            <Link
                                className="link link--label color-primary mr-2"
                                to={
                                    "/chart/" +
                                    _get(equipment, "id") +
                                    "#sensors=" +
                                    _get(currentAlertCondition, "installation_point_id") +
                                    "&alertId=" +
                                    _get(currentAlertCondition, "alertTriggered.id")
                                }
                                target={"_blank"}
                            >
                                <i className="fa fa-eye" />
                                <span>View</span>
                            </Link>
                        </span>
                    </div>

                    <b>If you proceed, any active alerts will be marked as ignored.</b>
                </SweetAlert>
            )}
        </>
    );
};
AlertBody.propTypes = {
    chartTypes: PropTypes.object,
};
export default withGlobalStore(AlertBody);
