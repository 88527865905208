import $ from "jquery";
import React, {useEffect, useReducer} from "react";
import PropTypes from "prop-types";
import {NavLink, withRouter} from "react-router-dom";
import {withGlobalStore} from "../../../../stores/GlobalStore";
import Helper from "../../../../helpers/helper";
import WhatNewPopup from "../../../../pages/shared/what-new-popup";
import WhatsNewModal from "../../../../modals/whats-new/whats-new-modal";
import HelpIndex from "../../../../modals/help/components/index";
import HelpUserGuide from "../../../../modals/help/components/userGuide";

import "./Menu.scss";
import HelpInstallationGuide from "../../../../modals/help/components/installationGuide";
import {withLocationSelectStore} from "../../../../stores/LocationSelectStore";
import {ACTION, reducer} from "./Reducer/MainReducer";
import HelpFeedback from "../../../../modals/help/components/feedback/feedback";
import {useSurveyQuery} from "../../../../hooks/api/surveu/useSurveyQuery";
import HelpRouteCollectorGuide from "../../../../modals/help/components/routeCollectorGuide";

const initialState = {
    popoverNew: false,
    popoverOverlay: false,
    showKeyboardShortcutsModal: false,
    forceNewsUpdate: false,
    countWhatsNewItems: 0,
    showUserGuideModal: false,
    showInstallationGuideModal: false,
    showFaqModal: false,
    lightMode: localStorage.getItem("lightMode"),
    isViewNewItem: false,
    showFeedbackModal: false,
    showRouteCollectorModal: false,
};

const Menu = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const updateCountWhatsNewItems = (count) => {
        dispatch({
            type: ACTION.SET_STATE,
            state: {countWhatsNewItems: count},
        });
    };

    useEffect(() => {
        if (["/alarms", "/alarms/history"].indexOf(window.location.pathname) !== -1) {
            dropdownMenu("ul-alarms");
        }
        if (
            ["/network/gateways", "/network/nodes", "/network/routers", "/network/sensors", "/network/motes", "/network/self-bearings"].indexOf(
                window.location.pathname
            ) !== -1 ||
            window.location.pathname.indexOf("/network/tachometers") !== -1
        ) {
            dropdownMenu("ul-network");
        }
        props.updateCountActiveDeviceAlerts();
        props.updateCountActiveAlarms();
        props.updateCountActiveNews();
        props.updateCountGlobalNotes();
        props.updateCountActionItems();
    }, []);

    const dropdownMenu = (ulId) => {
        $("#" + ulId)
            .stop()
            .slideToggle(200);
    };

    const dropdownUser = () => {
        $(".user-dropdown").stop().slideToggle(200);
    };

    const toggleMenu = () => {
        props.toggleLeftSide.update(!props.toggleLeftSide.hideSide);
    };
    const getDashboardLink = () => {
        const {getLocationId} = props;
        const location = getLocationId();

        if (location) {
            return "/equipments/" + location;
        }
        return "/";
    };

    const togglePopover = () => {
        const {isViewNewItem} = state;
        const searchParams = Helper.getHashParams();

        if (!(searchParams.modal && searchParams.modal === "whats-new")) {
            if (!isViewNewItem) {
                dispatch({
                    type: ACTION.SET_STATE,
                    state: {
                        popoverOverlay: !state.popoverOverlay,
                        popoverNew: !state.popoverNew,
                    },
                });
                document.querySelector(".app-content").classList.toggle("open-overlay");
            } else {
                dispatch({
                    type: ACTION.SET_STATE,
                    state: {isViewNewItem: false},
                });
            }
        }
    };

    const setViewNewItem = () => {
        dispatch({type: ACTION.SET_STATE, state: {isViewNewItem: true}});
    };

    const toggleKeyboardShortcutsModal = () => {
        dispatch({
            type: ACTION.SET_STATE,
            state: {
                showKeyboardShortcutsModal: !state.showKeyboardShortcutsModal,
            },
        });
    };

    const toggleFeedbackModal = () => {
        dispatch({
            type: ACTION.SET_STATE,
            state: {
                showFeedbackModal: !state.showFeedbackModal,
            },
        });
    };

    const toggleRouteCollectorGuideModal = () => {
        dispatch({
            type: ACTION.SET_STATE,
            state: {
                showRouteCollectorModal: !state.showRouteCollectorModal,
            },
        });
    };

    const handleSurvey = () => {
        window.open("https://" + process.env.SURVEY_LANDING_DOMAIN + "/customer_satisfaction_survey", "_blank");
    };

    const toggleUserGuideModal = () => {
        dispatch({
            type: ACTION.SET_STATE,
            state: {showUserGuideModal: !state.showUserGuideModal},
        });
    };

    const toggleInstallationGuideModal = () => {
        dispatch({
            type: ACTION.SET_STATE,
            state: {
                showInstallationGuideModal: !state.showInstallationGuideModal,
            },
        });
    };

    const setForceUpdateListNews = () => {
        dispatch({
            type: ACTION.SET_STATE,
            state: {forceNewsUpdate: !state.forceNewsUpdate},
        });
    };

    const triggerDarkMode = () => {
        const localVal = localStorage.getItem("lightMode");
        if (localVal === "true") {
            // document.querySelector("BODY").classList.remove("dark");
            localStorage.setItem("lightMode", "false");
        } else {
            // document.querySelector("BODY").classList.add("dark");
            localStorage.setItem("lightMode", "true");
        }
        window.location.reload();
    };

    const {auth, countActiveAlarms, user, countActiveNews, countActiveDeviceAlerts, countGlobalNotes, countActionItems} = props;
    const {countWhatsNewItems} = state;
    const searchParams = Helper.getHashParams();

    const {data: surveyAvailability} = useSurveyQuery();

    return (
        <aside className="left-side">
            <div className="wrapper-logo-sidebar">
                <NavLink
                    exact
                    to={getDashboardLink()}
                    className="logo-wrapper"
                >
                    <div className="logo">
                        <img
                            className="logo-main"
                            src="/assets/img/landing/top-logo.svg"
                            alt=""
                        />
                        <img
                            className="logo-small"
                            src="/assets/pic/logo-small.svg"
                            alt=""
                        />
                    </div>
                </NavLink>
            </div>
            <div className={user.customer.is_custom_header_image ? "menu-trigger-wrap with-logo" : "menu-trigger-wrap"}>
                {user.customer.is_custom_header_image && (
                    <div className="customer-logo-wrapper">
                        <div className="customer-logo-container">
                            <img
                                src={user.customer.header_image || ""}
                                alt=""
                            />
                        </div>
                    </div>
                )}
                {user.customer && (
                    <div className="menu customer-name">
                        <ul className="mb-0">
                            <li>
                                <span className="menu-name">
                                    <span className="menu-icon">
                                        <img
                                            src="/assets/pic/icon-customer.svg"
                                            alt=""
                                        />
                                    </span>
                                    <span className="menu-name-text">{user.customer.name || ""}</span>
                                </span>
                            </li>
                        </ul>
                    </div>
                )}
                <div
                    className="btn-menu-trigger"
                    onClick={() => toggleMenu()}
                >
                    <img
                        src="/assets/pic/icon-menu-trigger.svg"
                        alt=""
                    />
                </div>

                {/* <div className="customer-logo-wrapper">
                        <img className="logo" style={{width: "65%", height: "auto"}} src="/assets/img/case-dhl-logo.png" alt="" />
                    </div> */}
            </div>
            <HelpIndex
                showModal={state.showKeyboardShortcutsModal}
                onClose={toggleKeyboardShortcutsModal}
                withHelpFft={true}
                withHelpSimple={true}
                withHelp3d={true}
            />

            {state.showUserGuideModal && (
                <HelpUserGuide
                    showModal={state.showUserGuideModal}
                    onClose={toggleUserGuideModal}
                />
            )}
            {state.showInstallationGuideModal && (
                <HelpInstallationGuide
                    showModal={state.showInstallationGuideModal}
                    onClose={toggleInstallationGuideModal}
                />
            )}

            {state.showFeedbackModal && (
                <HelpFeedback
                    showModal={state.showFeedbackModal}
                    auth={auth}
                    onClose={toggleFeedbackModal}
                />
            )}

            {state.showRouteCollectorModal && (
                <HelpRouteCollectorGuide
                    showModal={state.showRouteCollectorModal}
                    onClose={toggleRouteCollectorGuideModal}
                />
            )}

            <WhatNewPopup
                popUpProps={{
                    target: "#whats-new-popover",
                    isOpen: state.popoverNew,
                    placement: "right-start",
                    trigger: "legacy",
                    toggle: togglePopover,
                    popperClassName: "whats-new-popover-container",
                    container: "body",
                    delay: {
                        show: 0,
                        hide: 0,
                    },
                    fade: false,
                    modifiers: {
                        preventOverflow: {enabled: false},
                    },
                }}
                updateCountWhatsNew={updateCountWhatsNewItems}
                toggleWhatsPopup={togglePopover}
                user={user}
                history={props.history}
                forceUpdate={state.forceNewsUpdate}
                updateCountActiveNews={props.updateCountActiveNews}
                countActiveNews={+countActiveNews}
                setViewNewItem={setViewNewItem}
            />

            {state.popoverNew && <div className="whats-overlay"></div>}

            {searchParams.modal && searchParams.modal === "whats-new" && (
                <WhatsNewModal
                    id={+searchParams.id}
                    updateCountActiveNews={() => {
                        props.updateCountActiveNews();
                        setForceUpdateListNews();
                    }}
                />
            )}
            <div className="menu link-list">
                <ul className="menu-lvl-1">
                    <li className={`whats-new-menu-popover ${+countWhatsNewItems === 0 ? "hidden" : ""}`}>
                        <a
                            id="whats-new-popover"
                            className="menu-item"
                        >
                            <span className="menu-icon">
                                <img
                                    src="/assets/pic/news.svg"
                                    alt=""
                                />
                            </span>
                            {countActiveNews > 0 && (
                                <span className="menu-count badge badge-info">
                                    <span>{countActiveNews}</span>
                                </span>
                            )}
                            <span className="menu-name color-yellow-brand"> What&apos;s New</span>
                        </a>
                    </li>
                    <li className="help-menu-item menu-item-has-children">
                        <span
                            className="menu-item"
                            onClick={() => dropdownMenu("ul-helper")}
                        >
                            <span className="menu-icon">
                                <img
                                    src="/assets/pic/icon-info-yellow.svg"
                                    alt=""
                                />
                            </span>
                            <span className="menu-dropdown">
                                <img
                                    src="/assets/pic/icon-dropdown.svg"
                                    alt=""
                                />
                            </span>
                            <span className="menu-name">Help</span>
                        </span>

                        <ul
                            className="menu-lvl-2"
                            id="ul-helper"
                        >
                            <li>
                                <span
                                    className="menu-name menu-item"
                                    onClick={toggleUserGuideModal}
                                >
                                    <span className="menu-icon">
                                        <img
                                            src="/assets/pic/icon-arrow-right.svg"
                                            alt=""
                                        />
                                        <img
                                            src="/assets/pic/icon-wifi-solid.svg"
                                            alt=""
                                        />
                                    </span>
                                    <span className="menu-name">User Guide</span>
                                </span>
                            </li>
                            <li>
                                <span
                                    className="menu-name menu-item"
                                    onClick={toggleInstallationGuideModal}
                                >
                                    <span className="menu-icon">
                                        <img
                                            src="/assets/pic/icon-arrow-right.svg"
                                            alt=""
                                        />
                                        <img
                                            src="/assets/pic/icon-project-diagram-solid.svg"
                                            alt=""
                                        />
                                    </span>
                                    <span className="menu-name">Product Guides</span>
                                </span>
                            </li>
                            {auth.userCan("manageBLEDevicesAndRoutes") && (
                                <li>
                                    <span
                                        className="menu-name menu-item"
                                        onClick={toggleRouteCollectorGuideModal}
                                    >
                                        <span className="menu-icon">
                                            <img
                                                src="/assets/pic/icon-arrow-right.svg"
                                                alt=""
                                            />
                                            <img
                                                src="/assets/pic/icon-project-diagram-solid.svg"
                                                alt=""
                                            />
                                        </span>
                                        <span className="menu-name">Route Collector Guide</span>
                                    </span>
                                </li>
                            )}
                            <li>
                                <span
                                    className="menu-name menu-item"
                                    onClick={toggleKeyboardShortcutsModal}
                                >
                                    <span className="menu-icon">
                                        <img
                                            src="/assets/pic/icon-arrow-right.svg"
                                            alt=""
                                        />
                                        <img
                                            src="/assets/pic/icon-th-large-solid.svg"
                                            alt=""
                                        />
                                    </span>
                                    <span className="menu-name">Keyboard Shortcuts</span>
                                </span>
                            </li>
                            {surveyAvailability && (
                                <li>
                                    <span
                                        className="menu-name menu-item"
                                        onClick={handleSurvey}
                                    >
                                        <span className="menu-icon">
                                            <img
                                                src="/assets/pic/icon-arrow-right.svg"
                                                alt=""
                                            />
                                            <img
                                                src="/assets/pic/icon-th-large-solid.svg"
                                                alt=""
                                            />
                                        </span>
                                        <span className="menu-name">Satisfaction Survey</span>
                                    </span>
                                </li>
                            )}
                            <li>
                                <span
                                    className="menu-name menu-item"
                                    onClick={toggleFeedbackModal}
                                >
                                    <span className="menu-icon">
                                        <img
                                            src="/assets/pic/icon-arrow-right.svg"
                                            alt=""
                                        />
                                        <img
                                            src="/assets/pic/icon-th-large-solid.svg"
                                            alt=""
                                        />
                                    </span>
                                    <span className="menu-name">Submit Feedback</span>
                                </span>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <NavLink
                            exact
                            to={getDashboardLink()}
                            className="menu-item"
                            activeClassName={"current"}
                        >
                            <span className="menu-icon">
                                <img
                                    src="/assets/pic/icon-dashboard.svg"
                                    alt=""
                                />
                            </span>
                            <span className="menu-name">Dashboard</span>
                        </NavLink>
                    </li>

                    {auth.userCan("showEquipmentProfile") && (
                        <li>
                            <NavLink
                                exact
                                to={"/equipment-profile"}
                                className="menu-item"
                                activeClassName={"current"}
                            >
                                <span className="menu-icon">
                                    <img
                                        src="/assets/img/profile/menu-icon.svg"
                                        alt=""
                                    />
                                </span>
                                <span className="menu-name">Equipment Profiles</span>
                            </NavLink>
                        </li>
                    )}

                    {auth.userCan("showAlarms") && (
                        <li className="menu-item-has-children">
                            <span
                                className="menu-item"
                                onClick={() => dropdownMenu("ul-alarms")}
                            >
                                <span className="menu-icon">
                                    <img
                                        src="/assets/pic/icon-alarms.svg"
                                        alt=""
                                    />
                                </span>
                                <span className="menu-dropdown">
                                    <img
                                        src="/assets/pic/icon-dropdown.svg"
                                        alt=""
                                    />
                                </span>
                                <span className="menu-name">Alerts</span>
                            </span>
                            <ul
                                className="menu-lvl-2"
                                id="ul-alarms"
                            >
                                <li>
                                    <NavLink
                                        exact
                                        to={"/alarms"}
                                        className="menu-item"
                                        activeClassName={"current"}
                                    >
                                        <span className="menu-icon">
                                            <img
                                                src="/assets/pic/icon-arrow-right.svg"
                                                alt=""
                                            />
                                            <img
                                                src="/assets/pic/icon-alarms.svg"
                                                alt=""
                                            />
                                        </span>
                                        {countActiveAlarms > 0 && (
                                            <span className="menu-count bg-danger color-white-c">
                                                <span>{countActiveAlarms}</span>
                                            </span>
                                        )}
                                        <span className="menu-name">Active</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        exact
                                        to={"/alarms/history"}
                                        className="menu-item"
                                        activeClassName={"current"}
                                    >
                                        <span className="menu-icon">
                                            <img
                                                src="/assets/pic/icon-arrow-right.svg"
                                                alt=""
                                            />
                                            <img
                                                src="/assets/pic/icon-history-solid.svg"
                                                alt=""
                                            />
                                        </span>
                                        <span className="menu-name">History</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        exact
                                        to={"/alarms/default"}
                                        className="menu-item"
                                        activeClassName={"current"}
                                    >
                                        <span className="menu-icon">
                                            <img
                                                src="/assets/pic/icon-arrow-right.svg"
                                                alt=""
                                            />
                                            <img
                                                src="/assets/pic/icon-clipboard-list-solid.svg"
                                                alt=""
                                            />
                                        </span>
                                        <span className="menu-name">Default Levels</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        exact
                                        to={"/alarms/log"}
                                        className="menu-item"
                                        activeClassName={"current"}
                                    >
                                        <span className="menu-icon">
                                            <img
                                                src="/assets/pic/icon-arrow-right.svg"
                                                alt=""
                                            />
                                            <img
                                                src="/assets/pic/icon-clipboard-list-solid.svg"
                                                alt=""
                                            />
                                        </span>
                                        <span className="menu-name">Alert Change Log</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                    )}
                    <li>
                        <NavLink
                            exact
                            to={"/sensor-intervals"}
                            className="menu-item"
                            activeClassName={"current"}
                        >
                            <span className="menu-icon">
                                <img
                                    src="/assets/pic/settings.svg"
                                    alt=""
                                />
                            </span>
                            <span className="menu-name">Sensor Intervals</span>
                        </NavLink>
                    </li>

                    {auth.userCan("manageSubscriptions") && (
                        <li className="menu-item-has-children">
                            <span
                                className="menu-item"
                                onClick={() => dropdownMenu("ul-subscriptions")}
                            >
                                <span className="menu-icon">
                                    <img
                                        src="/assets/pic/icon-clipboard-list-solid.svg"
                                        alt=""
                                    />
                                </span>
                                <span className="menu-dropdown">
                                    <img
                                        src="/assets/pic/icon-dropdown.svg"
                                        alt=""
                                    />
                                </span>
                                <span className="menu-name">Subscriptions</span>
                            </span>
                            <ul
                                className="menu-lvl-2"
                                id="ul-subscriptions"
                            >
                                <li>
                                    <NavLink
                                        exact
                                        to="/alert-groups"
                                        className="menu-item"
                                        activeClassName="current"
                                    >
                                        <span className="menu-icon">
                                            <img
                                                src="/assets/pic/icon-arrow-right.svg"
                                                alt=""
                                            />
                                            <img
                                                src="/assets/pic/icon-clipboard-list-solid.svg"
                                                alt=""
                                            />
                                        </span>
                                        <span className="menu-name">Alert Groups</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        exact
                                        to="/device-alert-groups"
                                        className="menu-item"
                                        activeClassName="current"
                                    >
                                        <span className="menu-icon">
                                            <img
                                                src="/assets/pic/icon-arrow-right.svg"
                                                alt=""
                                            />
                                            <img
                                                src="/assets/pic/icon-clipboard-list-solid.svg"
                                                alt=""
                                            />
                                        </span>
                                        <span className="menu-name">Device Alert Groups</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        exact
                                        to="/user-subscriptions"
                                        className="menu-item"
                                        activeClassName="current"
                                    >
                                        <span className="menu-icon">
                                            <img
                                                src="/assets/pic/icon-arrow-right.svg"
                                                alt=""
                                            />
                                            <img
                                                src="/assets/pic/icon-clipboard-list-solid.svg"
                                                alt=""
                                            />
                                        </span>
                                        <span className="menu-name">User Subscriptions</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                    )}
                    <li>
                        <NavLink
                            exact
                            to={"/notes"}
                            className="menu-item"
                            activeClassName={"current"}
                        >
                            <span className="menu-icon">
                                <img
                                    src="/assets/pic/icon-note.svg"
                                    alt=""
                                />
                            </span>
                            {countGlobalNotes > 0 && (
                                <span className="menu-count badge badge-success">
                                    <span>{countGlobalNotes}</span>
                                </span>
                            )}
                            <span className="menu-name">Notes</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            exact
                            to={"/locations"}
                            className="menu-item"
                            activeClassName={"current"}
                        >
                            <span className="menu-icon">
                                <img
                                    src="/assets/pic/icon-locations.svg"
                                    alt=""
                                />
                            </span>
                            <span className="menu-name">Asset Tree</span>
                        </NavLink>
                    </li>
                    <li className="menu-item-has-children">
                        <span
                            className="menu-item"
                            onClick={() => dropdownMenu("ul-network")}
                        >
                            <span className="menu-icon">
                                <img
                                    src="/assets/pic/icon-network.svg"
                                    alt=""
                                />
                            </span>
                            <span className="menu-dropdown">
                                <img
                                    src="/assets/pic/icon-dropdown.svg"
                                    alt=""
                                />
                            </span>
                            <span className="menu-name">Network</span>
                        </span>
                        <ul
                            className="menu-lvl-2"
                            id="ul-network"
                        >
                            <li>
                                <NavLink
                                    exact
                                    to="/network/action-items"
                                    className="menu-item"
                                    activeClassName="current"
                                >
                                    <span className="menu-icon">
                                        <img
                                            src="/assets/pic/icon-arrow-right.svg"
                                            alt=""
                                        />
                                        <img
                                            src="/assets/pic/icon-wifi-solid.svg"
                                            alt=""
                                        />
                                    </span>
                                    <span className="menu-name">Action Items</span>
                                    {countActionItems > 0 && (
                                        <span className="menu-count bg-danger color-white-c">
                                            <span>{countActionItems}</span>
                                        </span>
                                    )}
                                </NavLink>
                            </li>
                            {auth.userCan("showServiceNotifications") && (
                                <li>
                                    <NavLink
                                        exact
                                        to="/network/event-log"
                                        className="menu-item"
                                        activeClassName="current"
                                    >
                                        <span className="menu-icon">
                                            <img
                                                src="/assets/pic/icon-arrow-right.svg"
                                                alt=""
                                            />
                                            <img
                                                src="/assets/pic/icon-wifi-solid.svg"
                                                alt=""
                                            />
                                        </span>
                                        {countActiveDeviceAlerts.total > 0 && (
                                            <span className="menu-count bg-danger color-white-c">
                                                <span>{countActiveDeviceAlerts.total}</span>
                                            </span>
                                        )}
                                        <span className="menu-name">Event Log</span>
                                    </NavLink>
                                </li>
                            )}
                            <li>
                                <NavLink
                                    exact
                                    to={"/network/gateways"}
                                    className="menu-item"
                                    activeClassName={"current"}
                                >
                                    <span className="menu-icon">
                                        <img
                                            src="/assets/pic/icon-arrow-right.svg"
                                            alt=""
                                        />
                                        <img
                                            src="/assets/pic/icon-wifi-solid.svg"
                                            alt=""
                                        />
                                    </span>
                                    <span className="menu-name">Gateways</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    exact
                                    to={"/network/routers"}
                                    className="menu-item"
                                    activeClassName={"current"}
                                >
                                    <span className="menu-icon">
                                        <img
                                            src="/assets/pic/icon-arrow-right.svg"
                                            alt=""
                                        />
                                        <img
                                            src="/assets/pic/icon-project-diagram-solid.svg"
                                            alt=""
                                        />
                                    </span>
                                    <span className="menu-name">Repeaters</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    exact
                                    to={"/network/nodes"}
                                    className="menu-item"
                                    activeClassName={"current"}
                                >
                                    <span className="menu-icon">
                                        <img
                                            src="/assets/pic/icon-arrow-right.svg"
                                            alt=""
                                        />
                                        <img
                                            src="/assets/pic/icon-th-large-solid.svg"
                                            alt=""
                                        />
                                    </span>
                                    <span className="menu-name">Nodes</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    exact
                                    to={"/network/sensors"}
                                    className="menu-item"
                                    activeClassName={"current"}
                                >
                                    <span className="menu-icon">
                                        <img
                                            src="/assets/pic/icon-arrow-right.svg"
                                            alt=""
                                        />
                                        <img
                                            src="/assets/pic/icon-dot-circle-regular.svg"
                                            alt=""
                                        />
                                    </span>
                                    <span className="menu-name">Tethered Sensors</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    exact
                                    to={"/network/motes"}
                                    className="menu-item"
                                    activeClassName={"current"}
                                >
                                    <span className="menu-icon">
                                        <img
                                            src="/assets/pic/icon-arrow-right.svg"
                                            alt=""
                                        />
                                        <img
                                            src="/assets/pic/icon-dot-circle-regular.svg"
                                            alt=""
                                        />
                                    </span>
                                    <span className="menu-name">Sensor Motes</span>
                                </NavLink>
                            </li>
                            {auth.userCan("manageWiFiGroup") && (
                                <li>
                                    <NavLink
                                        exact
                                        to={"/network/wifi-groups"}
                                        className="menu-item"
                                        activeClassName={"current"}
                                    >
                                        <span className="menu-icon">
                                            <img
                                                src="/assets/pic/icon-arrow-right.svg"
                                                alt=""
                                            />
                                            <img
                                                src="/assets/pic/icon-dot-circle-regular.svg"
                                                alt=""
                                            />
                                        </span>
                                        <span className="menu-name">Wifi Mote Groups</span>
                                    </NavLink>
                                </li>
                            )}
                            {auth.userCan("manageAdapter") && (
                                <li className="menu-item-has-children">
                                    <span
                                        className="menu-item"
                                        onClick={() => dropdownMenu("ul-adapter")}
                                    >
                                        <span className="menu-icon">
                                            <img
                                                src="/assets/pic/icon-arrow-right.svg"
                                                alt=""
                                            />
                                            <img
                                                src="/assets/pic/icon-dot-circle-regular.svg"
                                                alt=""
                                            />
                                        </span>
                                        <span className="menu-name">Universal Adapters</span>
                                    </span>
                                    <ul
                                        className="menu-lvl-2"
                                        id="ul-adapter"
                                    >
                                        <li>
                                            <NavLink
                                                exact
                                                to={"/network/ua-configurations"}
                                                className="menu-item d-flex"
                                                activeClassName={"current"}
                                            >
                                                <span className="menu-icon">
                                                    <img
                                                        src="/assets/pic/icon-arrow-right.svg"
                                                        alt=""
                                                    />
                                                    <img
                                                        src="/assets/pic/icon-dot-circle-regular.svg"
                                                        alt=""
                                                    />
                                                </span>
                                                <span className="menu-name">Manage Configurations</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                exact
                                                to={"/network/wua-custom-sensor"}
                                                className="menu-item"
                                                activeClassName={"current"}
                                            >
                                                <span className="menu-icon">
                                                    <img
                                                        src="/assets/pic/icon-arrow-right.svg"
                                                        alt=""
                                                    />
                                                    <img
                                                        src="/assets/pic/icon-dot-circle-regular.svg"
                                                        alt=""
                                                    />
                                                </span>
                                                <span className="menu-name">Associate</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                            )}
                            <li>
                                <NavLink
                                    exact
                                    to={"/network/tachometers"}
                                    className="menu-item"
                                    activeClassName={"current"}
                                >
                                    <span className="menu-icon">
                                        <img
                                            src="/assets/pic/icon-arrow-right.svg"
                                            alt=""
                                        />
                                        <img
                                            src="/assets/pic/icon-tachometer-alt-solid.svg"
                                            alt=""
                                        />
                                    </span>
                                    <span className="menu-name">Tachometers</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    exact
                                    to={"/firmware-updates"}
                                    className="menu-item"
                                    activeClassName={"current"}
                                >
                                    <span className="menu-icon">
                                        <img
                                            src="/assets/pic/icon-arrow-right.svg"
                                            alt=""
                                        />
                                    </span>
                                    <span className="menu-name">Firmware Updates</span>
                                </NavLink>
                            </li>
                        </ul>
                    </li>
                    {auth.userCan("manageBLEDevicesAndRoutes") && (
                        <li>
                            <NavLink
                                exact
                                to={"/routes"}
                                className="menu-item"
                                activeClassName={"current"}
                            >
                                <span className="menu-icon">
                                    <img
                                        src="/assets/pic/icon-router.svg"
                                        alt=""
                                    />
                                </span>
                                <span className="menu-name">Routes</span>
                            </NavLink>
                        </li>
                    )}
                    <li>
                        <NavLink
                            exact
                            to={"/nameplates"}
                            className="menu-item"
                            activeClassName={"current"}
                        >
                            <span className="menu-icon">
                                <img
                                    src="/assets/pic/icon-clipboard-list-solid.svg"
                                    alt=""
                                />
                            </span>
                            <span className="menu-name">Nameplates</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            exact
                            to={"/users"}
                            className="menu-item"
                            activeClassName={"current"}
                        >
                            <span className="menu-icon">
                                <img
                                    src="/assets/pic/icon-users.svg"
                                    alt=""
                                />
                            </span>
                            <span className="menu-name">Users</span>
                        </NavLink>
                    </li>
                    <li className="hide-link">
                        <NavLink
                            exact
                            to={"/profile"}
                            className="menu-item"
                            activeClassName={"current"}
                        >
                            <span className="menu-icon">
                                <i className="fa fa-user" />
                            </span>
                            <span className="menu-name">My Profile</span>
                        </NavLink>
                    </li>
                    <li className="hide-link">
                        <a
                            href="#"
                            onClick={() => auth.logout()}
                            className="menu-item"
                        >
                            <span className="menu-icon">
                                <i className="fa fa-sign-out-alt" />
                            </span>
                            <span className="menu-name">Sign Out</span>
                        </a>
                    </li>
                </ul>
            </div>

            <div className="left-user-link">
                <div
                    className="user-dropdown"
                    onClick={() => dropdownUser()}
                >
                    <NavLink
                        exact
                        to={"/profile"}
                        className="menu-item"
                        activeClassName={"current"}
                    >
                        <i className="fa fa-user" />
                        <span>My Profile</span>
                    </NavLink>
                    <div
                        className="menu-item fake-link"
                        onClick={triggerDarkMode}
                    >
                        <i className="fa fa-adjust" />
                        <span>{state.lightMode === "true" ? "Light Mode" : "Dark Mode"}</span>
                    </div>
                    {auth.allowedCustomers.length > 1 && (
                        <a
                            href="#"
                            onClick={() => auth.customerLogout()}
                            className="menu-item"
                        >
                            <i className="fa fa-sign-out-alt" />
                            <span>Switch Customer</span>
                        </a>
                    )}
                    <a
                        href="#"
                        onClick={() => auth.logout()}
                        className="menu-item"
                    >
                        <i className="fa fa-sign-out-alt" />
                        <span>Sign Out</span>
                    </a>
                </div>
                <div
                    className="user-link"
                    onClick={() => dropdownUser()}
                >
                    <img
                        src="/assets/pic/icon-user.svg"
                        alt=""
                    />
                    <span>{props.user.full_name}</span>
                    <img
                        src="/assets/pic/icon-dropdown.svg"
                        alt=""
                    />
                </div>
            </div>
        </aside>
    );
};

Menu.propTypes = {
    history: PropTypes.object,
    toggleLeftSide: PropTypes.object,
    auth: PropTypes.object,
    user: PropTypes.object,
    countActiveDeviceAlerts: PropTypes.object,
    countActiveAlarms: PropTypes.number,
    countActiveNews: PropTypes.any,
    updateCountActiveDeviceAlerts: PropTypes.func,
    updateCountActiveAlarms: PropTypes.func,
    updateCountActiveNews: PropTypes.func,
    countGlobalNotes: PropTypes.number,
    updateCountGlobalNotes: PropTypes.func,
    countActionItems: PropTypes.number,
    updateCountActionItems: PropTypes.func,

    // LocationSelectStore
    getLocationId: PropTypes.func,
};

export default withLocationSelectStore(withGlobalStore(withRouter(Menu)));
