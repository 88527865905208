import React, {Component} from "react";
import PropTypes from "prop-types";
import NameSelect from "./name";
import {get as _get, includes as _includes, filter as _filter, indexOf as _indexOf, set as _set} from "lodash";
import Select from "react-select";
import {ValidationError} from "../../../../shared";

const darkModeSelectStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: "rgb(56, 56, 46)",
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: "#777",
        color: "#ccc",
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: "#ccc",
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: "#36342a",
    }),
    option: (styles, {isFocused}) => {
        return {
            ...styles,
            backgroundColor: isFocused ? "#777" : null,
        };
    },
};

const isDarkTheme = localStorage.getItem("lightMode");

class BearingForm extends Component {
    constructor(props) {
        super(props);
    }

    onChangeInstallationPoint = (value, event) => {
        const {index, onChange} = this.props;
        let profile = {...this.props.profile};
        let bearingData = _get(profile, "bearings." + index);
        let installationPointsIndexes = _get(bearingData, "installationPointsIndexes", []);
        let ids = _get(bearingData, "ids", []);

        if (event.action === "select-option") {
            installationPointsIndexes.push(+_get(event, "option.value"));
        }
        if (event.action === "remove-value") {
            const deleteIndex = _indexOf(installationPointsIndexes, +_get(event, "option.value"));
            installationPointsIndexes.splice(deleteIndex, 1);
            ids.splice(deleteIndex, 1);
        }

        _set(bearingData, "installationPointsIndexes", installationPointsIndexes);
        _set(bearingData, "ids", ids);

        onChange({
            target: {
                name: "bearings." + index,
                value: bearingData,
            },
        });
    };

    checkIndexIsDisabled = (index) => {
        const {profile} = this.props;
        const bearings = _get(profile, "bearings", []);

        let usedCount = 0;
        bearings.map((bearing) => {
            if (_includes(_get(bearing, "installationPointsIndexes", []), +index)) {
                usedCount++;
            }
        });

        return usedCount >= 3;
    };

    getErrorMsg = (name) => {
        const {formErrors} = this.props;

        return _get(formErrors, name);
    };

    getValidationClass = (name) => {
        const {profile, index, inProgress} = this.props;

        if (
            _get(profile, "bearings." + index + ".bearing.id") &&
            name !== "bearings." + index + ".installationPointsIndexes" &&
            name !== "bearings." + index + ".bearing.plot_lines_count" &&
            name !== "bearings." + index + ".bearing.name"
        )
            return "";

        const errorMsg = this.getErrorMsg(name);
        let value = _get(profile, name);

        if (Array.isArray(value)) {
            value = value.length;
        }

        let validationClass = "";
        if (errorMsg) {
            validationClass = " is-invalid";
        } else if (value && !inProgress) {
            validationClass = " valid";
        }

        return validationClass;
    };

    render() {
        const {index, profile, removeBearing, defaultBearings, fetchBearings, onChange, installationPoints, validateStep} = this.props;

        const options = installationPoints.map((point, index) => {
            return {label: _get(point, "name"), value: index, disabled: this.checkIndexIsDisabled(index)};
        });

        const value = _filter(options, (option) => {
            if (_includes(_get(profile, "bearings." + index + ".installationPointsIndexes", []), +_get(option, "value"))) {
                return option;
            }
        });
        return (
            <React.Fragment>
                <div className={"bearing-wrapper"}>
                    <div className={"form-group"}>
                        <label className={"form-label"}>
                            Installation Points <span className="color-danger">*</span>
                        </label>
                        <Select
                            classNamePrefix="bearing-select"
                            className={"form-control-select " + this.getValidationClass("bearings." + index + ".installationPointsIndexes")}
                            isMulti
                            placehoder={false}
                            isSearchable={false}
                            isClearable={false}
                            styles={isDarkTheme === "true" ? darkModeSelectStyles : {}}
                            value={value}
                            options={options}
                            onChange={this.onChangeInstallationPoint}
                            isOptionDisabled={(option) => option.disabled === true}
                            onBlur={validateStep}
                        />
                        <ValidationError message={this.getErrorMsg("bearings." + index + ".installationPointsIndexes")} />
                    </div>
                    <div className={"form-group form-control-select"}>
                        <label className={"form-label"}>
                            Name <span className="color-danger">*</span>
                        </label>
                        <NameSelect
                            className={!_get(profile, ["bearings" , index , "bearing", "id"]) && this.getValidationClass("bearings." + index + ".bearing.name")}
                            defaultBearings={defaultBearings}
                            fetchBearings={fetchBearings}
                            onChange={onChange}
                            bearing={_get(profile, "bearings." + index + ".bearing")}
                            index={index}
                            onBlur={validateStep}
                        />
                        {!_get(profile, ["bearings" , index , "bearing", "id"]) && (
                            <ValidationError message={this.getErrorMsg("bearings." + index + ".bearing.name")} />
                        )}
                    </div>
                    <span>To view more bearings, perform a search.</span>
                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            <div className={"form-group"}>
                                <label className={"form-label"}>
                                    Manufacturer <span className="color-danger">*</span>
                                </label>
                                <input
                                    className={"form-control " + this.getValidationClass("bearings." + index + ".bearing.mfr")}
                                    name={"bearings." + index + ".bearing.mfr"}
                                    value={_get(profile, "bearings." + index + ".bearing.mfr")}
                                    onChange={onChange}
                                    readOnly={!!_get(profile, "bearings." + index + ".bearing.id")}
                                    onBlur={validateStep}
                                />
                                <ValidationError message={this.getErrorMsg("bearings." + index + ".bearing.mfr")} />
                            </div>
                        </div>
                        <div className={"col-md-6"}>
                            <div className={"form-group"}>
                                <label className={"form-label"}>
                                    Balls/Rollers <span className="color-danger">*</span>
                                </label>
                                <input
                                    className={"form-control " + this.getValidationClass("bearings." + index + ".bearing.balls_rollers")}
                                    name={"bearings." + index + ".bearing.balls_rollers"}
                                    value={_get(profile, "bearings." + index + ".bearing.balls_rollers")}
                                    readOnly={!!_get(profile, "bearings." + index + ".bearing.id")}
                                    onChange={onChange}
                                    onBlur={validateStep}
                                />
                                <ValidationError message={this.getErrorMsg("bearings." + index + ".bearing.balls_rollers")} />
                            </div>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            <div className={"form-group"}>
                                <label className={"form-label"}>
                                    FTF <span className="color-danger">*</span>
                                </label>
                                <input
                                    className={"form-control " + this.getValidationClass("bearings." + index + ".bearing.ftf")}
                                    name={"bearings." + index + ".bearing.ftf"}
                                    value={_get(profile, "bearings." + index + ".bearing.ftf")}
                                    readOnly={!!_get(profile, "bearings." + index + ".bearing.id")}
                                    onChange={onChange}
                                    onBlur={validateStep}
                                />
                                <ValidationError message={this.getErrorMsg("bearings." + index + ".bearing.ftf")} />
                            </div>
                        </div>
                        <div className={"col-md-6"}>
                            <div className={"form-group"}>
                                <label className={"form-label"}>
                                    BSF <span className="color-danger">*</span>
                                </label>
                                <input
                                    className={"form-control " + this.getValidationClass("bearings." + index + ".bearing.bsf")}
                                    name={"bearings." + index + ".bearing.bsf"}
                                    value={_get(profile, "bearings." + index + ".bearing.bsf")}
                                    readOnly={!!_get(profile, "bearings." + index + ".bearing.id")}
                                    onChange={onChange}
                                    onBlur={validateStep}
                                />
                                <ValidationError message={this.getErrorMsg("bearings." + index + ".bearing.bsf")} />
                            </div>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-md-6"}>
                            <div className={"form-group"}>
                                <label className={"form-label"}>
                                    BPFO <span className="color-danger">*</span>
                                </label>
                                <input
                                    className={"form-control " + this.getValidationClass("bearings." + index + ".bearing.bpfo")}
                                    name={"bearings." + index + ".bearing.bpfo"}
                                    value={_get(profile, "bearings." + index + ".bearing.bpfo")}
                                    readOnly={!!_get(profile, "bearings." + index + ".bearing.id")}
                                    onChange={onChange}
                                    onBlur={validateStep}
                                />
                                <ValidationError message={this.getErrorMsg("bearings." + index + ".bearing.bpfo")} />
                            </div>
                        </div>
                        <div className={"col-md-6"}>
                            <div className={"form-group"}>
                                <label className={"form-label"}>
                                    BPFI <span className="color-danger">*</span>
                                </label>
                                <input
                                    className={"form-control " + this.getValidationClass("bearings." + index + ".bearing.bpfi")}
                                    name={"bearings." + index + ".bearing.bpfi"}
                                    value={_get(profile, "bearings." + index + ".bearing.bpfi")}
                                    readOnly={!!_get(profile, "bearings." + index + ".bearing.id")}
                                    onChange={onChange}
                                    onBlur={validateStep}
                                />
                                <ValidationError message={this.getErrorMsg("bearings." + index + ".bearing.bpfi")} />
                            </div>
                        </div>
                    </div>

                    <div className={"form-group"}>
                        <label className={"form-label"}>
                            Plot Lines <span className="color-danger">*</span>
                        </label>
                        <input
                            className={"form-control " + this.getValidationClass("bearings." + index + ".bearing.plot_lines_count")}
                            name={"bearings." + index + ".bearing.plot_lines_count"}
                            value={_get(profile, "bearings." + index + ".bearing.plot_lines_count")}
                            onChange={onChange}
                            onBlur={validateStep}
                        />
                        <ValidationError message={this.getErrorMsg("bearings." + index + ".bearing.plot_lines_count")} />
                    </div>

                    <span
                        className={"remove"}
                        onClick={() => removeBearing(index)}
                    >
                        <i className="fas fa-times" />
                    </span>
                </div>
            </React.Fragment>
        );
    }
}

BearingForm.propTypes = {
    profile: PropTypes.object,
    installationPoints: PropTypes.array,
    index: PropTypes.number,
    removeBearing: PropTypes.func,
    defaultBearings: PropTypes.array,
    fetchBearings: PropTypes.func,
    onChange: PropTypes.func,
    formErrors: PropTypes.object,
    validateStep: PropTypes.func,
    inProgress: PropTypes.bool,
};

export default BearingForm;
