import React, {Component} from "react";
import PropTypes from "prop-types";
import {withGlobalStore} from "../../stores/GlobalStore";
import {Modal} from "../../shared";
import SettingsForm from "../shared/intervals/SettingsForm";
import {get as _get, each as _each} from "lodash";
import SettingsDataHandler from "../shared/intervals/SettingsDataHandler";
import SweetAlert from "react-bootstrap-sweetalert";

const BOOLEAN_SENSOR_TYPE = 18;

class BachIntervalsModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showBatchConfirm: false,
        };
    }
    render() {
        const {showBatchConfirm} = this.state;
        const {installationPoint, equipment, onClose, updateSettings} = this.props;

        const versionTypesName = {};
        _each(equipment.installationPoints, (point) => {
            versionTypesName[_get(point, "sensor.version_type")] = _get(point, "sensor.version_name");
        });

        return (
            <SettingsDataHandler
                installationPoint={installationPoint}
                equipment={equipment}
                updateSettings={updateSettings}
                afterSubmit={() =>
                    new Promise((resolve) => {
                        onClose();
                        return resolve();
                    })
                }
            >
                {(props) => {
                    const {
                        settings,
                        showNotice,
                        formErrors,
                        validation,
                        inProgress,
                        currentVersionType,
                        onChange,
                        onSave,
                        isImpactVueEnabled,
                        getNodesSerials,
                        isBatchSettings,
                        setCurrentVersionType,
                        getCurrentModelSpec,
                        getCurrentSensorParameters,
                    } = props;

                    return (
                        <Modal
                            showModal={true}
                            size="xl"
                            title={"SET INTERVALS"}
                            onClose={onClose}
                            onSubmit={() => {
                                isBatchSettings() ? this.setState({showBatchConfirm: true}) : onSave();
                            }}
                            inProgress={inProgress}
                        >
                            {isBatchSettings() && Object.keys(settings).length ? (
                                <React.Fragment>
                                    {Object.keys(settings).length > 1 && (
                                        <div className="axis-block-container col-12 mb-4">
                                            {Object.keys(settings)
                                                .filter((item) => +item !== BOOLEAN_SENSOR_TYPE)
                                                .map((versionType) => (
                                                    <div
                                                        key={versionType}
                                                        className={`axis-block ${+versionType === +currentVersionType && "active"}`}
                                                        onClick={() => setCurrentVersionType(versionType)}
                                                    >
                                                        <span>{_get(versionTypesName, +versionType, versionType + " sensor version")} settings</span>
                                                    </div>
                                                ))}
                                        </div>
                                    )}
                                    {Object.keys(settings).map((versionType) => (
                                        <React.Fragment key={versionType}>
                                            {+currentVersionType === +versionType && (
                                                <SettingsForm
                                                    settings={_get(settings, versionType)}
                                                    specs={getCurrentModelSpec()}
                                                    sensorParameters={getCurrentSensorParameters()}
                                                    onChange={onChange}
                                                    showNotice={showNotice}
                                                    formErrors={_get(formErrors, "" + currentVersionType)}
                                                    versionType={+versionType}
                                                    isImpactVueEnabled={isImpactVueEnabled()}
                                                    nodeSerials={getNodesSerials()}
                                                />
                                            )}
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            ) : (
                                <SettingsForm
                                    installationPoint={installationPoint}
                                    settings={settings}
                                    specs={_get(installationPoint, "modelSpec", {})}
                                    sensorParameters={_get(installationPoint, "sensorParameters", {})}
                                    onChange={onChange}
                                    showNotice={showNotice}
                                    formErrors={formErrors}
                                    versionType={+_get(installationPoint, "sensor.version_type", 1)}
                                    isImpactVueEnabled={isImpactVueEnabled()}
                                    nodeSerials={getNodesSerials()}
                                    validation={validation}
                                />
                            )}
                            {showBatchConfirm && (
                                <SweetAlert
                                    warning
                                    showCancel
                                    confirmBtnText="Yes"
                                    cancelBtnBsStyle="default"
                                    btnSize="xs"
                                    title="BATCH UPDATE"
                                    onConfirm={() => this.setState({showBatchConfirm: false}, () => onSave())}
                                    onCancel={() => this.setState({showBatchConfirm: false})}
                                >
                                    This update will apply changes to intervals, RMS High-Pass filter, FMax, and LoR for all sensors on this equipment. Are
                                    you sure you want to continue?
                                </SweetAlert>
                            )}
                        </Modal>
                    );
                }}
            </SettingsDataHandler>
        );
    }
}

BachIntervalsModal.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    auth: PropTypes.object,
    installationPoint: PropTypes.object,
    onClose: PropTypes.func,
    updateSettings: PropTypes.func,
    equipment: PropTypes.object,
};

export default withGlobalStore(BachIntervalsModal);
