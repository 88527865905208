import React, {Component} from "react";
import PropTypes from "prop-types";
import SearchInput from "../../shared/search-input";
import {Loader} from "../../../shared";
import Table from "./table";
import ActionListApi from "../../../api/actionList";
import {get as _get, map as _map, set as _set, filter as _filter, includes as _includes, orderBy as _orderBy, each as _each, find as _find} from "lodash";
import FacilityApi from "../../../api/facility";
import Location from "../../../api/location";
import {withGlobalStore} from "../../../stores/GlobalStore";
import ActionItemModal from "./modal";
import Helper from "../../../helpers/helper";
import {HeaderSimple} from "../../../shared/header";
import CollapseLocationSelect from "../../../shared/collapseLocationSelect/collapseLocationSelect";
import {withLocationSelectStore} from "../../../stores/LocationSelectStore";
import ActionItemAddModal from "./components/ActionItemAddModal/ActionItemAddModal";
import ActionItemSelect from "./components/ActionItemSelect/ActionItemSelect";

const hardware = {
    sensor: "Tethered sensor",
    node: "Node",
    router: "Repeater",
    gateway: "Gateway",
    mote: "Sensor Mote",
};

class ActionItems extends Component {
    constructor(props) {
        super(props);

        const isArchive = window.location.pathname === "/network/archive-action-items";

        const showActionItemId = Helper.getHashParams().id;

        this.state = {
            loader: true,
            list: [],
            filteredList: [],
            info: {
                action_list_states: {},
                action_list_urgencies: {},
            },
            facilities: {},
            locations: {},
            parametersList: [],
            sort: {
                field: "",
                sort: "asc",
            },
            filter: {
                query: "",
                urgency: "",
                hardware: "",
                facility: "",
                location: "",
                status: "",
            },
            globalSearchString: "",
            isArchive: isArchive,
            showActionItemId: showActionItemId ? showActionItemId : null,
            selectedLocationId: props.getLocationId(),
            showAddActionItem: false,
        };
    }

    componentDidMount() {
        Promise.all([this.fetchFacilities(), this.fetchLocations(), this.handleFetch(), this.fetchInfo()]).then(() =>
            this.setState({loader: false, list: this.transformList(this.state.list)})
        );
    }

    handleArchive = () => {
        const {isArchive} = this.state;
        const {history} = this.props;

        if (!isArchive) {
            history.push("/network/archive-action-items");
        } else {
            history.push("/network/action-items");
        }

        this.setState({isArchive: !isArchive, loader: true}, this.handleFetch);
    };

    handleFetch = (withReset = true) => {
        const {isArchive, selectedLocationId} = this.state;
        return isArchive ? this.getArchiveList(withReset, selectedLocationId) : this.getMainList(withReset, selectedLocationId);
    };

    getMainList = (withReset, location_id = null) => {
        this.controller = new window.AbortController();
        this.signal = this.controller.signal;

        const {locations} = this.state;
        let facility_id = null;
        if (location_id) {
            facility_id = _get(_find(locations, {id: +location_id}), "facility_id");
        }

        if(+location_id === -2) {
            location_id = 0;
        }

        return ActionListApi.mainList({facility_id, location_id}).then((response) => {
            if (response) {
                const {list} = response;
                this.setState({list: this.transformList(list)}, () => {
                    withReset ? this.resetState() : this.handleFilter();
                });
            }
        });
    };

    getArchiveList = (withReset = true, location_id = null) => {
        this.controller = new window.AbortController();
        this.signal = this.controller.signal;

        const {locations} = this.state;
        let facility_id = null;
        if (location_id) {
            facility_id = _get(_find(locations, {id: +location_id}), "facility_id");
        }

        return ActionListApi.getArchiveActionItems({facility_id, location_id}).then((response) => {
            if (response) {
                const {items} = response;
                this.setState({list: this.transformList(items)}, () => {
                    withReset ? this.resetState() : this.handleFilter();
                });
            }
        });
    };

    resetState = () => {
        this.setState({
            loader: false,
            isArchive: this.state.isArchive,
            sort: {
                field: "",
                sort: "asc",
            },
            filter: {
                query: "",
                urgency: "",
                hardware: "",
                facility: "",
                location: "",
                status: "",
            },
        });
    };

    transformList = (list) => {
        const {facilities} = this.state;
        let actionItems = [...list];

        actionItems.map((actionItem, index) => {
            const aiInfo = {};

            const type = _get(actionItem, "equipment_type", "");
            if (["gateway", "node", "router"].indexOf(type) !== -1) {
                aiInfo.locationName = _get(actionItem, "location_info") || _get(actionItem, type + ".location_info") || "Unassigned";
                aiInfo.equipmentName = _get(actionItem, "equipment_info");
                if (type === "gateway") aiInfo.serial = _get(actionItem, "gateway.serial", "-");
                else if (type === "node") aiInfo.serial = _get(actionItem, "node.serial", "-");
                else aiInfo.serial = _get(actionItem, "router.serial", "-");
            } else {
                let sensorHex = _get(actionItem, "sensor_id");
                const sensorHardwareId = _get(actionItem, "installation_point.sensor.hardware_id");
                if (parseInt(sensorHardwareId)) {
                    sensorHex = parseInt(sensorHardwareId).toString(16).toUpperCase();
                }
                aiInfo.locationName = _get(actionItem, "installation_point.equipment.location.name", "Unassigned");
                aiInfo.equipmentName = _get(actionItem, "installation_point.equipment.name", "-");
                aiInfo.serial = sensorHex;
            }
            _set(actionItems, index + ".facilityName", _get(facilities, _get(actionItem, "facility_id")));
            _set(actionItems, index + ".hardware", _get(hardware, type));
            _set(actionItems, index + ".locationName", _get(aiInfo, "locationName"));
            _set(actionItems, index + ".equipmentName", _get(aiInfo, "equipmentName"));
            _set(actionItems, index + ".deviceSerial", _get(aiInfo, "serial"));
        });

        return actionItems;
    };

    fetchInfo = () => {
        return ActionListApi.getInfo().then((response) => {
            if (response) {
                const info = {
                    action_list_states: _get(response, "action_list_states", {}),
                    action_list_urgencies: _get(response, "action_list_urgencies", {}),
                    action_list_no_actions_groups: _get(response, "action_list_no_actions_groups", {}),
                    ww_action_list_reason_groups: _get(response, "ww_action_list_reason_groups", {}),
                };
                this.setState({info});
            }
        });
    };

    fetchFacilities = () => {
        return FacilityApi.list().then((response) => {
            if (response) {
                const {list} = response;
                this.setState({
                    facilities: this.transformFacilities(list || []),
                });
            }
        });
    };

    fetchLocations = () => {
        return Location.list().then((response) => {
            if (response) {
                const {list} = response;
                this.setState({
                    locations: Helper.transformLocations(list || []),
                });
            }
        });
    };

    transformFacilities = (list) => {
        let facilities = {};

        _map(list, (facility) => {
            facilities[_get(facility, "facility_id")] = _get(facility, "name");
        });

        return facilities;
    };

    handleChangeGlobalSearch = (globalSearchString) => {
        this.setState({globalSearchString: globalSearchString});
    };

    handleSortChange = (field = "") => {
        const {sort} = this.state;

        this.setState(
            {
                sort: Object.assign(
                    {},
                    {
                        field,
                        sort: field && field === sort.field ? (sort.sort === "asc" ? "desc" : "asc") : "asc",
                    }
                ),
            },
            this.handleFilter
        );
    };

    handleFilterChange = (obj = {}) => {
        this.setState(
            {
                filter: Object.assign({}, this.state.filter, obj),
            },
            this.handleFilter
        );
    };

    searchChange = (value) => {
        this.handleFilterChange({query: value});
    };

    handleFilter = () => {
        const {filter, list, sort} = this.state;
        let filteredList = list;

        const query = _get(filter, "query");
        if (query) {
            filteredList = this.handleSearch(query);
        }

        const urgency = _get(filter, "urgency");
        if (urgency) {
            filteredList = _filter(filteredList, (actionItem) => {
                return +_get(actionItem, "urgency") === +urgency;
            });
        }

        const hardware = _get(filter, "hardware");
        if (hardware) {
            filteredList = _filter(filteredList, (actionItem) => {
                return _get(actionItem, "equipment_type") === hardware;
            });
        }

        const facility = _get(filter, "facility");
        if (facility) {
            filteredList = _filter(filteredList, (actionItem) => {
                return +_get(actionItem, "facility_id") === +facility;
            });
        }

        const location = _get(filter, "location");
        if (location) {
            filteredList = _filter(filteredList, (actionItem) => {
                return _get(actionItem, "locationName", "-").split(" ").join("") === location.split(" ").join("");
            });
        }

        const status = _get(filter, "status");
        if (status) {
            filteredList = _filter(filteredList, (actionItem) => {
                return +_get(actionItem, "state") === +status;
            });
        }

        const sortField = _get(sort, "field");
        if (sortField) {
            filteredList = _orderBy(filteredList, sortField, _get(sort, "sort"));
        }

        this.setState({filteredList: filteredList});
    };

    handleSearch = (query) => {
        const {list} = this.state;

        return _filter(list, (actionItem) => {
            const sensorId = _get(actionItem, "installation_point.sensor_id") || _get(actionItem, "sensor_id") || "";

            return (
                _includes(("" + _get(actionItem, "locationName", "") || "").toLowerCase(), query.toLowerCase()) ||
                _includes(("" + _get(actionItem, "hardware", "") || "").toLowerCase(), query.toLowerCase()) ||
                _includes(("" + _get(actionItem, "deviceSerial", "") || "").toLowerCase(), query.toLowerCase()) ||
                _includes(("" + _get(actionItem, "facilityName", "") || "").toLowerCase(), query.toLowerCase()) ||
                _includes(sensorId, query)
            );
        });
    };

    getUrgencyFilter = () => {
        const {info} = this.state;
        let urgencyFilter = [];

        _each(_get(info, "action_list_urgencies"), (urgency) => {
            urgencyFilter.push({
                value: _get(urgency, "id"),
                label: _get(urgency, "name"),
            });
        });

        return urgencyFilter;
    };

    getHardwareFilter = () => {
        let hardwareFilter = [];

        _each(hardware, (name, key) => {
            hardwareFilter.push({
                value: key,
                label: name,
            });
        });

        return hardwareFilter;
    };

    getFacilityFilter = () => {
        const {facilities, list} = this.state;
        let facilityFilter = [];

        _each(list, (actionItem) => {
            if (!_find(facilityFilter, {value: +_get(actionItem, "facility_id")})) {
                facilityFilter.push({
                    value: +_get(actionItem, "facility_id"),
                    label: _get(facilities, +_get(actionItem, "facility_id")),
                });
            }
        });

        return facilityFilter;
    };

    getLocationFilter = () => {
        const {locations} = this.state;
        let locationFilter = [];

        _each(locations, (location) => {
            locationFilter.push({
                value: location.id,
                label: location.full_name,
            });
        });

        return locationFilter;
    };

    getStatusFilter = () => {
        const {info, isArchive} = this.state;
        let statusFilter = [];

        _each(_get(info, "action_list_states"), (name, id) => {
            if (isArchive && _includes([5, 9], +id)) {
                statusFilter.push({
                    value: id,
                    label: name,
                });
            }
            if (!isArchive && !_includes([5, 9], +id)) {
                statusFilter.push({
                    value: id,
                    label: name,
                });
            }
        });

        return statusFilter;
    };

    onChangeFilter = (event) => {
        const key = _get(event, "target.name");
        const value = _get(event, "target.value");

        const obj = key === "facility" ? {facility: value, location: ""} : {[key]: value};

        this.handleFilterChange(obj);
    };

    onChangeLocationFilter = (event) => {
        this.setState(
            {
                selectedLocationId: _get(event, "target.value"),
            },
            this.handleFetch
        );
    };

    filtersApplied = () => {
        const {filter} = this.state;

        return _get(filter, "urgency") || _get(filter, "hardware") || _get(filter, "facility") || _get(filter, "location") || _get(filter, "status");
    };

    resetFilter = () => {
        this.handleFilterChange({
            urgency: "",
            hardware: "",
            facility: "",
            query: "",
            location: "",
            status: "",
        });
    };

    downloadReport = () => {
        ActionListApi.exportActionItems()
            .then((response) => response.blob())
            .then((blob) => {
                this.crateFileLink(blob, "action-items-report");
            });
    };

    downloadArchiveReport = () => {
        ActionListApi.exportArchiveActionItems()
            .then((response) => response.blob())
            .then((blob) => {
                this.crateFileLink(blob, "archived-action-items-report");
            });
    };

    crateFileLink = (blob, fileName) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName + ".csv");
        link.setAttribute("id", "test-file");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    };

    onShowActionItem = (id) => {
        this.setState({showActionItemId: +id}, () => {
            if (id) {
                this.props.history.push(Helper.setHashParams({id: id}));
            } else {
                this.props.history.push(Helper.deleteHashParams(["id"]));
            }
        });
    };

    toggleAddActionItem = (show) => {
        this.setState({
            showAddActionItem: show,
        });
    };

    render() {
        let list = this.state.list;
        const {
            loader,
            sort,
            filter,
            globalSearchString,
            info,
            facilities,
            isArchive,
            filteredList,
            showActionItemId,
            selectedLocationId,
            showAddActionItem,
        } = this.state;
        const {history, user} = this.props;

        list = _get(filter, "query", "") || _get(sort, "field") || this.filtersApplied() ? filteredList : list;

        const urgencyFilter = this.getUrgencyFilter();
        const hardwareFilter = this.getHardwareFilter();
        const locationFilter = this.getLocationFilter();
        const statusFilter = this.getStatusFilter();

        return (
            <React.Fragment>
                <div>
                    <HeaderSimple
                        breadcrumbs={[{name: isArchive ? "Archived Action Items" : "Active Action Items"}]}
                        globalSearchString={globalSearchString}
                        handleChangeGlobalSearch={this.handleChangeGlobalSearch}
                    />
                    <div className="block action-item-block">
                        <div className={"block-body no-padding action-item-list" + (loader ? " loading" : "")}>
                            {loader ? (
                                <Loader />
                            ) : (
                                <React.Fragment>
                                    <div className="block-header">
                                        <div className="block-label">
                                            <div className="form-list form-list--row">
                                                {!!urgencyFilter.length && (
                                                    <ActionItemSelect
                                                        name={"urgency"}
                                                        value={_get(filter, "urgency")}
                                                        options={urgencyFilter}
                                                        label={"Urgency Levels"}
                                                        onChange={this.onChangeFilter}
                                                        className={"form-group form-group--inline"}
                                                    />
                                                )}
                                                {!!hardwareFilter.length && (
                                                    <ActionItemSelect
                                                        name={"hardware"}
                                                        value={_get(filter, "hardware")}
                                                        options={hardwareFilter}
                                                        label={"Hardware Types"}
                                                        onChange={this.onChangeFilter}
                                                        className={"form-group form-group--inline"}
                                                    />
                                                )}
                                                {!!locationFilter.length && (
                                                    <div className="form-group form-group--inline">
                                                        <CollapseLocationSelect
                                                            className={"form-control form-control-sm"}
                                                            selectName={"locationId"}
                                                            value={+selectedLocationId}
                                                            onChange={this.onChangeLocationFilter}
                                                            emptyOptionLabel={"All Locations"}
                                                            checkStoreValue={true}
                                                            withFavorite={true}
                                                        />
                                                    </div>
                                                )}
                                                {!!statusFilter.length && (
                                                    <ActionItemSelect
                                                        name={"status"}
                                                        value={_get(filter, "status")}
                                                        options={statusFilter}
                                                        label={"Statuses"}
                                                        onChange={this.onChangeFilter}
                                                        className={"form-group form-group--inline"}
                                                    />
                                                )}
                                                {this.filtersApplied() && (
                                                    <div className="form-group form-group--inline">
                                                        <button
                                                            className="btn btn-primary btn-sm"
                                                            onClick={this.resetFilter}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="block-toolbar">
                                            <button
                                                className="btn btn-primary btn-sm mr-3"
                                                onClick={() => this.toggleAddActionItem(true)}
                                            >
                                                <i className="fa fa-plus" /> Add Action Item
                                            </button>
                                            <div style={{minWidth: "180px"}}>
                                                <SearchInput
                                                    placeholder="Search Action Items"
                                                    query={filter.query}
                                                    onChange={this.searchChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"action-item-body custom-scroll ai-table"}>
                                        <Table
                                            list={list}
                                            sort={sort}
                                            query={filter.query}
                                            info={info}
                                            facilities={facilities}
                                            onSortChange={this.handleSortChange}
                                            isArchive={isArchive}
                                            user={user}
                                            onShowActionItem={this.onShowActionItem}
                                        />
                                    </div>
                                    <div className={"action-item-footer"} />
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                    {!loader && (
                        <div className={"d-flex"}>
                            <button
                                onClick={this.handleArchive}
                                className="btn v2-btn blue-border"
                            >
                                <img
                                    src={isArchive ? "/assets/pic/icon-ongoing.svg" : "/assets/pic/icon-archive.svg"}
                                    alt=""
                                />
                                <span>{isArchive ? "Show Active Action Items" : "Show Archived Action Items"}</span>
                            </button>
                            {isArchive ? (
                                <button
                                    onClick={this.downloadArchiveReport}
                                    className="btn v2-btn blue ml-3"
                                >
                                    <img
                                        src={"/assets/pic/icon-download.svg"}
                                        alt=""
                                    />
                                    <span>Download Archived Action Item Report</span>
                                </button>
                            ) : (
                                <button
                                    onClick={this.downloadReport}
                                    className="btn v2-btn blue ml-3"
                                >
                                    <img
                                        src={"/assets/pic/icon-download.svg"}
                                        alt=""
                                    />
                                    <span>Download Action Item Report</span>
                                </button>
                            )}
                        </div>
                    )}
                </div>
                {!loader && !!showActionItemId && (
                    <ActionItemModal
                        actionItemId={+showActionItemId}
                        info={info}
                        user={user}
                        history={history}
                        onClose={() => this.onShowActionItem(null)}
                        updateList={this.handleFetch}
                    />
                )}
                {showAddActionItem && (
                    <ActionItemAddModal
                        updateList={this.handleFetch}
                        onClose={() => this.toggleAddActionItem(false)}
                        onShowActionItem={this.onShowActionItem}
                    />
                )}
            </React.Fragment>
        );
    }
}

ActionItems.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    type: PropTypes.string,
    user: PropTypes.object,
    getLocationId: PropTypes.func.isRequired,
};

export default withLocationSelectStore(withGlobalStore(ActionItems));
