import {useQuery} from "@tanstack/react-query";
import {API_CONSTANTS} from "../../../api/constants/constants";
import SsdApi from "../../../api/ssd";

const searchSensorBySerialKey = (filters) => [API_CONSTANTS.SEARCH_SENSOR_BY_SERIAL, filters.query];
const searchSensorBySerialFn = (filters) => SsdApi.searchSensorBySerial({query: filters.query});

export const useSearchSensorBySerialQuery = (filters) => {
    return useQuery({
        queryFn: () => searchSensorBySerialFn(filters),
        queryKey: searchSensorBySerialKey(filters),
        enabled: filters.enabled,
        cacheTime: 0,
        select: (res) => {
            return res.data.map((item) => ({
                value: item.serial,
                label: item.label,
                equipmentType: item.equipmentType,
                activeWwAi: item.activeWwAi,
                isInactive: item.isInactive === 1,
                installation_point_id: item.installationPoint.id,
            }));
        },
    });
};
