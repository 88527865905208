import React, {Component} from "react";
import PropTypes from "prop-types";
import {
    get as _get,
    each as _each,
    setWith as _setWith,
    isEqual as _isEqual,
    isEmpty as _isEmpty,
    isNull as _isNull,
    size as _size
} from "lodash";

import "../../../../assets/scss/components/action-items/subscription-actions-items.scss";

class SubscriptionActonItems extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            all: false,
            custom: false,
            none: false,
            inProgress: false,
            allCheckboxesMap: {},
            allFacilitySettings: false
        };
    }

    componentDidMount() {
        this.updateAllCheckboxesMap();
    }

    componentDidUpdate(prevProps) {
        if (
            !_isEqual(prevProps.data, this.props.data) ||
            prevProps.loader !== this.props.loader ||
            !_isEqual(prevProps.facilities, this.props.facilities)
        ) {
            this.updateAllCheckboxesMap();
        }
    }

    updateAllCheckboxesMap = () => {
        const {data, emailEvents, urgencies, facilities, loader} = this.props;

        let allCheckboxesMap = {};
        let isAll = true;
        let isNone = true;

        _each(emailEvents, (event) => {
            const eventId = _get(event, "id");
            _each(urgencies, (urgency) => {
                _each(facilities, (facility) => {
                    const urgencyId = _get(urgency, "id", "");
                    const facilityId = _get(facility, "facility_id");
                    const valueKey = "["+eventId+"]["+facilityId+"][urgencies]["+urgencyId+"]";

                    const currentAllCheckboxValue = _get(allCheckboxesMap, "["+facilityId+"]["+urgencyId+"]", 1);
                    const value = _get(data, valueKey);

                    const newValue = currentAllCheckboxValue && _get(data, valueKey);

                    isAll = isAll && value;
                    isNone = isNone && !value;

                    _setWith(allCheckboxesMap, "["+facilityId+"]["+urgencyId+"]", newValue, Object);
                });
            });
        });

        const filteredCheckboxes = Object.values(allCheckboxesMap).filter(location => {
            return _size(Object.values(location).filter(state => state !== undefined)) > 0;
        });

        if (data && !loader && Object.keys(filteredCheckboxes).length) {
            this.setState({all: isAll, none: isNone, custom: !isAll && !isNone});
        }

        this.setState({allCheckboxesMap});
    }

    checkAllCheckboxesMap = (facilityId, urgencyId) => {
        const {allCheckboxesMap} = this.state;
        const {facilities} = this.props;

        if(_isNull(facilityId)){
            let tmpArrayValue = [];

            _each(facilities, (facility) => {
                tmpArrayValue.push(_get(allCheckboxesMap, "["+_get(facility, "facility_id")+"]["+urgencyId+"]"));
            });

            tmpArrayValue = tmpArrayValue.filter((value, index, array) => array.indexOf(value) === index);
            if(tmpArrayValue.length > 1) {
                return 0;
            } else {
                return +tmpArrayValue[0] || 0;
            }
        } else {
            return _get(allCheckboxesMap, "["+facilityId+"]["+urgencyId+"]");
        }
    }

    checkData = (data, facilityId, eventId, urgencyId) => {
        const {facilities} = this.props;
        let valueKey = "["+eventId+"]["+facilityId+"][urgencies]["+urgencyId+"]";

        if(_isNull(facilityId)) {
            let tmpArrayValue = [];

            _each(facilities, (facility) => {
                tmpArrayValue.push(_get(data, "["+eventId+"]["+_get(facility, "facility_id")+"][urgencies]["+urgencyId+"]"));
            });

            tmpArrayValue = tmpArrayValue.filter((value, index, array) => array.indexOf(value) === index);
            if(tmpArrayValue.length > 1) {
                return 0;
            } else {
                return +tmpArrayValue[0] || 0;
            }
        } else {
            return _get(data, valueKey);
        }
    }

    toggleAll = () => {
        const {all} = this.state;
        const {toggleAll} = this.props;

        this.setState({all: !all, custom: false, none: false}, () => toggleAll(1));
    }

    toggleCustom = () => {
        const {custom} = this.state;
        this.setState({all: false, custom: !custom, none: false});
    }

    toggleNone = () => {
        const {none} = this.state;
        const {toggleAll} = this.props;

        this.setState({all: false, custom: false, none: !none}, () => toggleAll(0));
    }

    handleChangeFacility = (facilityId = null) => {
        const {changeFacility} = this.props;

        if(_isNull(facilityId)) {
            this.setState({allFacilitySettings: true});
        } else {
            this.setState({allFacilitySettings: false});
        }

        changeFacility(facilityId);
    }

    handleChangeForAllFacility = (value, facilityId, urgencyId) => {
        const {toggleAllForFacility, changeData, emailEvents, facilities, urgencies} = this.props;

        if(_isNull(facilityId)) {
            let valueArray = [];
            let keyArray = [];
            _each(emailEvents, (emailEvent) => {
                const eventId = _get(emailEvent, "id");
                _each(facilities, (facility) => {
                    _each(urgencies, (urgency) => {
                        const idUrgency = _get(urgency, "id");
                        let valueKey = "["+eventId+"]["+_get(facility, "facility_id")+"][urgencies]["+idUrgency+"]";
                        keyArray.push(valueKey);
                        if(+idUrgency === +urgencyId) {
                            valueArray.push(value);
                        } else {
                            valueArray.push(+this.checkData(this.props.data, facilityId, eventId, idUrgency));
                        }
                    });
                });
            });
            changeData(keyArray, valueArray, true);
        } else {
            toggleAllForFacility(value, facilityId, urgencyId);
        }
    }

    render() {
        const {inProgress, all, custom, none} = this.state;
        const {data, facilities, emailEvents, urgencies, selectedFacilityId, changeData, fromProfile} = this.props;

        const labelClass = fromProfile ? "col-3" : "col-2 pull-right-label";
        return (
            <React.Fragment>
                <div className="form-group row">
                    <label className={"form-label " + labelClass}>Receive Network Action Items</label>
                    <div className="col-1">
                        <div className="form-list form-list--column">
                            <label className="form-radio ml-2">
                                <input
                                    type="radio"
                                    disabled={inProgress}
                                    checked={all}
                                    onChange={this.toggleAll}
                                /> All <span/>
                            </label>
                            <label className="form-radio ml-2">
                                <input
                                    type="radio"
                                    disabled={inProgress}
                                    checked={custom}
                                    onChange={this.toggleCustom}
                                /> Custom <span/>
                            </label>
                            <label className="form-radio ml-2">
                                <input
                                    type="radio"
                                    disabled={inProgress}
                                    checked={none}
                                    onChange={this.toggleNone}
                                /> None <span/>
                            </label>
                        </div>
                    </div>
                    {custom &&
                        <div className="col-5">
                            <div className={"row"}>
                                <div className={"col-md-4"}>
                                    <strong>Asset Tree Branch:</strong>
                                    <div className={"facility-list ai-setting-block"}>
                                        { !_isEmpty(facilities) &&  <div
                                            key={-1}
                                            className={"facility-option" +
                                                (this.state.allFacilitySettings
                                                        ?
                                                        " active"
                                                        :
                                                        ""
                                                )}
                                            onClick={() => this.handleChangeFacility(null)}
                                        >
                                            All Facilities
                                        </div> }
                                        {Object.keys(facilities).map((facility, key) =>
                                            <div
                                                key={key}
                                                className={"facility-option" +
                                                    (+selectedFacilityId === +_get(facilities, key+".facility_id", "")
                                                        ?
                                                        " active"
                                                        :
                                                        ""
                                                    )}
                                                onClick={() => this.handleChangeFacility(+_get(facilities, key+".facility_id", ""))}
                                            >
                                                {_get(facilities, key+".name", "")}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className={"col-md-8"}>
                                    <strong>Send email notifications for the following action item statuses:</strong>
                                    <div className={"email-events-list ai-setting-block mr-2"}>
                                        <div>
                                            <div><b>All</b></div>
                                            {Object.keys(urgencies).map((urgKey) =>
                                                <label className="form-checkbox" key={urgKey}>
                                                    <input
                                                        type="checkbox"
                                                        checked={this.checkAllCheckboxesMap(selectedFacilityId, _get(urgencies, urgKey+".id", ""))}
                                                        onChange={() => {
                                                            this.handleChangeForAllFacility(
                                                                +!this.checkAllCheckboxesMap(selectedFacilityId, _get(urgencies, urgKey+".id", "")),
                                                                selectedFacilityId,
                                                                _get(urgencies, urgKey+".id")
                                                            );
                                                        }}
                                                    /> {_get(urgencies, urgKey+".name", "")} <span/>
                                                </label>
                                            )}
                                        </div>
                                        <hr/>
                                        {emailEvents.map((event, key) =>
                                            <div key={key}>
                                                <div><b>{_get(event, "name", "")}</b></div>
                                                {Object.keys(urgencies).map((urgKey) =>
                                                    <EventCheckbox
                                                        key={+urgKey}
                                                        data={data}
                                                        urgKey={+urgKey}
                                                        urgencies={urgencies}
                                                        facilities={facilities}
                                                        changeData={changeData}
                                                        selectedFacilityId={selectedFacilityId}
                                                        event={event}
                                                        checkData={this.checkData}
                                                        emailEvents={emailEvents}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </React.Fragment>
        );
    }
}

const EventCheckbox = ({data, urgencies, facilities, urgKey, selectedFacilityId, event, changeData, checkData, emailEvents}) => {
    const eventId = _get(event, "id");
    const urgencyId = +_get(urgencies, urgKey+".id", "");
    let valueKey = "["+eventId+"]["+selectedFacilityId+"][urgencies]["+urgencyId+"]";

    return (
        <label className="form-checkbox" key={urgKey}>
            <input
                type="checkbox"
                checked={checkData(data, selectedFacilityId, eventId, urgencyId)}
                onChange={ () => {
                    if(_isNull(selectedFacilityId)) {
                        let valueArray = [];
                        let keyArray = [];
                        _each(emailEvents, (emailEvent) => {
                            const tmpEventId = _get(emailEvent, "id");
                            _each(facilities, (facility) => {
                                const facilityId = _get(facility, "facility_id");
                                _each(urgencies, (urgency) => {
                                    const tmpUrgencyId = _get(urgency, "id");
                                    let newValue = +checkData(data, selectedFacilityId, tmpEventId, tmpUrgencyId);
                                    valueKey = "["+tmpEventId+"]["+facilityId+"][urgencies]["+tmpUrgencyId+"]";
                                    keyArray.push(valueKey);
                                    if(+eventId === +tmpEventId && +tmpUrgencyId === +urgencyId) {
                                        valueArray.push(+!newValue);
                                    } else {
                                        valueArray.push(newValue);
                                    }
                                });
                            });
                        });
                        changeData(keyArray, valueArray, true);
                    } else {
                        changeData(valueKey, +(!_get(data, valueKey)));
                    }
                } }
            /> {_get(urgencies, urgKey+".name", "")} <span/>
        </label>
    );
};

EventCheckbox.propTypes = {
    data: PropTypes.object,
    urgencies: PropTypes.object,
    facilities: PropTypes.array,
    urgKey: PropTypes.number,
    selectedFacilityId: PropTypes.number,
    event: PropTypes.object,
    changeData: PropTypes.func,
    checkData: PropTypes.func,
    emailEvents: PropTypes.object
};

SubscriptionActonItems.propTypes = {
    loader: PropTypes.bool,
    data: PropTypes.object,
    urgencies: PropTypes.object,
    facilities: PropTypes.array,
    emailEvents: PropTypes.array,
    selectedFacilityId: PropTypes.number,
    changeFacility: PropTypes.func,
    changeData: PropTypes.func,
    toggleAllForFacility: PropTypes.func,
    fromProfile: PropTypes.bool,
    toggleAll: PropTypes.func
};

export default SubscriptionActonItems;