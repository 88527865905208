import React, {Component, Fragment, useEffect, useState} from "react";
import {Loader, Modal} from "../../../shared";
import PropTypes from "prop-types";
import {withGlobalStore} from "../../../stores/GlobalStore";
import CheckBox from "../../../pages/shared/template/check-box";
import EquipmentInstallationPointApi from "../../../api/equipmentInstallationPoint";
import {
    get as _get,
    find as _find,
    isEqual as _isEqual,
    filter as _filter,
    includes as _includes,
    cloneDeep as _cloneDeep,
    each as _each,
    sortBy as _sortBy,
} from "lodash";
import Toast from "../../../pages/shared/toast";
import RequestOnDemandTab from "../../../pages/chart/aside/components/requestOnDemandTab";
import {Button} from "reactstrap";
import requestOnDemandGroupApi from "../../../api/requestOnDemandGroup";
import SweetAlert from "react-bootstrap-sweetalert";
import FMaxSelect from "../../../pages/shared/intervals/FMaxSelect";
import LinesOfResolutionSelect from "../../../pages/shared/intervals/LinesOfResolutionSelect";
import {LossPacketsAlert} from "../../../shared/lossPacketsAlert/lossPacketsAlert";
// import "../../../assets/scss/components/modals/request-on-demand/request-on-demand.scss";
import RequestOnDemandGroupApi from "../../../api/requestOnDemandGroup";
import InstallationPointSettingsApi from "../../../api/InstallationPointSettings";
import Helper from "../../../helpers/helper";
import ImpactVueText from "../../../shared/impactVue/ImpactVueText";

const readings = [
    {label: "RMS", name: "is_rms"},
    {label: "FFT", name: "is_fft"},
];

class RequestOnDemandModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            installationPoints: [],
            showHFDvue: false,
            loader: true,
            showWarning: false,
            isLoading: false,
        };

        this.fetch = this.fetch.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDataChange = this.onDataChange.bind(this);
        this.toggleHFDvue = this.toggleHFDvue.bind(this);
        this.handleSubmitOnDemand = this.handleSubmitOnDemand.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            !_isEqual(nextProps, this.props) ||
            !_isEqual(nextState.installationPoints, this.state.installationPoints) ||
            nextState.showHFDvue !== this.state.showHFDvue ||
            nextState.loader !== this.state.loader ||
            nextState.isLoading !== this.state.isLoading ||
            nextState.showWarning !== this.state.showWarning
        );
    }

    handleSubmitOnDemand = (data) => {
        const {user, equipment} = this.props;

        RequestOnDemandGroupApi.create(data).then((response) => {
            for (const od of (response || {}).list || []) {
                const odData = ((od || {}).data || {})[0] || (od || {}).data || {};
                const {installationPoints} = equipment;

                const type = _get(od, "data.type");
                const pointId = _get(od, "data.installation_point_id");
                const point = _find(installationPoints, (point) => +point.id === +pointId) || {};

                if (+odData.readingType === 12) {
                    this.props.addPendingOnDemand(odData.setting_id);
                }
                if (point.id && odData.requestId) {
                    InstallationPointSettingsApi.getEstimatedTime(point.id, odData.requestId, {
                        type,
                        isHFDvue: _get(data, "data." + point.id + ".isHFDvue") ? 1 : 0,
                    }).then(({time}) => {
                        let successText = `<b>${point.name}</b><br>The on-demand ${type.toUpperCase()} request has been sent.<br>Data should be returned ${
                            time ? "by " + Helper.dateToUserFormat(time, user) : "later"
                        }.`;
                        Toast.success(<div dangerouslySetInnerHTML={{__html: successText}}></div>);
                    });
                }
            }
            this.setState({isLoading: false});
            this.props.onClose();
        });
    };

    componentDidMount() {
        this.fetch();
    }

    fetch() {
        EquipmentInstallationPointApi.list(this.props.equipment.id).then(({list = []}) => {
            this.setState({
                installationPoints: _filter(list, (point) => point.point_type === "sensor"),
                loader: false,
            });
        });
    }

    onDataChange(pointId, pointData = {}) {
        let updatedDemanList = this.state.data;

        if (pointId && !Object.keys(pointData).length) {
            delete updatedDemanList[pointId];
        }

        if (Object.keys(pointData).length) {
            this.setState({data: {...updatedDemanList, [pointId]: pointData}});
        }
    }

    toggleHFDvue() {
        this.setState({
            showHFDvue: !this.state.showHFDvue,
            data: {},
        });
    }

    onSubmit() {
        const {data, showHFDvue} = this.state;

        if (!Object.keys(data || {}).length || _find(data, (point = {}) => !(point.is_rms || point.is_fft))) {
            return Toast.error("Please select the desired installation points and readings for the on-demand reading (RMS or FFT).");
        } else {
            this.setState({
                isLoading: true,
            });

            requestOnDemandGroupApi
                .preValidate({data})
                .then((response) => {
                    const versions = _get(response, "versions", []);
                    const filtered = _filter(versions, (version) => _includes(["0+unknown"], version)) || [];

                    if (filtered.length) {
                        return this.setState({showWarning: true});
                    } else {
                        return this.handleSubmitOnDemand({data, showHFDvue});
                    }
                })
                .finally(() => {
                    this.setState({isLoading: false});
                });
        }
    }

    cancelWarning = () => {
        this.setState({showWarning: false});
    };

    render() {
        const {installationPoints, showHFDvue, loader, showWarning, data, isLoading} = this.state;
        const {auth, onClose, triggers} = this.props;

        const booleanTriggerTypes = triggers?.restriction?.generic_sensor_types;

        const filteredInstallationPoints = installationPoints.filter((point) => !booleanTriggerTypes?.includes?.(point?.sensor?.version_type));

        if (!auth.userCan("sendRequestOnDemand")) {
            return <></>;
        }

        return (
            <React.Fragment>
                <Modal
                    {...this.props}
                    size="lg"
                    title={
                        <span>
                            {showHFDvue ? <ImpactVueText /> : ""} {this.props.title}
                        </span>
                    }
                    submitTitle="Send"
                    onSubmit={this.onSubmit}
                    showModal={true}
                    inProgress={isLoading || this.props.isTriggersLoading}
                    withoutFooter={true}
                >
                    {loader ? (
                        <Loader />
                    ) : (
                        <React.Fragment>
                            <div className={"custom-scroll demand-scroll"}>
                                {filteredInstallationPoints.map((point) => (
                                    <InstallationPoint
                                        point={point}
                                        key={point.id}
                                        showHFDvue={+_get(point, "sensor.is_hfdvue", false)}
                                        onDataChange={this.onDataChange}
                                    />
                                ))}
                            </div>
                            <div className="edit-btns pt-2 d-flex justify-content-end">
                                <Button
                                    onClick={onClose}
                                    color="secondary"
                                    className="btn-sm"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={this.onSubmit}
                                    disabled={isLoading}
                                    color="primary"
                                    className="btn-sm ml-2"
                                >
                                    {isLoading ? (
                                        <span>
                                            <i className="fa fa-spinner" /> Processing
                                        </span>
                                    ) : (
                                        "Save"
                                    )}
                                </Button>
                            </div>
                            <RequestOnDemandTab />
                        </React.Fragment>
                    )}

                    {/*TODO: refactor with modal hooks*/}
                    {showWarning && (
                        <SweetAlert
                            warning
                            showCancel
                            confirmBtnText="Confirm"
                            cancelBtnBsStyle="default"
                            btnSize="xs"
                            title="An unknown version of the gateway"
                            openAnim={false}
                            closeAnim={false}
                            onConfirm={() => this.handleSubmitOnDemand({data, showHFDvue})}
                            onCancel={this.cancelWarning}
                        >
                            There may be problems with your request. If you have a problem, please contact support@waites.net
                        </SweetAlert>
                    )}
                </Modal>
            </React.Fragment>
        );
    }
}

const InstallationPoint = ({point, showHFDvue, onDataChange}) => {
    const defaultFMax = _get(point, "sensorParameters.defaultFMax");
    const defaultLor = _get(point, "sensorParameters.defaultLorOnDemand");
    const modelSpec = _get(point, "modelSpec", {});
    const sensorParameters = _get(point, "sensorParameters", {});

    const initData = {
        id: null,
        fMax: defaultFMax,
        linesOfResolution: defaultLor,
        is_fft: 0,
        is_rms: 0,
        axisIds: [1],
        isHFDvue: false,
    };

    const [data, setData] = useState(_cloneDeep(initData));

    useEffect(() => {
        let pointData = {
            id: _get(data, "id"),
            is_fft: _get(data, "is_fft"),
            is_rms: _get(data, "is_rms"),
            isHFDvue: _get(data, "isHFDvue"),
        };

        let fft = [];
        (data.axisIds || []).map((axisId) => {
            fft.push({
                axis_id: axisId,
                f_max: data.fMax,
                lines_of_resolution: data.linesOfResolution,
            });
        });

        if (data.id) {
            onDataChange(point.id, {...pointData, fft});
        }
    }, [data]);

    const changeReadingType = (reading) => {
        if (reading.name === "is_fft") {
            setData({...data, is_fft: +!data.is_fft});
        } else if (reading.name === "is_rms") {
            setData({...data, is_rms: +!data.is_rms});
        }
    };

    const changeAxis = (axis_id) => {
        const axisIds = _get(data, "axisIds", []);
        const alreadyChecked = _includes(axisIds, +axis_id);
        let linesOfResolution = _get(data, "linesOfResolution");

        if (!alreadyChecked || axisIds.length > 1) {
            if (alreadyChecked) {
                const index = axisIds.indexOf(+axis_id);
                if (index !== -1) {
                    axisIds.splice(index, 1);
                }
            } else {
                axisIds.push(+axis_id);
            }
        }

        setData({...data, axisIds, linesOfResolution});
    };

    const changeFMax = (e) => {
        setData({...data, fMax: _get(e, "target.value")});
    };

    const changeLinesOfResolutionSelect = (e) => {
        setData({...data, linesOfResolution: _get(e, "target.value")});
    };

    let lorValues = _get(sensorParameters, "lines_of_resolution_on_demand", []);
    const lor = +_get(data, "linesOfResolution");
    const fMax = +_get(data, "fMax");
    const maxAcquisitionTime = _get(sensorParameters, "maxAcquisitionTime", 60);

    if (_get(data, "isHFDvue")) {
        const fMaxValues = _get(sensorParameters, "impactvue_fmax", []) || [];
        let newLorValues = [];
        _each(lorValues, (lorValue) => {
            newLorValues.push(lorValue);
        });
        _each(fMaxValues, (fMaxValue) => {
            let exist = false;
            _each(newLorValues, (lorValue) => {
                if (+lorValue > fMaxValue * maxAcquisitionTime) {
                    exist = true;
                    return;
                }
            });
            if (exist) {
                newLorValues.push(fMaxValue * maxAcquisitionTime);
            }
        });

        if (lor / fMax > maxAcquisitionTime) {
            changeLinesOfResolutionSelect({target: {value: fMax * maxAcquisitionTime}});
        }

        lorValues = _sortBy(newLorValues);
    }

    return (
        <div className="row request-row">
            <div className="col-md-3">
                <CheckBox
                    className={"my-1 mx-0 form-checkbox" + (point.sensor_id === null ? " disabled" : "")}
                    onChange={() => {
                        if (data.id) {
                            onDataChange(point.id, {});
                            setData(_cloneDeep(initData));
                        } else {
                            setData({...data, id: point.id});
                        }
                    }}
                    label={point.name + (!point.sensor_id ? " (sensor not installed)" : !point.sensor ? " (sensor has not yet reported)" : "")}
                    color={point.color || ""}
                    checked={!!data.id}
                    disabled={!point.sensor_id || !point.sensor}
                />
                {!!(data.id && showHFDvue) && (
                    <div
                        className="chart-block m-0 p-0"
                        style={{position: "relative", background: "none"}}
                    >
                        <span
                            className="alert-link"
                            onClick={() => {
                                setData({...data, isHFDvue: !data.isHFDvue});
                            }}
                        >
                            <label className="switch">
                                <input
                                    readOnly={true}
                                    type="checkbox"
                                    checked={data.isHFDvue ? "checked" : false}
                                />
                                <span className="slider round" />
                            </label>
                            <ImpactVueText />
                        </span>
                    </div>
                )}
            </div>
            {!!data.id && (
                <Fragment>
                    <div className="col-md-2">
                        <div className="row">
                            {readings.map((reading = {}) => (
                                <div
                                    key={`${point.id}-${reading.name}`}
                                    className="col-md-6"
                                >
                                    <CheckBox
                                        className="my-2 mx-0 blue-checkbox"
                                        onChange={() => changeReadingType(reading)}
                                        label={reading.label}
                                        checked={data[reading.name] === 1}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-md-7 pl-3 pr-3">
                        {data.is_fft === 1 && (
                            <>
                                <LossPacketsAlert
                                    id={_get(point, "id")}
                                    isCollapsed={false}
                                    className={"on-demand-alert-damage"}
                                    lossPacketsPercent={_get(point, "sensor.node.packet_loss_percent")}
                                />
                                {!data.isHFDvue && (
                                    <div className={"d-flex"}>
                                        {modelSpec.map((spec, index) => (
                                            <div
                                                key={index}
                                                className={"mr-2"}
                                            >
                                                <CheckBox
                                                    className="my-2 mx-0 form-checkbox"
                                                    onChange={() => changeAxis(+_get(spec, "axis_id"))}
                                                    checked={_includes(data.axisIds, +_get(spec, "axis_id"))}
                                                    label={_get(spec, "axis_name", "").toUpperCase()}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}

                                <div
                                    className="form-group"
                                    style={{marginBottom: 20}}
                                >
                                    <div className="align-items-center mb-3">
                                        <label className="text-right">FMax:</label>
                                        <div>
                                            <FMaxSelect
                                                name={"f_max"}
                                                sensorParameters={sensorParameters}
                                                value={_get(data, "fMax")}
                                                onChange={(e) => changeFMax(e)}
                                                isHFDvue={data.isHFDvue}
                                                versionType={+_get(point, "sensor.version_type")}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="form-group"
                                    style={{marginBottom: 15}}
                                >
                                    <div className="align-items-center mb-3">
                                        <label className="text-right">Lines of Resolution:</label>
                                        <div>
                                            <LinesOfResolutionSelect
                                                name={"lines_of_resolution"}
                                                value={_get(data, "linesOfResolution")}
                                                values={lorValues}
                                                onChange={(e) => changeLinesOfResolutionSelect(e)}
                                                isRequestOnDemand={true}
                                                isHFDvue={data.isHFDvue}
                                                curFmax={_get(data, "fMax")}
                                                maxAcquisitionTime={maxAcquisitionTime}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </Fragment>
            )}
        </div>
    );
};

InstallationPoint.propTypes = {
    point: PropTypes.object,
    showHFDvue: PropTypes.bool,
    onDataChange: PropTypes.func,
};

RequestOnDemandModal.propTypes = {
    auth: PropTypes.object,
    equipmentId: PropTypes.number,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    showModal: PropTypes.bool,
    inProgress: PropTypes.bool,
    addPendingOnDemand: PropTypes.func,
    user: PropTypes.object,
    children: PropTypes.any,
    title: PropTypes.string,
    history: PropTypes.object,
    installationPoints: PropTypes.array,
    chartTypes: PropTypes.object,
    equipment: PropTypes.object,
    disabled: PropTypes.bool,
    triggers: PropTypes.object,
    isTriggersLoading: PropTypes.bool,
};

export default withGlobalStore(RequestOnDemandModal);
