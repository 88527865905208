import React, {useEffect, useState} from "react";
import LocationModal from "../../../../modals/location/location";
import Helper from "../../../../helpers/helper";
import SweetAlert from "react-bootstrap-sweetalert";
import CollapseLocationSelect from "../../../../shared/collapseLocationSelect/collapseLocationSelect";
import styles from "../../location.module.scss";
import PropTypes from "prop-types";
import {useAssetDeleteMutation} from "../../../../hooks/api/assets/useAssetDeleteMutation";
import {getLocationId, setLocationId} from "../../../../helpers/locations-list";
import {useHistory} from "react-router";
import {useAssetDeleteInfoQuery} from "../../../../hooks/api/assets/useAssetDeleteInfoQuery";
import {getAssetTreeBranchParentByChildId} from "../../../../helpers/getAssetTreeBranchParentByChildId";

const LocationsActionsModal = ({
    treeData,
    user,
    updateUser,
    refreshLocation,
    onDeleteLocation,
    setMovedToAssetTreeBranchId,
    movedToAssetTreeBranchId,
    deleteLocationId,
}) => {
    const history = useHistory();
    const searchParams = Helper.getHashParams();
    const [confirmDeleteModal, setConfirmDeleteModal] = useState({
        show: false,
    });

    const {data: deleteInfo} = useAssetDeleteInfoQuery(deleteLocationId);
    const {mutateAsync: locationDelete} = useAssetDeleteMutation();

    useEffect(() => {
        if (deleteLocationId) {
            setConfirmDeleteModal({
                show: true,
            });
            setMovedToAssetTreeBranchId(getAssetTreeBranchParentByChildId(treeData, deleteLocationId));
        } else {
            setConfirmDeleteModal({
                show: false,
            });
        }
    }, [deleteLocationId, treeData]);

    const handleChangeAssetTreeBranchForDelete = ({value}) => {
        setMovedToAssetTreeBranchId(value);
    };

    const deleteLocation = () => {
        onDeleteLocation(null);
        return locationDelete({id: deleteLocationId, moveToAsset: movedToAssetTreeBranchId}).then(() => {
            if (+deleteLocationId === +getLocationId()) {
                setLocationId("");
            }
        });
    };

    const handleOnClose = () => {
        const removeParams = searchParams.modal === "add" ? ["modal"] : ["modal", "id"];
        history.push(removeParams);
    };

    return (
        <>
            {(searchParams.modal || !!searchParams.id) && (
                <LocationModal
                    variant={searchParams.modal}
                    locationId={+searchParams.id}
                    list={treeData}
                    onClose={handleOnClose}
                    refreshLocation={refreshLocation}
                    updateUser={updateUser}
                    user={user}
                />
            )}
            {confirmDeleteModal.show && deleteInfo && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnBsStyle="default"
                    btnSize="xs"
                    title="DELETE BRANCH"
                    onConfirm={deleteLocation}
                    onCancel={() => onDeleteLocation(null)}
                >
                    Are you sure you want to delete this branch?
                    <br />
                    <div className={"text-left"}>
                        {!!(deleteInfo.countActionItems || deleteInfo.countUsers || deleteInfo.countEquipment) && (
                            <>
                                <div className={"mt-2 mb-2"}>
                                    <div className="mb-1">Note that the following exists:</div>
                                    <ul className="pl-4">
                                        {!!deleteInfo.countEquipment && (
                                            <li>
                                                {+deleteInfo.countEquipment} {deleteInfo.countEquipment > 1 && "pieces of"} equipment
                                            </li>
                                        )}
                                        {!!deleteInfo.countActionItems && (
                                            <li>
                                                {+deleteInfo.countActionItems > 1
                                                    ? +deleteInfo.countActionItems + " active action items"
                                                    : "1 active action item"}
                                            </li>
                                        )}
                                        {!!deleteInfo.countUsers && (
                                            <li>
                                                {deleteInfo.countUsers > 1
                                                    ? +deleteInfo.countUsers + " users with access to this branch"
                                                    : "1 user with access to this branch"}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                                <div className={"mt-2 mb-2"}>
                                    Deleting this branch will reassign all associated equipment and active action items to another branch. Users will lose
                                    access to {deleteInfo.location.name} and will stop receiving emails for this branch.
                                </div>
                                <div className="mb-2">Select the location to which you want to move the equipment from the remote branch.</div>
                                <div className="w-100 flex">
                                    <CollapseLocationSelect
                                        locationList={[deleteInfo.facilityLocation]}
                                        onChange={handleChangeAssetTreeBranchForDelete}
                                        value={movedToAssetTreeBranchId}
                                        emptyOptionLabel={false}
                                        addClassName={styles.locationDelete}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </SweetAlert>
            )}
        </>
    );
};

LocationsActionsModal.propTypes = {
    treeData: PropTypes.array,
    refreshLocation: PropTypes.func,
    user: PropTypes.object,
    updateUser: PropTypes.func,
    onDeleteLocation: PropTypes.func,
    setMovedToAssetTreeBranchId: PropTypes.func,
    movedToAssetTreeBranchId: PropTypes.number,
    deleteLocationId: PropTypes.number,
};

export default LocationsActionsModal;
