import React from "react";
import PropTypes from "prop-types";
import SelectWrapper from "../../helpers/select-wrapper";

const readingIntervals = [
    {id: 2, name: "Minutes", seconds: 60},
    {id: 3, name: "Hours", seconds: 60 * 60},
    {id: 4, name: "Days", seconds: 24 * 60 * 60},
    {id: 5, name: "Weeks", seconds: 7 * 24 * 60 * 60},
];

const ReadingIntervalSelect = ({name, value, onChange, disabled = false}) => {
    return (
        <SelectWrapper
            className={disabled ? " select-without-arrow" : ""}
            name={name}
            defaultValue={value}
            onChange={onChange}
            disabled={disabled}
        >
            {readingIntervals.map((obj) => (
                <option
                    key={`reading-interval-temp-${obj.name}`}
                    value={obj.id}
                >
                    {obj.name}
                </option>
            ))}
        </SelectWrapper>
    );
};

ReadingIntervalSelect.propTypes = {
    disabled: PropTypes.bool,
    value: PropTypes.number,
    name: PropTypes.string,
    onChange: PropTypes.func,
};

export default ReadingIntervalSelect;
