import {isEmpty as _isEmpty} from "lodash";

export const addValidationRules = ({rules, data, hasImpactVue, showServiceInput, hasRouteCollectorAccess}) => {
    const commonRules = [
        {
            field: "idle_threshold_type",
            method: "isEmpty",
            validWhen: false,
            skipOnEmpty: () => _isEmpty(data.idle_threshold) && data.idle_threshold !== 0,
            message: "Threshold type can't be empty.",
        },
    ];

    const impactVueRules = hasImpactVue
        ? [
              {
                  field: "impact_idle_threshold",
                  method: "isNumeric",
                  validWhen: true,
                  skipOnEmpty: true,
                  message: "ImpactVue idle threshold must be a number.",
              },
              {
                  field: "impact_idle_threshold_type",
                  method: "isEmpty",
                  validWhen: false,
                  skipOnEmpty: () => _isEmpty(data.impact_idle_threshold) && data.impact_idle_threshold !== 0,
                  message: "Threshold type can't be empty.",
              },
          ]
        : [];

    const serviceInputRules = showServiceInput
        ? [
              {
                  field: "service",
                  method: "isEmpty",
                  validWhen: false,
                  message: "Service Type cannot be empty.",
              },
          ]
        : [];

    const routeCollectorRules = hasRouteCollectorAccess
        ? [
              {
                  field: "is_route_collector",
                  method: (is_route_collector) => [0, 1].includes(is_route_collector),
                  validWhen: false,
                  message: "Valid values are 0 or 1.",
              },
          ]
        : [];

    return [...rules, ...commonRules, ...impactVueRules, ...serviceInputRules, ...routeCollectorRules];
};
