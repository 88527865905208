import Helper from "./helper";
import {find as _find, get as _get} from "lodash";

export const getLocationId = () => {
    return !+Helper.getStorageItem("location") ? "" : Helper.getStorageItem("location");
};

export const setLocationId = (locationId) => {
    Helper.setStorageItem("location", locationId);
};

export const getLocationName = (locationList, locationId) => {
    const location = _find(locationList, {id: locationId});
    return _get(location, "name", "").trim();
};

export const deleteLocationId = () => localStorage.removeItem("location");
