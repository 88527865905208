import {useQuery} from "@tanstack/react-query";
import ApiLocation from "../../../api/location";
import {API_CONSTANTS} from "../../../api/constants/constants";

const assetGetKey = (locationId) => [API_CONSTANTS.GET_ASSETS, {locationId}];
const assetGetFn = (locationId) => ApiLocation.get(locationId);

export const useAssetGetQuery = ({locationId}) => {
    return useQuery({
        queryFn: () => assetGetFn(locationId),
        queryKey: assetGetKey(locationId),
        select: (res) => res?.item,
        enabled: !!locationId,
    });
};
