import moment from "moment";
import {useMemo} from "react";
import {useChartActions, useSelectChartFullScreenMode, useSelectChartType, useSelectChartZoomRange} from "../../store/hooks/useStoreContext";
import {MTIME_TWO_MONTH, useChartSelectedRangeStore} from "../../../../../../stores/zustand/ChartSelectedRangeStore";
import {useLocateNearestFFT} from "../helpers/useLocateNearestFFT";
import {useIsShowFFTAcquisitionsTime} from "../../../store/sync/fftAcquisitionsTimeStore";
import {useReadingTimestampQueries} from "../../../../../../hooks/api/fftReadings/useReadingTimestampQueries";
import {useSelectedByEquipment} from "../../../../../../stores/zustand/ChartSelectedEquipmentStore";
import {useEquipmentByParams} from "../../../../../../hooks/api/equipment/useEquipmentQuery";
import {useIsImpactVue} from "../../../hooks/useChartTypes";
import {useInstallationPointColors} from "../../../../../../hooks/helpers/theme";

const getRangeFilter = (min, max) => {
    const difference = max - min;

    if (!min || !max) {
        return 6;
    }

    if (difference > 2500000) {
        return 16;
    }
    if (difference > 2000000) {
        return 2;
    }
    if (difference > 1700000) {
        return 1;
    }
    if (difference > 1200000) {
        return 0;
    }
    if (difference > 70000) {
        return 0;
    }
    if (difference > 20000) {
        return 0;
    }
    return 0;
};

export const useFftAcquisitionsBuilder = () => {
    const {equipmentItem} = useEquipmentByParams();
    const selectedPoints = useSelectedByEquipment(equipmentItem.id);
    const chartType = useSelectChartType();
    const isImpactVue = useIsImpactVue(chartType);
    const installationPointColors = useInstallationPointColors();
    const fullScreenMode = useSelectChartFullScreenMode();
    const {toggleFullscreen} = useChartActions();

    const {min, max} = useSelectChartZoomRange();

    const {data, isSuccess} = useReadingTimestampQueries({
        installationPointIds: selectedPoints,
        from: moment().subtract(2, "month").unix(),
        to: moment().unix(),
        isImpactVue,
    });

    const onScrollStart = () => {
        if (!fullScreenMode) {
            return;
        }
        toggleFullscreen();
    };

    const locateNearestFFT = useLocateNearestFFT({onScrollStart});
    const rangeSelector = useChartSelectedRangeStore();
    const isShowFFTAcquisitionsTime = useIsShowFFTAcquisitionsTime();

    const acquisitionAnnotations = useMemo(() => {
        if (!isSuccess || !data?.length) {
            return [];
        }

        if (rangeSelector.range > MTIME_TWO_MONTH) {
            return [];
        }

        return data
            ?.filter((item) => !!item)
            .flatMap((timestamps, installationPointIndex) => {
                const filteredByRange = timestamps.filter((item) => {
                    if (!min || !max) {
                        return true;
                    }
                    return min < moment(item).unix() && moment(item).unix() < max;
                });

                const filteredByDistance = filteredByRange.reduce(
                    (arr, item) => {
                        const isAdd = moment(item).diff(arr[arr.length - 1], "hours") > getRangeFilter(min, max);
                        return isAdd ? [...arr, item] : arr;
                    },
                    [timestamps[0]]
                );

                return filteredByDistance
                    .map((item) => {
                        const offsetTimezoneMinutes = moment().utcOffset();

                        if (offsetTimezoneMinutes > 0) {
                            return moment(item).add(offsetTimezoneMinutes, "minutes").toISOString();
                        }

                        return moment(item).subtract(offsetTimezoneMinutes, "minutes").toISOString();
                    })
                    .map((itemsUnit) => {
                        const installationPointId = selectedPoints[installationPointIndex];

                        const installationPointColorIndex = equipmentItem.installationPoints.findIndex((item) => item.id === installationPointId);

                        return {
                            draggable: "",
                            events: {
                                click: () => {
                                    locateNearestFFT({
                                        installationPointId: installationPointId,
                                        timestamp: moment(itemsUnit).unix(),
                                    });
                                },
                            },
                            labels: {
                                allowOverlap: true,
                                shape: "rect",
                                text: " ",
                                className: "cursor-pointer cube",
                                backgroundColor: installationPointColors[installationPointColorIndex] || "#000000",
                                borderColor: installationPointColors[installationPointColorIndex] || "#000000",
                                points: [
                                    {
                                        x: +moment(itemsUnit).format("x"),
                                        y: 0,
                                        xAxis: 0,
                                        yAxis: 0,
                                    },
                                ],
                            },
                        };
                    });
            });
    }, [isSuccess, getRangeFilter(min, max), selectedPoints]);

    return isShowFFTAcquisitionsTime ? acquisitionAnnotations : [];
};
