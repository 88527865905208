import {useQuery} from "@tanstack/react-query";
import ApiLocation from "../../../api/location";
import {API_CONSTANTS} from "../../../api/constants/constants";

const assetDeleteInfoKey = (deleteLocationId) => [API_CONSTANTS.GET_ASSETS_LIST, {deleteLocationId}];
const assetDeleteInfoFn = (deleteLocationId) => ApiLocation.getDeleteInfo(deleteLocationId);

export const useAssetDeleteInfoQuery = (deleteLocationId) => {
    return useQuery({
        queryKey: assetDeleteInfoKey(deleteLocationId),
        queryFn: () => assetDeleteInfoFn(deleteLocationId),
        enabled: !!deleteLocationId,
    });
};
