import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Loader} from "../../shared";
import Helper from "../../helpers/helper";
import {withGlobalStore} from "../../stores/GlobalStore";

import "../../assets/scss/components/facility/facility-icon.scss";
import {withLocationSelectStore} from "../../stores/LocationSelectStore";
import {HeaderSimple} from "../../shared/header";
import LocationsHeader from "./components/LocationsHeader/LocationsHeader";
import LocationsAssetTree from "./components/LocationsAssetTree/LocationsAssetTree";
import LocationsActionsButtons from "./components/LocationsActionsButtons/LocationsActionsButtons";
import LocationsActionsModal from "./components/LocationsActionsModal/LocationsActionsModal";
import {useAssetListQuery} from "../../hooks/api/assets/useAssetListQuery";

const Locations = (props) => {
    const {auth, user, updateUser, refreshLocation} = props;

    const breadcrumbs = [{name: "Asset Tree"}];
    let hasAccessToFacility = true;
    const sort = localStorage.getItem("auto-sort-asset-tree");

    const [backUpTreeData, setBackUpTreeData] = useState([]);
    const [treeData, setTreeData] = useState([]);
    const [deleteLocationId, setDeleteLocationId] = useState(null);
    const [movedToAssetTreeBranchId, setMovedToAssetTreeBranchId] = useState(null);
    const [globalSearchString, setGlobalSearchString] = useState("");
    const [autoSort, setAutoSort] = useState(() => Boolean(sort));

    const {data: locationList, isFetching: isLocationListLoading} = useAssetListQuery({
        onlyWithEquipment: false,
        isFlat: false,
        sort,
    });

    const handleChangeGlobalSearch = (globalSearchString) => {
        setGlobalSearchString(globalSearchString);
    };

    useEffect(() => {
        if (!isLocationListLoading) {
            let treeData = locationList[0].children || [];
            if (sort === "1") {
                treeData = Helper.sortLocationList(treeData);
            }
            setTreeData(treeData);
            setBackUpTreeData(treeData);

            treeData.forEach((facility) => {
                hasAccessToFacility = hasAccessToFacility && user.allowedLocationIds.includes(+facility.id);
            });
        }
        if (sort === null || sort === "0") {
            setAutoSort(false);
            localStorage.setItem("auto-sort-asset-tree", "0");
        }
    }, [isLocationListLoading]);

    const onDeleteLocation = (id) => {
        setDeleteLocationId(id);
    };

    const handleAutoSort = () => {
        setAutoSort((prev) => !prev);
    };

    return (
        <div>
            <HeaderSimple
                breadcrumbs={breadcrumbs}
                globalSearchString={globalSearchString}
                handleChangeGlobalSearch={handleChangeGlobalSearch}
            />
            {isLocationListLoading ? (
                <div className="loader-fullscreen">
                    <Loader />
                </div>
            ) : (
                <div>
                    <LocationsHeader
                        auth={auth}
                        refreshLocation={refreshLocation}
                        handleAutoSort={handleAutoSort}
                        treeData={treeData}
                        setTreeData={setTreeData}
                    />
                    <LocationsAssetTree
                        setTreeData={setTreeData}
                        onDeleteLocation={onDeleteLocation}
                        auth={auth}
                        autoSort={autoSort}
                        treeData={treeData}
                        user={user}
                    />
                    {hasAccessToFacility && (
                        <LocationsActionsButtons
                            treeData={treeData}
                            setTreeData={setTreeData}
                            backUpTreeData={backUpTreeData}
                        />
                    )}
                </div>
            )}
            <LocationsActionsModal
                onDeleteLocation={onDeleteLocation}
                refreshLocation={refreshLocation}
                movedToAssetTreeBranchId={movedToAssetTreeBranchId}
                treeData={treeData}
                user={user}
                setMovedToAssetTreeBranchId={setMovedToAssetTreeBranchId}
                updateUser={updateUser}
                deleteLocationId={deleteLocationId}
            />
        </div>
    );
};

Locations.propTypes = {
    auth: PropTypes.object,
    history: PropTypes.object,
    user: PropTypes.object,
    updateUser: PropTypes.func,
    refreshLocation: PropTypes.func,
};

export default withLocationSelectStore(withGlobalStore(Locations));
