export const rules = [
    {
        field: "location_id",
        method: "isEmpty",
        validWhen: false,
        message: "Asset Tree Branch can't be empty.",
    },
    {
        field: "name",
        method: (name, options, data) => {
            const matchingLocations = data.dashboardList.filter((item) => +item.location_id === +data.location_id);
            const isNameDuplicate = matchingLocations.find((item) => item.name.trim().toLowerCase() === name.trim().toLowerCase() && item.id !== data.id);

            return !isNameDuplicate;
        },
        validWhen: true,
        message: "The equipment name must be unique within the selected asset tree branch.",
    },
    {
        field: "idle_threshold",
        method: "isNumeric",
        validWhen: true,
        skipOnEmpty: true,
        message: "Idle threshold must be a number.",
    },
];
