const labelText = "Select Parent";

export const rules = [
    {
        field: "name",
        method: (name) => !!name.replace(/<[^>]*>/gi, "").replace(/\s+|&nbsp;/gi, "").length,
        validWhen: true,
        message: "This field is required.",
    },
    {
        field: "name",
        method: (name, options, state, auxiliaryComponent) => {
            return validateAssetTreeBranchName(state, auxiliaryComponent);
        },
        validWhen: true,
        message: "A location with this name already exists for this parent branch.",
    },
    {
        field: "label",
        method: (label) => label !== labelText,
        validWhen: true,
        message: "This field is required.",
    },
];

const validateAssetTreeBranchName = (state, auxiliaryComponent) => {
    const {locationList, locationId} = auxiliaryComponent;
    let {name, parentId} = state;

    name = name.trim().toLowerCase();

    const sanitizeName = (input) =>
        input
            .replace(/<[^>]*>/gi, "")
            .replace(/\s?&nbsp;/gi, "")
            .toLowerCase();

    const findParentLocation = (list, parentId) => {
        let result = null;

        list.forEach((item) => {
            if (result) return;
            if (item.id === parentId) {
                result = item;
            } else if (item.children && item.children.length) {
                result = findParentLocation(item.children, parentId);
            }
        });

        return result;
    };

    const hasDuplicateChildName = (children, name) => {
        return children.find((child) => {
            return child.id !== locationId && sanitizeName(child.name) === name;
        });
    };

    if (name) {
        const parentLocation = findParentLocation(locationList, parentId);
        if (parentLocation?.children?.length) {
            return !hasDuplicateChildName(parentLocation.children, name);
        } else if (parentLocation?.children?.length <= 0) return true;
    }

    return false;
};
