export const API_CONSTANTS = {
    GET_DASHBOARD_LIST: "GET_DASHBOARD_LIST",
    GET_EQUIPMENT: "equipment",
    GET_EQUIPMENT_TYPES: "equipment-types",
    GET_EQUIPMENTS_FOR_LOCATION: "get-equipments-for-locations",
    GET_ASSETS_LIST: "GET_ASSETS_LIST",
    GET_ASSETS: "GET_ASSETS",
    GET_ASSETS_DELETE_INFO: "GET_ASSETS_DELETE_INFO",
    GET_AVAILABLE_EQUIPMENT_TYPE: "GET_AVAILABLE_EQUIPMENT_TYPE",
    SEARCH_SENSOR_BY_SERIAL: "SEARCH_SENSOR_BY_SERIAL",
    SEARCH_NODE_BY_SERIAL: "SEARCH_NODE_BY_SERIAL",
    SEARCH_GATEWAY_BY_SERIAL: "SEARCH_GATEWAY_BY_SERIAL",
    SEARCH_REPEATER_BY_SERIAL: "SEARCH_REPEATER_BY_SERIAL",
};
