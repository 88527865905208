import {useQueries} from "@tanstack/react-query";
import Api from "../../../api/api";
import {useQueriesStatus} from "../../useQueriesStatus";
import moment from "moment";

const getReadingTimestamps = ({installationPointId, from, to, isImpactVue}) =>
    Api.get("reading/fft-timestamps", {query: {installationPointId, from, to, isImpactVue}});

const getReadingTimestampsKeys = (params) => ["READING_TIMESTAMPS", params];

export const useReadingTimestampQueries = ({installationPointIds = [], from, to, isImpactVue, enabled}) => {
    const queries = installationPointIds?.map((installationPointId) => ({
        queryFn: () => getReadingTimestamps({installationPointId, from, to, isImpactVue: +isImpactVue}),
        queryKey: getReadingTimestampsKeys({
            installationPointId,
            from: moment.unix(from).format("MM/DD/YYYY"),
            to: moment.unix(to).format("MM/DD/YYYY"),
            isImpactVue,
        }),
        enabled,
    }));

    const res = useQueries({queries});

    return useQueriesStatus(res);
};
