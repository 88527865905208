import React, {Component} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {withGlobalStore} from "../../stores/GlobalStore";
import {ResetSortButton} from "../shared/resetSortButton";
import {get as _get, keys as _keys, each as _each} from "lodash";
import Helper from "../../helpers/helper";

const headersList = {
    "name_first": {title: "Name", sort: true},
    "username": {title: "Username", sort: true},
    "email": {title: "Email", sort: true},
    "asset_tree": {title: "Available Asset Tree", sort: false},
    "is_active": {title: "Status", sort: true},
    "last_seen": {title: "Last Seen", sort: true},
    "actions": {title: "Actions", sort: false, additionalClasses: "table-buttons-th", component: ResetSortButton}
};

class Table extends Component
{
    render() {
        const {auth, list, sort, onSortChange, query} = this.props;
        const listLen = Object.keys(headersList).length;

        return (
            <table className="table table-hover tl-fixed mb-0">
                <thead>
                    <tr>
                        {Object.keys(headersList).map(key => {
                            let component = "";
                            const RowComponent = _get(headersList, [key, "component"], false);
                            if (RowComponent) {
                                component = <RowComponent sort={sort} resetSort={() => onSortChange("")}/>;
                            }
                            return (
                                listLen && headersList[key].sort
                                    ?
                                    <th className={headersList[key].additionalClasses || ""} key={key} style={headersList[key].style || {}} onClick={() => onSortChange(key)}>
                                        <div className="cursor-pointer">
                                            {headersList[key].title} &nbsp;
                                            {(sort.field === key) ? <i className={"fa fa-sort" + (sort.sort === "asc" ? "-up" : "-down")}/> : <i className="fa fa-sort"/>}
                                        </div>
                                    </th>
                                    :
                                    (key !== "actions" || auth.userCan("editUsers")) &&
                                    <th className={headersList[key].additionalClasses || ""} key={key} style={headersList[key].style || {}}>{headersList[key].title} {component}</th>
                            );
                        }
                        )}
                    </tr>
                </thead>
                <tbody>
                    {list.length > 0
                        ?
                        list.map((user) => <UserRow key={user.id} user={user} props={this.props}/>)
                        :
                        <tr>
                            <td colSpan={listLen} className="text-center text-info">
                                {query !== "" ? "No items match your search." : "Users not found"}
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        );
    }
}

Table.propTypes = {
    auth: PropTypes.object,
    list: PropTypes.array,
    sort: PropTypes.object,
    onSortChange: PropTypes.func,
    query: PropTypes.string,
    getLocationFullNameById: PropTypes.func
};

const UserRow = ({user, props}) => {
    const userLocations = _get(user, "locations");
    const userLocationIds = _keys(userLocations);

    let locationNames = props.auth.isAllLocations(user) ? "All" : "";
    if (!locationNames) {
        _each(userLocationIds, (locationId) => {
            locationNames = locationNames + props.getLocationFullNameById(locationId) + "<br>";
        });
    }

    const userStatus = (user) => {
        if (+user.is_active === 1) {
            if (+user.confirmed === 1) {
                return  <span className="color-primary"><i className="fa fa-check" /> Active</span>;
            } else {
                return  <span className="color-danger"><i className="fa fa-times" /> Unconfirmed</span>;
            }
        } else {
            return <span className="color-danger"><i className="fa fa-times" /> Inactive</span>;
        }
    };

    return (
        <tr>
            <td dangerouslySetInnerHTML={{__html: Helper.highlight(user.name_first + " " + user.name_last, props.query)}}/>
            <td dangerouslySetInnerHTML={{__html: Helper.highlight(user.username, props.query)}}/>
            <td dangerouslySetInnerHTML={{__html: Helper.highlight(user.email, props.query)}}/>
            <td dangerouslySetInnerHTML={{__html: locationNames}}/>
            <td>{userStatus(user)}</td>
            <td dangerouslySetInnerHTML={{__html: Helper.highlight(_get(user, "last_seen"), props.query)}}/>
            {props.auth.userCan("editUsers") &&
                <td>
                    <ActionBar user={user} props={props}/>
                </td>
            }
        </tr>
    );
};

UserRow.propTypes = {
    auth: PropTypes.object,
    user: PropTypes.object,
    props: PropTypes.object,
    query: PropTypes.string,
    getLocationFullNameById: PropTypes.func
};

const ActionBar = ({user, props}) => {
    const userId = _get(user, "id");

    return <div className="btn-group btn-group-sm">
        <Link
            to={"/users/edit/"+userId}
            className="link link-primary"
            title={"Edit User"}
        >
            <i className="fa fa-pen"/>
        </Link>
        {+_get(user, "confirmed") === 1 &&
            <button
                className="link link-secondary"
                title="Reset Password"
                onClick={() => props.onResetPassword(userId)}
            >
                <i className="fa fa-key"/>
            </button>
        }
        {(+_get(user, "is_active") === 1 && +_get(user, "confirmed") === 0) &&
            <button
                className="link link-secondary"
                title="Resend the activation email"
                onClick={() => props.onResendEmail(userId)}
            >
                <i className="fa fa-envelope "/>
            </button>
        }
        <button
            className="link link-danger"
            title="Delete User"
            onClick={() => props.onDeleteUser(userId)}
        >
            <i className="fa fa-times"/>
        </button>
    </div>;
};

ActionBar.propTypes = {
    user: PropTypes.object,
    props: PropTypes.object,
    onResetPassword: PropTypes.func,
    onDeleteUser: PropTypes.func,
    onResendEmail: PropTypes.func
};

export default withGlobalStore(Table);