import ApiLocation from "../../../api/location";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {API_CONSTANTS} from "../../../api/constants/constants";
import Helper from "../../../helpers/helper";

const assetUpdateFn = ({data, id}) => ApiLocation.update(data, id);

export const useAssetUpdateMutation = ({props, history}) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: assetUpdateFn,
        onSuccess: () => {
            queryClient.invalidateQueries([API_CONSTANTS.GET_ASSETS_LIST]);
            props.refreshLocation();
            props.updateUser();
            history.push(Helper.deleteHashParams(["modal", "id"]));
        },
    });
};
