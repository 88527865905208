import React, {useMemo, useState} from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledTooltip} from "reactstrap";
import {forEach as _forEach, get as _get, has as _has} from "lodash";
import {AXES_COLORS, AXES_COLORS_DARK} from "../../../../../../constants/constants";
import Toast from "../../../../../shared/toast";
import auth from "../../../../../../services/auth";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {useLockPoints} from "../../hooks/useLockPoints";
import {useManageAxis} from "../../../../../../modals/manageAxes/hooks/useManageAxis";
import useAxisLabelQuery from "../../../../../../hooks/api/axisLabel/useAxisLabelQuery";
import {useEquipmentByParams} from "../../../../../../hooks/api/equipment/useEquipmentQuery";
import {useSelectedAxis} from "../../hooks/helpers/useSelectedAxis";
import {useToggleActiveSeries} from "../../hooks/helpers/useToggleActiveSeries";
import {useAxisSyncStoreActions} from "../../../store/sync/axisSyncStore";
import {shallow} from "zustand/shallow";
import {useFFTTimestampsQuery} from "../../../../../../hooks/api/fftReadings/TimestampsQuery";
import {useCurrentTimestampsInfo} from "../../hooks/helpers/useCurrentTimestampsInfo";
import {useSelectUnixDateRangeTimestampSync} from "../../../store/sync/timestampsSyncStore";
import {useIsImpactVue} from "../../../hooks/useChartTypes";

const isDarkTheme = localStorage.getItem("lightMode");

const SeriesMenuMultiple = () => {
    const [dropdownSeriesOpen, setDropdownSeriesOpen] = useState(false);
    const {equipmentItem} = useEquipmentByParams();
    const seriesList = useChartStateStoreContext((state) => state.settings.seriesList, shallow);
    const chartType = useChartStateStoreContext((state) => state.settings.chartType);
    const isImpactVue = useIsImpactVue(chartType);
    const selectedPoint = useChartStateStoreContext((state) => state.settings.selectedPoint, shallow);
    const {unixFrom, unixTo} = useSelectUnixDateRangeTimestampSync(selectedPoint, isImpactVue);
    const {axisLabelsChart: axisLabels} = useAxisLabelQuery(equipmentItem.id);
    const toggleActiveSeries = useToggleActiveSeries();
    const chartMode = useChartStateStoreContext((state) => state.settings.chartMode);
    const {chartModeList} = useChartStateStoreContext((state) => state.factory, shallow);
    const selectedAxis = useSelectedAxis();
    const isLoading = useChartStateStoreContext((state) => state.isLoading);
    const toggleSeriesOpen = () => {
        setDropdownSeriesOpen((prev) => !prev);
    };
    const {data: fftTimestamps} = useFFTTimestampsQuery({
        equipmentId: equipmentItem.id,
        selectorFun: (res) => _get(res.fftTimestamps, [selectedPoint], {}),
        unixTo,
        unixFrom,
    });
    const {time, date} = useCurrentTimestampsInfo();

    const outliersIds = [];
    _forEach(fftTimestamps, (val, key) => {
        const isOutlier = _get(val, [date, time, "is_outlier"], false);

        if (isOutlier) {
            outliersIds.push(key);
        }
    });

    const lockPoints = useLockPoints();
    const installationPointId = useChartStateStoreContext((state) => state.settings.selectedPoint);
    const {toggleActiveSeriesForSingleSelect, setDefaultAxis} = useAxisSyncStoreActions();
    const viewOnly = useMemo(() => auth.equipmentViewOnly(equipmentItem), [equipmentItem]);

    const saveSelectedAxis = () => {
        setDefaultAxis({axis: selectedAxis, installationPoint: selectedPoint, isImpactVue, isWaterfall: false});
        Toast.info("The default settings will be applied to this sensor.");
    };

    const [showManageAxis] = useManageAxis();

    const changeActiveSeries = (selected) => {
        lockPoints.current = [];
        const isWaterfall = chartMode === chartModeList.WATERFALL || chartMode === chartModeList.STACKED_WATERFALL;

        toggleActiveSeriesForSingleSelect(installationPointId, selected, isWaterfall);
    };

    if (Object.keys(seriesList.list).length < 2) {
        return <></>;
    }
    const selectedLabels = Object.keys(seriesList.list)
        .filter((key) => selectedAxis.indexOf(key) >= 0)
        .map((key) =>
            selectedPoint && _has(axisLabels, [key, selectedPoint]) && axisLabels[key][selectedPoint]
                ? axisLabels[key][selectedPoint]
                : seriesList.list[key].axisName + " - Axis"
        );

    return (
        <Dropdown
            size="sm"
            disabled={!!isLoading}
            isOpen={dropdownSeriesOpen}
            toggle={toggleSeriesOpen}
        >
            <DropdownToggle
                caret
                className="btn-default"
            >
                {selectedLabels.join(", ")}
            </DropdownToggle>
            <DropdownMenu>
                {Object.keys(seriesList.list).map((key) => (
                    <DropdownItem
                        className={"axis-dropdown-item"}
                        key={key}
                        toggle={false}
                    >
                        {selectedPoint ? (
                            <div>
                                <i
                                    id={`iconm-${seriesList.list[key].axisName}`}
                                    className={`fa fa-eye${selectedAxis.indexOf(key) >= 0 ? "" : "-slash"}`}
                                    onClick={() => toggleActiveSeries(key)}
                                />
                                <UncontrolledTooltip
                                    placement="top"
                                    target={`iconm-${seriesList.list[key].axisName}`}
                                >
                                    {"Click to  overlay"}
                                </UncontrolledTooltip>
                            </div>
                        ) : (
                            <div>
                                <i
                                    id={`icon-${seriesList.list[key].axisName}`}
                                    className={`disabled-eye fa fa-eye${selectedAxis.indexOf(key) >= 0 ? "" : "-slash"}`}
                                />
                                <UncontrolledTooltip
                                    placement="top"
                                    target={`icon-${seriesList.list[key].axisName}`}
                                >
                                    {"You cannot select multiple axes when multiple installation points are selected"}
                                </UncontrolledTooltip>
                            </div>
                        )}
                        {selectedPoint ? (
                            <span
                                id={`labelm-${seriesList.list[key].axisName}`}
                                className={"label badge"}
                                style={{
                                    color: "#fff",
                                    backgroundColor: isDarkTheme === "true" ? AXES_COLORS_DARK[key] : AXES_COLORS[key],
                                }}
                                onClick={() => changeActiveSeries(key)}
                            >
                                {selectedPoint && _has(axisLabels, [key, selectedPoint]) && axisLabels[key][selectedPoint]
                                    ? axisLabels[key][selectedPoint]
                                    : seriesList.list[key].axisName + " - Axis"}
                                <UncontrolledTooltip
                                    placement="top"
                                    target={`labelm-${seriesList.list[key].axisName}`}
                                >
                                    Click to change the overlay view
                                </UncontrolledTooltip>
                            </span>
                        ) : (
                            <span
                                className={"label badge"}
                                id={`label-${seriesList.list[key].axisName}`}
                                style={{
                                    color: "#fff",
                                    backgroundColor: isDarkTheme === "true" ? AXES_COLORS_DARK[key] : AXES_COLORS[key],
                                }}
                                onClick={() => changeActiveSeries(key)}
                            >
                                {selectedPoint && _has(axisLabels, [key, selectedPoint]) && axisLabels[key][selectedPoint]
                                    ? axisLabels[key][selectedPoint]
                                    : seriesList.list[key].axisName + " - Axis"}
                                <UncontrolledTooltip
                                    placement="top"
                                    target={`label-${seriesList.list[key].axisName}`}
                                >
                                    Click to change the axes view
                                </UncontrolledTooltip>
                            </span>
                        )}
                        {!!outliersIds && outliersIds.map((axisId) => +axisId).indexOf(+key) >= 0 && (
                            <span
                                className={"label badge"}
                                style={{
                                    color: "#fff",
                                    backgroundColor: "orange",
                                    marginLeft: "10px",
                                }}
                            >
                                outlier
                            </span>
                        )}
                    </DropdownItem>
                ))}
                {!!saveSelectedAxis && (
                    <DropdownItem className={"d-flex"}>
                        <div
                            title="Save the axes view for this sensor."
                            className="btn btn-sm btn-primary ml-auto mr-auto"
                            onClick={saveSelectedAxis}
                        >
                            Save as Default
                        </div>
                    </DropdownItem>
                )}
                {!viewOnly && (
                    <React.Fragment>
                        <DropdownItem divider />
                        <DropdownItem onClick={() => showManageAxis([selectedPoint])}>Manage...</DropdownItem>
                    </React.Fragment>
                )}
            </DropdownMenu>
        </Dropdown>
    );
};

export default SeriesMenuMultiple;
