import {useQuery} from "@tanstack/react-query";
import {API_CONSTANTS} from "../../../api/constants/constants";
import SsdApi from "../../../api/ssd";

const searchNodeBySerialKey = (filters) => [API_CONSTANTS.SEARCH_NODE_BY_SERIAL, filters.query];
const searchNodeBySerialFn = (filters) => SsdApi.searchNodeBySerial({query: filters.query});

export const useSearchNodeBySerialQuery = (filters) => {
    return useQuery({
        queryFn: () => searchNodeBySerialFn(filters),
        queryKey: searchNodeBySerialKey(filters),
        enabled: filters.enabled,
        cacheTime: 0,
        select: (res) => {
            return res.data.map((item) => ({
                value: item.serial,
                label: item.label,
                equipmentType: item.equipmentType,
                activeWwAi: item.activeWwAi,
            }));
        },
    });
};
