import React, {Component, useEffect, useState} from "react";
import InfiniteScroll from "react-infinite-scroller";
import ApiEquipments from "../../api/equipment";
import ApiLocation from "../../api/location";
import PropTypes from "prop-types";
import {DndProvider, useDrop} from "react-dnd";
import withScrolling from "react-dnd-scrolling";
import {HTML5Backend} from "react-dnd-html5-backend";
import Loader from "../../shared/loader/loader";
import EquipmentBlock from "./equipment-block";
import EquipmentTable from "./equipment-list-table";
import SearchInput from "../shared/search-input";
import Helper from "../../helpers/helper";
import {Link} from "react-router-dom";
import {withGlobalStore} from "../../stores/GlobalStore";
import {get as _get, set as _set, has as _has, mapValues as _mapValues, sortBy as _sortBy, find as _find} from "lodash";
import update from "immutability-helper";
import EquipmentLocationTree from "./locations-tree-equipments";
import cookies from "react-cookies";

import "../../assets/scss/components/tree-icon/tree-icon.scss";
import {withLocationSelectStore} from "../../stores/LocationSelectStore";
import CollapseLocationSelect from "../../shared/collapseLocationSelect/collapseLocationSelect";
import SelectWrapper from "../../helpers/select-wrapper";
import ContactEmailAlert from "../../modals/userAlert/contact-email";
import Survey from "../../modals/userAlert/survey";
import {HeaderDashboard} from "../../shared/header";
import {deleteLocationId} from "../../helpers/locations-list";

class NewDashboard extends Component {
    constructor(props) {
        super(props);

        let cid = _get(props, "auth.customer.customer_id", null);
        let uid = _get(props, "user.id", null);
        let cookieName = "favorite-asset-tree-" + cid + "-" + uid;
        const favAssetTreeCookie = cookies.load(cookieName);
        let favLocationId = false;
        if (favAssetTreeCookie !== undefined) {
            favLocationId = -2;
        }

        let storeLocation = props.getLocationId() || "";
        const paramLocationId = _get(this.props, "match.params.locationId", "");
        const location = paramLocationId
            ? paramLocationId
            : favLocationId && (storeLocation === "" || storeLocation === 0)
            ? favLocationId
            : storeLocation;

        let showContactEmailAlert = false;
        const cookieContactEmail = cookies.load("contactEmail");
        if (props.user.contact_email || props.user.email.indexOf("+") === -1) {
            if (cookieContactEmail !== undefined) {
                cookies.remove("contactEmail");
            }
        } else if (cookieContactEmail === undefined || cookieContactEmail !== "1") {
            showContactEmailAlert = true;
        }

        this.state = {
            title: "Equipment",
            loader: true,
            opened: false,
            list: {},
            listByAssetTree: {},
            filter: {
                sort: {
                    field: "",
                    sort: "asc",
                },
                location,
            },
            dnd: {},
            resultCount: 0,
            showContactEmailAlert: showContactEmailAlert,
            cookieName,
        };
        this.updateDND.bind(this);
    }

    calcLocationLabelList = (locations = [], locationLabelList = [], prefix = "") => {
        locations.forEach((location = {}) => {
            locationLabelList.push({
                id: location.id,
                label: prefix + location.name,
            });
            if ((location.children || []).length) {
                this.calcLocationLabelList(location.children, locationLabelList, prefix + location.name + " / ");
            }
        });
        return locationLabelList;
    };

    setLocationName = (name) => {
        let title = "Equipment";

        if (name && name !== "") {
            let locationName = name.replace(/&nbsp;/g, "").trim();
            title += " for " + locationName;
        }
        this.setState({title});
    };

    handleSearch = (value) => {
        if (!this.finished) {
            this.finished = true;
            this.controller.abort();
        }

        this.finished = false;

        const {filter} = {...this.state};

        this.setState(
            {
                filter: {
                    ...filter,
                    sort: {},
                    search: value,
                },
                loader: true,
            },
            this.fetchFilter
        );
    };

    fetchFilter = () => {
        this.controller = new window.AbortController();
        this.signal = this.controller.signal;

        const {filter} = this.state;
        ApiEquipments.dashboardList({filter}, this.signal).then((equipments) => {
            let listByAssetTree = {};

            Object.keys(_get(equipments, "list", [])).map((listKey) => {
                _get(equipments.list, [listKey, "equipments"], []).map((equipment) => {
                    if (!_has(listByAssetTree, equipment.location_id)) {
                        _set(listByAssetTree, equipment.location_id, []);
                    }

                    if (filter.level && _get(equipments.list, [listKey, "level", "name"], "Working") !== filter.level) {
                        return;
                    }
                    listByAssetTree[equipment.location_id].push(equipment);
                });
            });

            if (equipments) {
                this.setState(
                    {
                        list: equipments.list || [],
                        listByAssetTree: listByAssetTree,
                        loader: false,
                        resultCount: equipments.total || 0,
                        dnd: {isRefresh: true},
                    },
                    () => {
                        if (!this.finished) {
                            this.finished = true;
                        }
                    }
                );
            }
        });
    };
    resetFilter = () => {
        if (!this.finished) {
            this.finished = true;
            this.controller.abort();
        }

        this.finished = false;
        this.setState(
            {
                title: "Equipment",
                filter: {
                    ...this.state.filter,
                    location: "",
                    level: "",
                },
                loader: true,
            },
            () => {
                if (_get(this.props, "match.params.locationId", false)) {
                    deleteLocationId();

                    this.props.history.push({pathname: "/"});
                }
                this.fetchFilter();
            }
        );

        deleteLocationId();
    };

    handleFilter = (e) => {
        const {name = "", value = ""} = _get(e, "target");
        let {filter} = this.state;

        if (!this.finished) {
            this.finished = true;
            this.controller.abort();
        }

        this.finished = false;

        this.setState({filter: {...filter, [name]: value, sort: {}}, loader: true}, this.fetchFilter);
    };

    componentDidMount() {
        this.fetchFilter();
        const dashboardDisplayType = localStorage.getItem("viewDashboardType");
        this.changeView(dashboardDisplayType);
    }

    handleSort = (field, direction) => {
        const {filter} = this.state;
        this.setState({filter: {...filter, sort: {field, sort: direction}}});
    };

    changeView = (viewDashboardType) => {
        switch (viewDashboardType) {
            case "list":
            case "tree":
                Helper.setStorageItem("viewDashboardType", viewDashboardType);
                return this.props.history.push(Helper.setHashParams({viewDashboardType}));
            default:
                Helper.setStorageItem("viewDashboardType", "blocks");
                this.props.history.push(Helper.deleteHashParams(["viewDashboardType"]));
        }
    };

    setViewType = (viewDashboardType) => {
        let {filter, loader} = this.state;
        if (viewDashboardType === "tree" && (filter.search || filter.sort.field)) {
            loader = !!filter.search;
            filter = {
                ...filter,
                sort: {},
                search: "",
            };
        }
        this.setState({dnd: {isRefresh: true}, filter, loader}, () => {
            if (viewDashboardType === "tree" && loader) {
                this.fetchFilter();
            }
            this.changeView(viewDashboardType);
        });
    };
    updateDND = (dnd) => {
        this.setState({dnd});
    };
    handleOpen = (equipmentId) => {
        this.setState({
            ...this.state,
            opened: equipmentId,
        });
    };

    render() {
        const {list, listByAssetTree, loader, filter, resultCount, opened, dnd, title, showContactEmailAlert} = this.state;
        const {auth, locationList, locationLoading} = this.props;
        const {viewDashboardType = "blocks"} = Helper.getHashParams();

        let locationLabelList = [];
        if (!locationLoading) {
            locationLabelList = this.calcLocationLabelList(locationList || []);
        }

        return (
            <div>
                {showContactEmailAlert && <ContactEmailAlert />}
                <Survey />
                <HeaderDashboard
                    dnd={dnd}
                    updateDND={this.updateDND}
                    filter={filter}
                    auth={auth}
                    list={list}
                    handleFilter={this.handleFilter}
                    setLocationName={this.setLocationName}
                    handleSearch={this.handleSearch}
                    resetFilter={this.resetFilter}
                />
                {loader || locationLoading ? (
                    <div className="loader-fullscreen">
                        <Loader />
                    </div>
                ) : (
                    <React.Fragment>
                        <div className="subheader">
                            <div className="subheader-title">{title} </div>
                            <div className="subheader-controls">
                                <div className="subheader-toolbar">
                                    {auth.userCan("editEquipment") && (
                                        <Link
                                            to={"/equipments/create"}
                                            className={"btn btn-primary btn-sm btn-elevate mr-4"}
                                        >
                                            <i className="fa fa-plus-circle" />
                                            Add Equipment
                                        </Link>
                                    )}
                                    <SwitchBlock
                                        switchToggle={this.setViewType}
                                        viewDashboardType={viewDashboardType}
                                    />
                                </div>
                            </div>
                        </div>
                        {viewDashboardType === "blocks" && (
                            <EquipmentBlocks
                                list={listByAssetTree}
                                resultCount={resultCount}
                                locationLabelList={locationLabelList}
                                filter={filter}
                                dnd={dnd}
                                fetchFilter={this.fetchFilter}
                            />
                        )}
                        {viewDashboardType === "list" && (
                            <EquipmentTable
                                filter={filter}
                                handleSort={this.handleSort}
                                opened={opened}
                                handleOpen={this.handleOpen}
                                list={list}
                                resultCount={resultCount}
                                locationLabelList={locationLabelList}
                                history={this.props.history}
                                auth={auth}
                            />
                        )}
                        {viewDashboardType === "tree" && (
                            <EquipmentLocationTree
                                resultCount={resultCount}
                                history={this.props.history}
                                handleOpen={this.handleOpen}
                                handleSort={this.handleSort}
                                opened={opened}
                                list={list}
                                filter={filter}
                                locationLabelList={locationLabelList}
                                locations={locationList}
                                auth={auth}
                            />
                        )}
                    </React.Fragment>
                )}
            </div>
        );
    }
}

const ScrollingComponent = withScrolling("div");

const WrapperEquipmentBlock = ({locationGroup, children}) => {
    const [{isOver, canDrop}, drop] = useDrop({
        accept: "card",
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
        canDrop: (item) => {
            const {groupIndex} = item;
            return groupIndex === locationGroup;
        },
    });

    const getBackgroundColor = () => {
        if (isOver) {
            if (canDrop) {
                return "allow-container";
            } else if (!canDrop) {
                return "not-allow-container";
            }
        } else {
            return "";
        }
    };

    return (
        <div
            ref={drop}
            className={`${getBackgroundColor()} wrapper-dnd`}
        >
            {children}
        </div>
    );
};

WrapperEquipmentBlock.propTypes = {
    children: PropTypes.object,
    locationGroup: PropTypes.number,
};

const EquipmentBlocks = ({list = {}, locationLabelList, resultCount, dnd = {}, filter, fetchFilter = () => {}}) => {
    const [dataLength, setDataLength] = useState(20);
    const [sorting, setSorting] = useState([]);

    useEffect(() => {
        let sorting = {};
        Object.keys(list).map((key) => {
            sorting[key] = Object.values(
                _mapValues(
                    _sortBy(list[key], [(o) => (_get(o, "user_sort") || _get(o, "user_sort") === 0 ? +_get(o, "user_sort") : _get(o, "sort"))]),
                    "id"
                )
            );
        });
        setSorting(sorting);
    }, [dnd.isRefresh]);

    useEffect(() => {
        if (dnd.isApply) {
            ApiLocation.updateSort(sorting).then(fetchFilter);
        }
    }, [dnd.isApply]);

    if (!resultCount && filter?.search) {
        return (
            <div className="text-center">
                <h3>No items match your search.</h3>
            </div>
        );
    }

    if (!resultCount) {
        return (
            <div className="text-center">
                <h3>No equipment to display.</h3>
            </div>
        );
    }

    let total = 0;
    let length = 1;
    let tempList = sorting;

    for (let [, equipments] of Object.entries(tempList)) {
        total += (equipments || []).length;
    }

    const moveCard = (dragIndex, hoverIndex, groupIndex) => {
        setSorting({
            ...sorting,
            [groupIndex]: update(sorting[groupIndex], {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, sorting[groupIndex][dragIndex]],
                ],
            }),
        });
    };

    return (
        <InfiniteScroll
            loadMore={() => setDataLength(dataLength + 10)}
            hasMore={dataLength < total && !!locationLabelList.length}
            loader={
                <div
                    key="loader"
                    className="text-center"
                >
                    <Loader />
                </div>
            }
        >
            {dnd.isSorting && (
                <div className="k-margin-b-20-tablet-and-mobile custom-alert-block">
                    <div
                        className="alert alert-outline-warning fade show"
                        role="alert"
                    >
                        <div className="alert-icon">
                            <i className="flaticon-warning" />
                        </div>
                        <div className="alert-text">{total ? "You can drag equipment." : "Equipments not found."}</div>
                    </div>
                </div>
            )}

            {locationLabelList.map((locationLabel) => {
                if (length > dataLength || !(tempList[locationLabel.id] || []).length) {
                    return;
                }

                return (
                    <div key={locationLabel.id}>
                        <div className="subheader subheader--no-after">
                            <div className="subheader-title">{locationLabel.label}</div>
                        </div>
                        <DndProvider backend={HTML5Backend}>
                            <ScrollingComponent>
                                <WrapperEquipmentBlock locationGroup={locationLabel.id}>
                                    <div className="row grid">
                                        {tempList[locationLabel.id].map((equipmentId, index) => {
                                            if (length++ > dataLength) return;
                                            return (
                                                <EquipmentBlock
                                                    key={equipmentId}
                                                    index={index}
                                                    dnd={dnd}
                                                    groupIndex={locationLabel.id}
                                                    equipment={
                                                        _find(list[locationLabel.id], {
                                                            id: equipmentId,
                                                        }) || {}
                                                    }
                                                    moveCard={(dragIndex, hoverIndex) => moveCard(dragIndex, hoverIndex, locationLabel.id)}
                                                />
                                            );
                                        })}
                                    </div>
                                </WrapperEquipmentBlock>
                            </ScrollingComponent>
                        </DndProvider>
                    </div>
                );
            })}
        </InfiniteScroll>
    );
};

EquipmentBlocks.propTypes = {
    list: PropTypes.object,
    filter: PropTypes.object,
    locationLabelList: PropTypes.array,
    resultCount: PropTypes.number,
    dnd: PropTypes.object,
    fetchFilter: PropTypes.func,
};

const SwitchBlock = ({switchToggle, viewDashboardType}) => {
    return (
        <div className="right-equipment-block">
            <div className="change-list-view">
                <i
                    title={"View Photos"}
                    className={`block-visibility fa fa-th-large fa-2x ${viewDashboardType === "blocks" ? "active" : ""}`}
                    onClick={() => switchToggle("blocks")}
                    aria-hidden="true"
                />
                <i
                    title={"View List"}
                    className={`block-visibility fa fa-list fa-2x ${viewDashboardType === "list" ? "active" : ""}`}
                    onClick={() => switchToggle("list")}
                    aria-hidden="true"
                />
                <span
                    title={"View Tree"}
                    className={`block-visibility tree-icon ${viewDashboardType === "tree" ? "active" : ""}`}
                    onClick={() => switchToggle("tree")}
                >
                    <svg
                        height="13px"
                        width="13px"
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="m512 312v-160h-230v62h-152v-54h100v-160h-230v160h90v294h192v58h230v-160h-230v62h-152v-160h152v58zm-472-272h150v80h-150zm282 352h150v80h-150zm0-200h150v80h-150zm0 0" />
                    </svg>
                </span>
            </div>
        </div>
    );
};

SwitchBlock.propTypes = {
    switchToggle: PropTypes.func,
    viewDashboardType: PropTypes.string,
};

const HeaderRight = ({levelList = {}, filter, dnd = {}, handleFilter, handleSearch, resetFilter, setLocationName, auth}) => {
    let filterNotEmpty = (filter) => {
        return _get(filter, "location", "") || _get(filter, "level", "");
    };
    const {viewDashboardType = "blocks"} = Helper.getHashParams();

    return (
        <div className="header-rules mr-4 form-list form-list--row">
            {auth.userCan("showAlarms") && (
                <React.Fragment>
                    <div className="filter-item">
                        <label>Alert Level</label>
                    </div>
                    <div className="form-group form-group--inline">
                        <SelectWrapper
                            onChange={handleFilter}
                            value={_get(filter, ["level"]) || ""}
                            name="level"
                            style={{marginLeft: 5, width: 150}}
                            disabled={dnd.isSorting}
                        >
                            <option value="">All</option>
                            {Object.keys(levelList).map((key) => (
                                <option
                                    key={key}
                                    value={levelList[key]}
                                >
                                    {levelList[key]}
                                </option>
                            ))}
                        </SelectWrapper>
                    </div>
                </React.Fragment>
            )}
            {auth.userCan("showFullServiceEquipments") && auth.customer.system_version === "hybrid-service" && (
                <React.Fragment>
                    <div className="filter-item">
                        <label>Service</label>
                    </div>
                    <div className="form-group form-group--inline">
                        <SelectWrapper
                            onChange={handleFilter}
                            value={_get(filter, ["service"]) || ""}
                            name="service"
                            style={{marginLeft: 5, width: 150}}
                            disabled={dnd.isSorting}
                        >
                            <option value="">All</option>
                            <option value="full">Full Service</option>
                            <option value="self">Self Service</option>
                        </SelectWrapper>
                    </div>
                </React.Fragment>
            )}
            <div className="filter-item">
                <label>Asset Tree Branch</label>
            </div>
            <div className="form-group form-group--inline">
                <CollapseLocationSelect
                    style={{
                        marginLeft: 5,
                        width: 150,
                    }}
                    selectName={"location"}
                    value={+_get(filter, ["location"], 0)}
                    onChange={handleFilter}
                    disabled={dnd.isSorting}
                    emptyOptionLabel={"All"}
                    setLocationName={setLocationName}
                    checkStoreValue={true}
                    withFavorite={true}
                />
            </div>
            {filterNotEmpty(filter) && (
                <div className="filter-item">
                    <button
                        onClick={resetFilter}
                        className="btn btn-primary btn-sm"
                    >
                        Clear
                    </button>
                </div>
            )}
            {viewDashboardType !== "tree" && (
                <div className="filter-item global-search">
                    <SearchInput
                        placeholder="Search Equipment"
                        query={_get(filter, ["search"], "")}
                        additionalClasses={"form-control-sm"}
                        onChange={handleSearch}
                        disabled={dnd.isSorting}
                    />
                </div>
            )}
        </div>
    );
};

HeaderRight.propTypes = {
    levelList: PropTypes.object,
    filter: PropTypes.any,
    dnd: PropTypes.object,
    handleFilter: PropTypes.func,
    handleSearch: PropTypes.func,
    resetFilter: PropTypes.func,
    setLocationName: PropTypes.func,
    auth: PropTypes.object,
};

NewDashboard.propTypes = {
    history: PropTypes.object,
    auth: PropTypes.object,
    user: PropTypes.object,

    // LocationSelectStore
    getLocationId: PropTypes.func,
    setLocationId: PropTypes.func,
    locationList: PropTypes.array,
    locationLoading: PropTypes.bool,
};

export default withLocationSelectStore(withGlobalStore(NewDashboard));
