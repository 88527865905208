import {useEffect, useState} from "react";
import {useChartSelectedChartTypeActions, useChartSelectedChartTypeStore} from "../../../../../../stores/zustand/ChartSelectedChartTypeStore";
import {scrollToFft} from "../../../helpers/scrollToFft";
import {useTimestampsSyncStoreActions} from "../../../store/sync/timestampsSyncStore";
import {useNearestFftMutation} from "../../../../../../hooks/api/fftReadings/useNearestFftMutation";
import {useSelectChartType} from "../../store/hooks/useStoreContext";
import {useIsImpactVue} from "../../../hooks/useChartTypes";

const DEFAULT_SCROLL_DATA = {
    installationPointId: null,
    chartType: null,
};

export const useLocateNearestFFT = ({onScrollStart} = {}) => {
    const chartType = useSelectChartType();
    const isImpactVue = useIsImpactVue(+chartType);

    const {setTimestampsForFft} = useTimestampsSyncStoreActions();

    const onNearestFftTimestampSuccess = (res, variables) => {
        const fftChartType = isImpactVue ? 19 : +chartType === 3 ? 9 : 8;

        if (selectedCharts.checked.indexOf(fftChartType + "") === -1) {
            setCheckedChartType(fftChartType + "", true);
        }
        scrollToFft(fftChartType, variables.installationPointId);
        setTimestampsForFft(variables.installationPointId, {date: res.date, time: res.time, readingId: res.id, checkChartDateRange: true}, isImpactVue);

        setScrollData({
            installationPointId: variables.installationPointId,
            chartType: fftChartType,
        });
    };

    const {mutate} = useNearestFftMutation({onSuccess: onNearestFftTimestampSuccess});

    const selectedCharts = useChartSelectedChartTypeStore();
    const {setCheckedChartType} = useChartSelectedChartTypeActions();
    const [scrollData, setScrollData] = useState(DEFAULT_SCROLL_DATA);

    useEffect(() => {
        if (!scrollData.installationPointId || !scrollData.chartType) {
            return;
        }
        if (!selectedCharts.checked.includes(scrollData.chartType + "")) {
            return;
        }
        onScrollStart?.();
        scrollToFft(scrollData.chartType, scrollData.installationPointId);
        setScrollData(DEFAULT_SCROLL_DATA);
    }, [scrollData, selectedCharts.checked]);
    return mutate;
};
